import axios, { AxiosError, AxiosResponse } from "axios";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { ApiResponse } from "../../types/api";
// import { useNavigate } from "react-router-dom";

interface LoginResponse extends AxiosResponse {
  data: {
    msg: string;
    user?: {
      email: string;
      token: string;
    };
  };
}

export default function useLoginModel() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // const navigate = useNavigate();

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    route: "email" | "otp"
  ) => {
    event.preventDefault();

    setIsSubmitting(true);
    const email = event.currentTarget.email.value;

    const payload =
      route === "email"
        ? { email }
        : { email, otp: event.currentTarget.otp.value };

    try {
      const result: LoginResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/login/${route}`,
        { ...payload }
      );
      setIsSubmitting(false);
      const { data } = result;

      if (data.user) {
        const token = data.user.token;
        localStorage.setItem("token", token);
      }

      // navigate(route === "email" ? "/login/otp" : "/dashboard", {
      //   replace: true,
      //   state: { email },
      // });
    } catch (err) {
      setIsSubmitting(false);
      toast.error(
        (err as ApiResponse).msg ||
          (err as AxiosError).message ||
          "An unexpected error occured"
      );
    }
  };

  return { handleSubmit, isSubmitting };
}
