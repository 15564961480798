import { IconButton, Typography } from "@material-tailwind/react";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { BiTrash } from "react-icons/bi";
import { GETMESSAGETEMPLATEBYTYPE } from "../../GraphQl/queries";
import { useQuery } from "@apollo/client";

const TemplateTable = ({
  item,
  index,
  activePage,
  PAGE_SIZE,
  setOpenModal,
  handleEdit,
  isLast,
  handleDelete,
}: any) => {
  const className = isLast
    ? "px-2 py-3 text-center"
    : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

  const { data: MessageTypeData } = useQuery(GETMESSAGETEMPLATEBYTYPE, {
    variables: {
      id: item.message_type,
    },
  });

  console.log("MessageTypeData is", MessageTypeData);
  return (
    <tr
      key={index}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={className} onClick={() => setOpenModal(true)}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {(activePage - 1) * PAGE_SIZE + index + 1}
        </Typography>
      </td>
      <td className={className} onClick={() => setOpenModal(true)}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {/* {item?.message_type?.length > 20
                              ? item.message_type
                                  .split("-")
                                  .slice(0, 1)
                                  .join(" ") +
                                " " +
                                item.message_type.split(" ")[1].slice(0, 5) +
                                "..."
                              : item.message_type} */}
          {item.messageTypeByMessageType.name}
        </Typography>
      </td>
      <td className={className} onClick={() => setOpenModal(true)}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize text-wrap"
        >
          {/* {item?.message_body
                              ?.split(" ")
                              .slice(0, 3)
                              .join(" ") + "..."} */}
          {item?.message_body}
        </Typography>
      </td>
      <td className={className} onClick={() => setOpenModal(true)}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.message_variables}
        </Typography>
      </td>
      <td
        className={`flex flex-row items-center justify-center gap-2 ${className}`}
      >
        <button
          onClick={() => handleEdit((activePage - 1) * PAGE_SIZE + index)}
        >
          <IconButton className="bg-[#4CAF4F]" size="sm">
            <IconEdit stroke={2} size={13} />
          </IconButton>
        </button>
        <button
          onClick={() => handleDelete((activePage - 1) * PAGE_SIZE + index)}
        >
          <IconButton color="red" size="sm">
            <BiTrash />
          </IconButton>
        </button>
      </td>
    </tr>
  );
};

export default TemplateTable;
