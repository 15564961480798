import { Typography } from "@material-tailwind/react";
import React from "react";
import HeaderIcon from "./HeaderIcon";

export const CustomHeader = ({ title }: { title: string }) => {
  return (
    <div className="sticky top-0 z-50 mb-5">
      <div className="bg-[#222020] py-3 sticky top-0 z-50 w-full flex flex-row items-center justify-between">
        <Typography className="capitalize font-semibold text-lg lg:text-xl text-brand-primary">
          {title}
        </Typography>
        <HeaderIcon />
      </div>
    </div>
  );
};
