import { useQuery, gql } from "@apollo/client";

export const ADDTOCOMPREHENSIVE = gql`
  mutation addToComprehensive(
    $data: [comprehensive_third_party_risk_class_insert_input!]!
  ) {
    insert_comprehensive_third_party_risk_class(objects: $data) {
      affected_rows
    }
  }
`;

export const ADDTOTHIRDPARTY = gql`
  mutation addTothirdParty(
    $data: [third_party_only_risk_class_insert_input!]!
  ) {
    insert_third_party_only_risk_class(objects: $data) {
      affected_rows
    }
  }
`;

export const ADDREGION = gql`
  mutation createRegion($name: String) {
    insert_regions(objects: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const DELETECOVER = gql`
  mutation MyMutation($id:uuid) {
  delete_company_subclass_covertypes(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
`;

export const DELETECOMPANYDISCOUNT = gql`
  mutation MyMutation($id:uuid) {
  delete_company_discounts(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
`;

export const DELETECOMPANYBENFIT = gql`
    mutation MyMutation($id:uuid) {
  delete_company_additional_benefits(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
`;


export const DELETECOMPANYSUBCLASS = gql`
   mutation MyMutation($id:uuid) {
  delete_company_subclass_covertypes(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
`;

export const DELETEREGION = gql`
  mutation deleteRegion($region_id: uuid!) {
    delete_regions_by_pk(id: $region_id) {
      id
    }
  }
`;

export const CREATE_DISTRICT = gql`
  mutation createDistrict(
    $region_id: uuid!
    $name: String
    $category: String
    $capital: String
  ) {
    insert_districts(
      objects: {
        region_id: $region_id
        name: $name
        category: $category
        capital: $capital
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_CLIENT_TYPE = gql`
  mutation addClientType(
    $description: String
    $name: String
    $created_by: uuid
  ) {
    insert_client_types(
      objects: {
        description: $description
        name: $name
        created_by: $created_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT_TYPE = gql`
  mutation updateClientType($id: uuid, $name: String, $desc: String) {
    update_client_types(
      where: { id: { _eq: $id } }
      _set: { description: $desc, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_NEW_DIVISION = gql`
  mutation addDivisions($description: String, $name: String) {
    insert_divisions(objects: { description: $description, name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_TOWN = gql`
  mutation insertSingleTown($district_id: uuid!, $name: String) {
    insert_towns(objects: { district_id: $district_id, name: $name }) {
      returning {
        id
        name
      }
    }
  }
`;

export const INSERTBUSINESSCLASS = gql`
  mutation insertBusinessClass(
    $image_url: String
    $name: String
    $description: String
  ) {
    insert_business_classes(
      objects: { image_url: $image_url, name: $name, description: $description }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATEBUSINESSCLASS = gql`
  mutation updateBusinessClass(
    $id: uuid!
    $description: String
    $image: String
    $name: String
  ) {
    update_business_classes_by_pk(
      pk_columns: { id: $id }
      _set: { description: $description, image_url: $image, name: $name }
    ) {
      description
      created_at
      id
      image_url
      name
    }
  }
`;

export const DELETEBUSINESSCLASS = gql`
  mutation deleteBusinessClass($id: uuid!) {
    delete_business_classes_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATESUBCLASS = gql`
  mutation createSubclass(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_subclass(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETESUBCLASS = gql`
  mutation deleteSubclassById($id: uuid!) {
    delete_subclass_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATEPERIL = gql`
  mutation createPeril($class_id: uuid!, $description: String, $name: String) {
    insert_perils(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETEPERIL = gql`
  mutation deletePeril($id: uuid!) {
    delete_perils_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATEEXCLUSION = gql`
  mutation createExclusion(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_exclusions(
      objects: { class_id: $class_id, description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETEEXCLUSION = gql`
  mutation deleteExclusion($id: uuid!) {
    delete_exclusions_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETEPREMIUMRATE = gql`
  mutation deletePremiumRate($id: uuid!) {
    delete_premium_rate_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATEPREMIUMRATE = gql`
  mutation updatePremiumRate(
    $id: uuid
    $rate: String
    $status: String
    $sub_class_id: uuid
    $class_id: uuid
    $covertype: uuid
    $desc: String
  ) {
    update_premium_rate(
      where: { id: { _eq: $id } }
      _set: {
        rate: $rate
        status: $status
        sub_class_id: $sub_class_id
        class_id: $class_id
        covertype_id: $covertype
        description: $desc
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDPERILOREXCLUSIONTOCOVERTYPE = gql`
  mutation addPerilorExclusionToCoverType(
    $cover_type_id: uuid
    $exclusion_id: uuid
    $peril_id: uuid
  ) {
    insert_coverage(
      objects: {
        cover_type_id: $cover_type_id
        exclusions_id: $exclusion_id
        peril_id: $peril_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDPREMIUMRATE = gql`
  mutation addPremiumRate(
    $cover_type_id: uuid
    $class_id: uuid
    $subclass_id: uuid
    $description: String
    $rate: String
  ) {
    insert_premium_rate(
      objects: {
        class_id: $class_id
        covertype_id: $cover_type_id
        description: $description
        rate: $rate
        status: "active"
        sub_class_id: $subclass_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETESYSTEMDSICOUNT = gql`
  mutation deleteSystemDiscount($id: uuid!) {
    delete_system_discount_by_pk(id: $id) {
      id
    }
  }
`;

export const ADDDISCOUNT = gql`
  mutation addDiscount($name: String, $description: String) {
    insert_discount(
      objects: { name: $name, description: $description, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`;
export const ADD_DISCOUNT = gql`
mutation addDiscount( $description: String,$business_class:uuid,$name:String) {
  insert_discount(objects: {description: $description, status: true, business_class: $business_class, name:$name}) {
    returning {
    id
}
}
}
`;

export const ADDDISCOUNTRATE = gql`
  mutation addDiscountRate(
    $class_id: uuid
    $covertype_id: uuid
    $desc: String
    $discount_id: uuid
    $subclass_id: uuid
    $rate: String
  ) {
    insert_discount_rates(
      objects: {
        class_id: $class_id
        covertype_id: $covertype_id
        description: $desc
        discount_id: $discount_id
        sub_class_id: $subclass_id
        rate: $rate
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETEDISCOUNTRATE = gql`
  mutation deleteDiscountRate($id: uuid!) {
    delete_discount_rates_by_pk(id: $id) {
      id
    }
  }
`;

export const ADDCOVERTYPE = gql`
  mutation addCoverTypes(
    $class_id: uuid!
    $description: String
    $name: String
  ) {
    insert_covertypes(
      objects: {
        class_id: $class_id
        description: $description
        name: $name
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETECOVERTYPE = gql`
  mutation deleteCoverTypes($id: uuid!) {
    delete_covertypes_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETELOADING = gql`
  mutation deleteLoading($id: uuid!) {
    delete_loadings_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATELOADING = gql`
mutation createLoading($description: String, $name: String, $bu_id: uuid) {
  insert_loadings(objects: {description: $description, name: $name, status: "active", business_class: $bu_id}) {
    returning {
    id
}
}
}
`;

export const CREATELOADINGTYPE = gql`
  mutation createLoadingRate(
    $class_id: uuid
    $covertype_id: uuid
    $desc: String
    $loading_id: uuid
    $subclass_id: uuid
    $rate: String
  ) {
    insert_loading_rates(
      objects: {
        class_id: $class_id
        covertype_id: $covertype_id
        description: $desc
        loading_id: $loading_id
        subclass_id: $subclass_id
        rate: $rate
        status: "active"
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDCOMMISSION = gql`
  mutation addCommission(
    $desc: String
    $name: String
    $is_special: Boolean
    $code: String
  ) {
    insert_commisions(
      objects: {
        description: $desc
        name: $name
        status: "active"
        is_special: $is_special
        code: $code
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATEPERMISSION = gql`
  mutation createPermission($platform: String, $name: String, $action: jsonb) {
    insert_permission_setup(
      objects: { platform: $platform, name: $name, actions: $action }
    ) {
      affected_rows
    }
  }
`;

export const UPDATEPERMISSION = gql`
  mutation updatePermission(
    $id: uuid
    $platform: String
    $name: String
    $action: jsonb
  ) {
    update_permission_setup(
      where: { id: { _eq: $id } }
      _set: { platform: $platform, name: $name, actions: $action }
    ) {
      affected_rows
    }
  }
`;

export const UPDATEEXTERNALCLIENT = gql`
  mutation updateExternalClient($id: uuid!, $data: external_client_set_input!) {
    update_external_client(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const INSERNEWEXTERNALCLIENT = gql`
  mutation insertNewExternalclient($data: [external_client_insert_input!]!) {
    insert_external_client(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const DELETECOMMISSIONS = gql`
  mutation deleteCommissions($id: uuid!) {
    delete_commisions_by_pk(id: $id) {
      id
    }
  }
`;

export const ADDCOMMISSIONRATE = gql`
  mutation addCommissionRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $commission_id: uuid
    $intermediary_type: String
    $subclass_id: uuid
  ) {
    insert_commisions_rates(
      objects: {
        class_id: $class_id
        commision_id: $commission_id
        description: $desc
        intermediary_type: $intermediary_type
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETECOMMISSIONRATE = gql`
  mutation deleteCommissionRate($id: uuid!) {
    delete_commisions_rates_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATELEVY = gql`
  mutation createLevy($desc: String, $name: String) {
    insert_levies(
      objects: { description: $desc, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDLEVYRATE = gql`
  mutation addLevyRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $levy_id: uuid
    $amount: String
    $subclass_id: uuid
    $covertype: uuid
  ) {
    insert_levies_rate(
      objects: {
        class_id: $class_id
        description: $desc
        amount: $amount
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
        levy_id: $levy_id
        covertype_id: $covertype
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETELEVY = gql`
  mutation deleteLevy($id: uuid!) {
    delete_levies_by_pk(id: $id) {
      id
    }
  }
`;
export const DELETELEVYRATE = gql`
  mutation deleteLevyRate($id: uuid!) {
    delete_levies_rate_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATETAX = gql`
  mutation createTax($desc: String, $name: String) {
    insert_taxes(
      objects: { description: $desc, name: $name, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDTAXRATE = gql`
  mutation addTaxRate(
    $desc: String
    $rate: String
    $class_id: uuid
    $tax_id: uuid
    $amount: String
    $subclass_id: uuid
    $covertype: uuid
  ) {
    insert_tax_rate(
      objects: {
        class_id: $class_id
        description: $desc
        amount: $amount
        status: "active"
        subclass_id: $subclass_id
        rate: $rate
        tax_id: $tax_id
        covertype_id: $covertype
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETETAX = gql`
  mutation deleteTax($id: uuid!) {
    delete_taxes_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETESUBSCRIPTION = gql`
  mutation deleteSubscription($id: uuid) {
    delete_subscription_rate(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const DELETECLIENT = gql`
  mutation deleteClient($id: uuid) {
    delete_external_client(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETETAXRATE = gql`
  mutation deleteTaxRate($id: uuid!) {
    delete_tax_rate_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATERISKCLASS = gql`
  mutation updateClass(
    $id: uuid!
    $basic_premium: String
    $ecowas_peril: String
    $total_amount: String
    $fee_amount: String
  ) {
    update_third_party_only_risk_class(
      where: { risk_id: { _eq: $id } }
      _set: {
        basic_premium: $basic_premium
        ecowas_perils: $ecowas_peril
        total_amount: $total_amount
        fee_amount: $fee_amount
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDMESSAGETYPE = gql`
  mutation addMessageType(
    $description: String
    $name: String
    $status: String
  ) {
    insert_message_types(
      objects: { description: $description, name: $name, status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADDMESSAGETEMPLATE = gql`
  mutation addMessageTemplate(
    $message_type: uuid
    $message_variable: [String!]!
    $message_body: String
  ) {
    insert_message_template(
      objects: {
        message_variables: $message_variable
        message_type: $message_type
        message_body: $message_body
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($id: uuid) {
    delete_message_template(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATEMESSAGETYPE = gql`
  mutation updateMessageType(
    $id: uuid
    $name: String
    $des: String
    $status: String
  ) {
    update_message_types(
      where: { id: { _eq: $id } }
      _set: { name: $name, description: $des, status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATEMESSAGETEMPLATE = gql`
  mutation MyMutation(
    $id: uuid
    $body: String
    $message_type: uuid
    $variables: [String!]!
  ) {
    update_message_template(
      where: { id: { _eq: $id } }
      _set: {
        message_body: $body
        message_type: $message_type
        message_variables: $variables
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETEMESSAGETYPE = gql`
  mutation deleteMessageType($id: uuid) {
    delete_message_types(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const ADDNEWPREFIX = gql`
  mutation addNewPrefix($name: String) {
    insert_vehicle_prefix(objects: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATEPREFIX = gql`
  mutation updatePrefix($name: String, $id: uuid) {
    update_vehicle_prefix(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const DELETEPREFIX = gql`
  mutation deletePrefix($id: uuid) {
    delete_vehicle_prefix(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATEDISCOUNT = gql`
  mutation updateDiscount($id: uuid, $desc: String, $rate: String) {
    update_discount(
      where: { id: { _eq: $id } }
      _set: { description: $desc, rate: $rate, status: true }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATELOADINGS = gql`
  mutation updateLoading($id: uuid, $desc: String) {
    update_loadings(
      where: { id: { _eq: $id } }
      _set: { description: $desc, status: "active" }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATECOMMISIONS = gql`
  mutation updateCommisions(
    $id: uuid
    $desc: String
    $name: String
    $code: String
  ) {
    update_commisions(
      where: { id: { _eq: $id } }
      _set: {
        description: $desc
        status: "active"
        name: $name
        code: $code
        is_special: false
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATELEVIES = gql`
  mutation updateLevies($id: uuid, $desc: String, $name: String) {
    update_levies(
      where: { id: { _eq: $id } }
      _set: { description: $desc, status: "active", name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATETAXES = gql`
  mutation updateTaxes(
    $id: uuid
    $desc: String
    $name: String
    $usertype: String
  ) {
    update_taxes(
      where: { id: { _eq: $id } }
      _set: {
        description: $desc
        status: "active"
        name: $name
        user_type: $usertype
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_RATE = gql`
  mutation update_subscription_rate(
    $id: uuid
    $desc: String
    $name: String
    $usertype: String
    $company_id: uuid
    $intermediary_id: uuid
    $bus_id: uuid
    $rate: String
    $subclass_id: uuid
  ) {
    update_subscription_rate(
      where: { id: { _eq: $id } }
      _set: {
        description: $desc
        name: $name
        user_type: $usertype
        insurer_company: $company_id
        business_class_id: $bus_id
        intermediary: $intermediary_id
        rate: $rate
        subclass_id: $subclass_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_COLOR = gql`
  mutation updateColors($id: uuid!, $name: String!) {
    update_colors(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_MAKE = gql`
  mutation updateMake($id: uuid!, $name: String!) {
    update_vehicle_make(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_MODEL = gql`
  mutation updateModel($id: uuid!, $name: String!) {
    update_vehicle_model(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_CONSTRUCTION_TYPE = gql`
mutation addConstructionType($code: String, $desc: String, $type: String,$image:string) {
  insert_construction_type(objects: {code: $code, description: $desc, state: "active", type: $type,image:$image}) {
    returning {
      id
    }
  }
}
`;

export const ADD_USE_OF_PROPERTIES = gql`
mutation addPropertyUseType($code: String, $desc: String, $type: String) {
  insert_property_type(objects: {code: $code, description: $desc, state: "active", type: $type}) {
    returning {
      id
    }
  }
}
`;

export const ADD_SUBCLASS_TO_COMPANY = gql`
  mutation addSubclassToCompany(
    $bclass_id: uuid!, 
    $com_id: uuid!, 
    $sub_class_id: uuid!, 
    $desc: String!
  ) {
    insert_company_subclass_covertypes(objects: {
      business_class_id: $bclass_id, 
      company_id: $com_id, 
      sub_class_id: $sub_class_id, 
      description: $desc
    }) {
      returning {
        id
      }
    }
  }
`;


export const ADD_BUSINESS_COVERTYPE=gql`
mutation insertNewData($data: [company_subclass_covertypes_insert_input!]!) {
  insert_company_subclass_covertypes(objects: $data) {
    returning {
      id
    }
  }
}
`

export const DELETE_BUSINESS_COVERTYPES = gql`
  mutation MyMutation($company_id: uuid!, $id: uuid!) {
    delete_company_subclass_covertypes(
      where: { id: { _eq: $id }, company_id: { _eq: $company_id } }
    ) {
      returning {
        id
      }
    }
  }
`;


export const ADD_BUSINESS_DISCOUNT = gql`
  mutation MyMutation($bus_id: uuid, $company_id: uuid, $discount_id: uuid) {
    insert_company_discounts(
      objects: {
        business_class_id: $bus_id, 
        company_id: $company_id, 
        discount_id: $discount_id, 
        status: true
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const ADD_BUSINESS_BENEFIT = gql`
 mutation MyMutation($bus_id:uuid,$company_id:uuid,$additonal_benefit_id:uuid) {
  insert_company_additional_benefits(objects: {business_class_id: $bus_id, company_id: $company_id, benefit: $additonal_benefit_id, status: true}) {
    returning {
      id
    }
  }
}

`






export const ADD_ADDITIONAL_BENEFIT = gql`
mutation addAdditionalBenefit($name: String!, $desc: String!, $class_id: uuid!) {
  insert_additional_benefit(objects: {description: $desc, name: $name, status: true, business_class: $class_id}) {
    returning {
      id
    }
  }
}
`;


export const UPDATE_ADDITIONAL_BENEFIT = gql`
mutation MyMutation($id: uuid!, $name: String!, $desc: String!, $class_id: uuid!) {
  update_additional_benefit(where: {id: {_eq: $id}}, _set: {name: $name, description: $desc, business_class: $class_id}) {
    returning {
      id
    }
  }
}
`;


export const DELETE_ADDITIONAL_BENEFIT = gql`
  mutation MyMutation($id: uuid!) {
    delete_additional_benefit(where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`;


export const DELETE_COMPANY_LOADING = gql`
  mutation DeleteCompanyLoading($id: uuid!) {
    delete_company_loading(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;


export const ADD_DRIVER_STATION = gql`
  mutation MyMutation($name: String!) {
    insert_driver_stations(objects: { name: $name }) {
      returning {
        id
      }
    }
  }
`;






