import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { ADD_BUSINESS_COVERTYPE } from "../../../../GraphQl/mutations";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckbox } from "react-icons/io";
import { DELETECOVER } from "../../../../GraphQl/mutations";

import { useLocation } from "react-router-dom";

import CoverType from "./CoverType";
import Benefits from "./Benefits";
import Discount from "./Discount";
import { business_coverType, Company_Subclass_CoverType } from "../../../../GraphQl/queries";
import { CoverTypes } from "../../../../types/query";
import axios from "axios";

type passedValueType = {
  businessId: string;
  subClassId: string;
  getCompanyId: string;
  getClass: string;
  getSubClass: string;
  getDescription: string;
};

type CoverType = {
  id: string;
  name: string;
  description: string;
};

type FormDataType = {
  company_id: string;
  business_class_id: string;
  sub_class_id: string;
  covertypes: CoverType[];
};

type registeredCoverType = {
  covertypes: { name: string; id: string,description:string }[];
  description: string;
  id:string;
};

const getCoverTypesIds = (data: registeredCoverType[]) => {
  let ids = [] as string[];
  data?.forEach((values: registeredCoverType) => {
    values?.covertypes?.forEach(vl=>{
      ids.push(vl.id)
    })
   
  });

  return ids;
};

const ManageCoverTypes = () => {
  const location = useLocation();
  const {
    businessId,
    subClassId,
    getCompanyId,
    getClass,
    getSubClass,
    getDescription,
  } = location.state as passedValueType;

  const [isSelectAllIds, setIsSelectAllIds] = useState<boolean>(false);
  const [searchClassText, setSearchClassText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const PAGE_SIZE = 10;

  const [
    addCoverType,
    { data: formReturnData, loading: formLoading, error: formError },
  ] = useMutation(ADD_BUSINESS_COVERTYPE);

  const [formData, setFormData] = useState<FormDataType>({
    company_id: getCompanyId,
    business_class_id: businessId,
    sub_class_id: subClassId,
    covertypes: [],
  });

  const {
    loading: registeredCoverTypeLoading,
    error: registeredCoverTypeError,
    data: registeredCoverTypeData,
    refetch: registeredCoverRefetch,
  } = useQuery(Company_Subclass_CoverType, {
    variables: {
      sub_class_id: subClassId,
      company_id: getCompanyId,
    },
  });

  const {
    loading: coverTypeLoading,
    error: coverTypeError,
    data: coverTypeData,
    refetch: coverTyepRefetch,
  } = useQuery(business_coverType, {
    variables: {
      id: businessId,
    },
  });

  

  // const handleSelectCoverType = (id: string, name: string, status: string, description: string) => {
  //   const coverTypeExists = formData.covertypes.some(
  //     (coverType) => coverType.id === id
  //   );

  //   const updatedCoverTypes = coverTypeExists
  //     ? formData.covertypes.filter((coverType) => coverType.id !== id)
  //     : [...formData?.covertypes, { id, name, status, description }];

  //   setFormData({ ...formData, covertypes: updatedCoverTypes });
  // };

  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);

  const filterData = (filter: registeredCoverType) => {
    let isTextMatch =false

        filter?.covertypes?.forEach(val=>{
          isTextMatch = val?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                        val?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
         
        })
      // filter?.description
      //   ?.toLowerCase()
      //   ?.includes(searchText?.toLowerCase()) ||
      // (filter?.covertypes &&
      //   filter?.covertypes?.forEach(vl=>{
      //     vl.name?.toLowerCase().includes(searchText?.toLowerCase())
      //   }))
         
    return isTextMatch;
  };

  const filterCoverTypes = (filter: {
    id: string;
    name: string;
    description: string;
    status: string;
  }) => {

    let isTextMatch = false

    if (!getCoverTypesIds( registeredCoverTypeData?.company_subclass_covertypes)?.includes(filter?.id)){
      isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchClassText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchClassText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchClassText?.toLowerCase());
    }
   
   
    return isTextMatch;


  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleClassSearch = (e: any) => {
    setSearchClassText(e.target.value);
  };

  // Filtered and paginated data for the current page
  const paginatedData = registeredCoverTypeData?.company_subclass_covertypes
    ?.filter(filterData)
    ?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);



  const paginatedCoverTypes = coverTypeData?.covertypes?.filter(filterCoverTypes);
 

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (registeredCoverTypeData?.company_subclass_covertypes?.filter(filterData)
        .length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };
 


  const handleCreate = async () => {
    setIsSelectAllIds(false);

    
    if (formData?.covertypes?.length <= 0) {
      toast.info("Select at least one Cover Type");
    } 
    else {
      const url = `${process.env.REACT_APP_BASE_URL}/auth/add-covertype-to-subclass-company?company_id=${formData?.company_id}&sub_class_id=${formData?.sub_class_id}`;
      
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let data = await [] as CoverType[]
      formData?.covertypes?.forEach((value:CoverType)=>{
        data?.push(value)
      })
    
      if (registeredCoverTypeData?.company_subclass_covertypes?.[0]?.covertypes?.length >0){
        data = [...data, ...registeredCoverTypeData?.company_subclass_covertypes?.[0]?.covertypes]
      }
     
     
      axios
      .post(url, data, { headers })
      .then((response) => {
        coverTyepRefetch(); 
        registeredCoverRefetch();
        toast.success("Cover Created Successfully");
        setFormData({
          company_id: getCompanyId,
          business_class_id: businessId,
          sub_class_id: subClassId,
          covertypes: [],
        })
      })
      .catch((error) => {
        toast.error("Sorry, something went wrong");
      });
    

      // setFormData({
      //   ...formData,
      //   covertypes: [],
      // });
      // setIsSelectAllIds(false)

     
    }

  };

 

  const handleDelete = async (id:string) => {
   
        try {
          const url = `${process.env.REACT_APP_BASE_URL}/auth/delete-covertype-from-subclass-company?company_id=${getCompanyId}&sub_class_id=${subClassId}&covertype_id=${id}`;
      
          const response = await axios.post(url, {
            headers: {
              Accept: "application/json"
            },
          });

          coverTyepRefetch()
          registeredCoverRefetch()
  
          toast.success("Cover Type deleted successfully");
        } catch (error) {
          toast.error("Failed to delete cover type");
        }
      
      
  }


  const handleSelectAllCoverTypes = () => {
    if (isSelectAllIds == false) {
      setIsSelectAllIds(true);
      
      const newData = [] as CoverType[];
      paginatedCoverTypes?.forEach((value:CoverType,index:number) => {
        formData?.covertypes?.[index]?.id !== value?.id && 
       newData.push({
        id:value?.id,
        name:value?.name,
        description:value?.description
      })
        
      });


      setFormData({ ...formData, covertypes: [...formData?.covertypes, ...newData] });

 
      
    } else {
      setIsSelectAllIds(false);

      setFormData({ ...formData, covertypes: [] });
    }
  };

  const handleSelectCoverTypeId = (value: CoverType) => {
    let newData = [] as CoverType[];
    
    if (isSelectAllIds == true) {
      setIsSelectAllIds(false);
    }

    newData = formData?.covertypes?.filter((v:CoverType, index)=>{

      return v?.id !== value?.id
    })

    if (newData?.length == formData?.covertypes?.length){
        setFormData({ ...formData, covertypes: [...formData?.covertypes, value] });

    }else{
        setFormData({ ...formData, covertypes: [...newData] });
    }
   
  };


  return (
    <>
      <CustomHeader title="COVER TYPES MANAGEMENT" />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Class", "SubClass", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {getClass}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {getSubClass}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize"
                  >
                    {getDescription}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>
      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Select Cover Types</Typography>
            </div>
            <div className="p-3 bg-white">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchClassText}
                  onChange={handleClassSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
              <div className="flex  justify-end items-center my-5">
                  <button
                    className="flex gap-2 items-center"
                    onClick={() => handleSelectAllCoverTypes()}
                  >
                    {isSelectAllIds ? (
                      <IoMdCheckbox size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank />
                    )}
                    Select All
                  </button>
                </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["CoverType", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedCoverTypes?.map(
                          (
                            item: {
                              id: string;
                              name: string;
                              description: string;
                            
                            },
                            index: number
                          )  => (
                    <tr
                      onClick={() => {
                        handleSelectCoverTypeId({
                          id:item?.id,
                         name: item?.name,
                         description: item?.description
                        })
                        // handleSelectCoverType(
                        //   item?.id,
                        //   item?.name,
                        //   item?.status,
                        //   item?.description
                        // );
                      }}
                      key={item.id}
                      className={`transition-all hover:cursor-pointer hover:bg-gray-100 bg-white`}
                    >
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize flex gap-5"
                        >
                          {formData?.covertypes?.some(
                            (coverType) => coverType.id === item?.id
                          ) ? (
                            <IoMdCheckbox />
                          ) : (
                            <MdOutlineCheckBoxOutlineBlank />
                          )}
                          {item.name}
                        </Typography>
                      </td>
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize "
                        >
                          {item?.description}
                        </Typography>
                      </td>
                    </tr>

                        )
                      )}
                </tbody>
            </table>
          </div>
        
{/* 
      {display === "COVERTYPE" && (
        <CoverType
          getCompanyId={getCompanyId}
          businessId={businessId}
          subClassId={subClassId}
        />
      )} */}

      {/* {
        display === "BENEFIT" && 
        <Benefits/>
      } */}

          <div className="mt-5 flex items-end justify-end">
            <Button
              onClick={() => handleCreate()}
              size="sm"
              className="capitalize flex flex-row items-center bg-red-400 font-normal"
            >
              <BiPlus size={30} />

              {formLoading ? "Creating..." : "Add Cover Type"}
            </Button>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of Cover Type under {getSubClass}</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {["no", "Covertype", "Description",""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>

                  {
                  paginatedData?.[0]?.covertypes &&
                  paginatedData?.[0]?.covertypes.map(
                    (item: CoverType, index: number) => {
                      
                      const isLast = index === paginatedData?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                          onClick={() => { }}
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {index+1}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                             {item?.name || ""}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.description || ""}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <div className="flex flex-row items-center gap-x-2">
                              <IconButton
                                onClick={(e) => {
                                  handleDelete(item?.id);
                                
                                }}
                                color="red"
                                size="sm"
                              >
                                <BiTrash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>

            {registeredCoverTypeLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !registeredCoverTypeLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (registeredCoverTypeData?.company_subclass_covertypes?.filter(
                          filterData
                        ).length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (registeredCoverTypeData?.company_subclass_covertypes?.filter(
                          filterData
                        ).length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    
      <div className="h-20" />
    </>
  )
}

export default ManageCoverTypes;
