import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { CustomModal } from "../../../components";
import {
  GETALLMESSAGETEMPLATES,
  GETMESSAGETEMPLATEBYTYPE,
} from "../../../GraphQl/queries";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADDMESSAGETEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  UPDATEMESSAGETEMPLATE,
} from "../../../GraphQl/mutations";
import { toast } from "react-toastify";
import TemplateTable from "../../../components/forms/message-templateForm";

const Template = () => {
  type MessageTemplate = {
    // id: any;
    // type: string;
    // body: string;
    // variables: string;
    message_template: any;
    id: any;
    message_body: string;
    message_type: string;
    message_variables: [];
  };
  interface MessageType {
    id: any;
    name: string;
    description: string;
    status: string;
  }
  type FormType = {
    id: any;
    message_body: string;
    message_type: string;
    message_variables: [];
  };

  const [isEditing, setIsEditing] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState<number | null>(null);
  const [editingItem, setEditingItem] = useState<MessageType | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState();
  const PAGE_SIZE = 4;

  const {
    handleSubmit,
    formState: { isSubmitting },
    register,
    reset,
    setValue,
  } = useForm<FormType>();

  const { data, loading, refetch } = useQuery<{
    message_template: MessageTemplate[];
  }>(GETALLMESSAGETEMPLATES);

  // const { data: MessageTypeData } = useQuery(GETMESSAGETEMPLATEBYTYPE, {
  //   variables:{
  //     id:
  //   }
  // });

  const paginatedData: any[] = data?.message_template || [];

  // const messageTypes = MessageTypeData?.message_type || [];
  // console.log("messageTypes", messageTypes);

  const [addMessageTemplate, { loading: RequestLoading }] = useMutation(
    ADDMESSAGETEMPLATE,
    {
      onCompleted: (data) => {
        toast.success("Template Successfully added");
        reset();
        refetch();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating template"),
    }
  );

  const [UpdateMessageTemplate] = useMutation(UPDATEMESSAGETEMPLATE, {
    onCompleted: (data) => {
      toast.success("Template updated Successfully");
      setEditingItem(null);
      reset();
      refetch();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error updating template"),
  });

  const [DeleteMessageTemplate] = useMutation(DELETE_MESSAGE_TEMPLATE, {
    onCompleted: (data) => {
      toast.success("Template deleted successfully");
      refetch();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting template"),
  });

  const onSubmit: SubmitHandler<FormType> = (data) => {
    console.log("Form Data Submitted:", data);
    console.log("editting itemm >>>", editingItem);

    try {
      if (editingItem) {
        UpdateMessageTemplate({
          variables: {
            id: editingItem.id,
            message_type: data.message_type,
            variables: [...data.message_variables],
            body: data.message_body,
            // status: data.message_type,
            // name: data.message_variables,
            // des: data.message_body,
          },
        });

        console.log("upadated data", data);
      } else {
        addMessageTemplate({
          variables: {
            message_type: data.message_type,
            message_variable: [...data.message_variables],
            message_body: data.message_body,
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating premium rate");
    }
  };

  const deleteMessageTemplate = (id: string) => {
    try {
      DeleteMessageTemplate({
        variables: {
          id,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting template");
    }
  };

  const paginatedItems = paginatedData?.slice(
    (activePage - 1) * PAGE_SIZE,
    activePage * PAGE_SIZE
  );

  const nextPage = () => {
    if (activePage * PAGE_SIZE < paginatedData.length) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleEdit = (index: number) => {
    const itemToEdit = paginatedData[index];
    setEditingItem(itemToEdit);
    setValue("message_type", itemToEdit.message_type);
    setValue("message_body", itemToEdit.message_body);
    setValue("message_variables", itemToEdit.message_variables);
  };

  const handleDelete = (index: number) => {
    const messageId = paginatedData[index].id;
    deleteMessageTemplate(messageId);
  };
  console.log("paginatedData", paginatedData);
  console.log(data);
  console.log("Form state:", { isSubmitting });

  return (
    <>
      <CustomHeader title="Message Template" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="rounded-lg overflow-hidden w-full">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              {isEditing
                ? "Update Message Template"
                : "Create Message Template"}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col">
              <div className="w-full">
                <div className="w-full flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    type="type"
                    className="mr-2 mb-2 font-semibold text-[14px] w-18"
                  >
                    Type
                  </Typography>
                  <select
                    id="Class"
                    {...register("message_type", { required: true })}
                    className="capitalize h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ml-8 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {paginatedData?.map((data, index) => (
                      <option key={index} value={data.message_type}>
                        {data.messageTypeByMessageType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-full mb-6 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    type="body"
                    className=" mr-9 font-semibold text-[14px] w-3/3"
                  >
                    Body
                  </Typography>
                  <textarea
                    {...register("message_body", { required: true })}
                    className="rounded-lg border-[#B0BEC5] border-[1px] mt-2 p-2 h-28 w-full"
                  ></textarea>
                </div>
                <div className="w-full flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    type="variables"
                    className=" mb-2 font-semibold text-[14px] w-18"
                  >
                    Variables
                  </Typography>
                  <select
                    id="Class"
                    {...register("message_variables", { required: true })}
                    className="capitalize h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ml-2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {paginatedData?.map((data, index) => (
                      <option key={index} value={data.message_variables}>
                        {data.message_variables}
                      </option>
                    ))}
                    {/* {paginatedData?.map((data, index) => {
                      return data.message_variables.map(
                        (messageVariable: any, messageIndex:) => (
                          <option
                            key={`${index}-${messageIndex}`}
                            value={messageVariable}
                          >
                            {messageVariable}
                          </option>
                        )
                      );
                    })} */}
                  </select>
                </div>
              </div>
            </div>
            <div className="m-2 flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
                // onClick={() => console.log()}
              >
                {isSubmitting || RequestLoading
                  ? "Creating..."
                  : "Create Template"}
              </Button>
            </div>
          </form>
        </div>
        <div>
          <CardBody className="shadow-sm overflow-x-scroll px-0 py-0 rounded-3xl">
            <div className="bg-black pt-5">
              <Typography
                variant="h6"
                color="black"
                className="px-4 pb-4 font-bold text-brand-primary leading-none opacity-70 capitalize"
              >
                List of All Message Templates
              </Typography>
            </div>
            <div className="w-full">
              <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
                <div className="flex flex-col md:flex-row lg:flex-row gap-3 w-full">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
              </div>
            </div>

            <table className="w-full bg-white  table-auto overflow-scroll overflow-x-scroll">
              <thead>
                <tr>
                  {["No.", "Message", "Body", "Variables", ""].map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData &&
                  paginatedItems.map((item, index) => {
                    const isLast = index === paginatedItems.length - 1;
                    const className = isLast
                      ? "px-2 py-3 text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <TemplateTable
                        item={item}
                        index={index}
                        activePage={activePage}
                        PAGE_SIZE={PAGE_SIZE}
                        setOpenModal={setOpenModal}
                        handleEdit={handleEdit}
                        isLast={isLast}
                        handleDelete={handleDelete}
                      />
                    );
                  })}
              </tbody>
            </table>

            {paginatedData && paginatedData.length > 0 && (
              <div className="p-3 flex items-center justify-center bg-white">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(paginatedData.length / PAGE_SIZE)}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={activePage * PAGE_SIZE >= paginatedData.length}
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
            {paginatedData?.length < 1 && (
              <div className="bg-white p-3 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {openModal && (
              <CustomModal
                title="Message Template"
                onClose={() => setOpenModal(false)}
                open={openModal}
                children={
                  <>
                    <div className="flex flex-col px-6">
                      <div className="flex flex-row justify-between items-center">
                        <span>Message Type</span>
                        <p>Onboarding</p>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </CardBody>
        </div>
      </div>
    </>
  );
};

export default Template;
