import React from "react";
import { FaCheck } from "react-icons/fa";
import Logo from "../../assets/logo.png";

const PaymentSuccessPage = () => {
  return (
    <div className="h-screen bg-white flex flex-col items-center justify-center">
      <div className="mb-10">
        <img src={Logo} alt="logo" className="w-36 object-cover" />
      </div>
      <div className="w-full max-w-md mx-auto bg-white p-3 shadow-md rounded-lg  ">
        <div className="text-center flex flex-col items-center justify-center">
          <div className="my-5">
            <div className="w-16 h-16 bg-[#66d445] rounded-full flex items-center justify-center">
              <FaCheck size={30} color="white" />
            </div>
          </div>
          <div className="text-lg lg:text-xl font-extrabold  text-[#66d445] capitalize tracking-wider">
            <h1>payment successful!</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
