import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import systemconfig from "../../../../systemconfig";
import {
  ADD_BUSINESS_DISCOUNT,
} from "../../../../GraphQl/mutations";

import { useHistory } from "react-router-dom";
import {
  GET_ALL_BUSINESS_CLASSES,
  business_discount,
  GET_COMPANY_DISCOUNT,
  get_benefits_company
} from "../../../../GraphQl/queries";
import { MdOutlineCheckBoxOutlineBlank, MdPolicy } from "react-icons/md";
import { IoMdCheckbox } from "react-icons/io";
import { DELETECOMPANYDISCOUNT } from "../../../../GraphQl/mutations";



type registeredDiscountType = {
  business_class: {
    id: string;
    name: string
  },
  discount: {
    id: string,
    description: string,
    businessClassByBusinessClass:{
      id:string,
      name:string
    }
  }
  rate: string,
  id: string
}

type DiscountType = {
  discount_id: string;
};

type FormDataType = {
  company_id: string;
  discount_id: DiscountType[]; // Change to an array of DiscountType objects
  description: string;
};


const ManagePolicyDiscount = () => {
  const history = useHistory();
  const getCompanyId = localStorage.getItem("company_id") as string;
  const getCompanyName = localStorage.getItem("company_name") as string;

  const [searchClassText, setSearchClassText] = useState("");
  const [searchSubClassText, setSearchSubClassText] = useState("");
  const [selectedSubClass, setSelectedSubClass] = useState<string | null>(null);
  const [selectedClassId, setSelectedClassId] = useState<string | null>(null);
  const [loadingSave, setLoading] = useState(false);

  const PAGE_SIZE = 10;

  const [
    addDiscount,
    { data: formReturnData, loading: formLoading, error: formError },
  ] = useMutation(ADD_BUSINESS_DISCOUNT);

  const [formData, setFormData] = useState<FormDataType>({
    company_id: getCompanyId,
    discount_id: [],
    description: "",
  });

  const {
    loading: classLoading,
    error: classError,
    data: classData,
    refetch: classRefetch,
  } = useQuery(GET_ALL_BUSINESS_CLASSES);

  const {
    loading: registeredDiscountLoading,
    error: registeredDiscountError,
    data: registeredDiscountData,
    refetch: registeredDiscountRefetch,
  } = useQuery(GET_COMPANY_DISCOUNT, {
    variables: {
      company_id: getCompanyId
    }
  });

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    // Toggle the selectAll state
    setSelectAll(!selectAll);

    // If selectAll is true, set all items to the selected state
    if (!selectAll) {
      setFormData({
        ...formData,
        discount_id: paginatedSubClass?.map((item: any) => ({ discount_id: item.id })), // Select all ids as objects
      });
    } else {
      setFormData({
        ...formData,
        discount_id: [], // Deselect all (make it an empty array)
      });
    }
  };



  const handleSelectItem = (itemId: string) => {
    // Check if the item is already in the discount_id array
    const exists = formData.discount_id.some((discount) => discount.discount_id === itemId);
  
    if (exists) {
      // If it exists, remove it from the array
      setFormData({
        ...formData,
        discount_id: formData.discount_id.filter((discount) => discount.discount_id !== itemId),
      });
    } else {
      // Otherwise, add it to the array
      setFormData({
        ...formData,
        discount_id: [...formData.discount_id, { discount_id: itemId }],
      });
    }
  };
  









  const {
    loading: discountLoading,
    error: discountError,
    data: discountData,
    refetch: discountRefetch,
  } = useQuery<any>(business_discount, {
    variables: {
      id: selectedClassId,
    },
    skip: selectedClassId == null,
  });



  const createPayload = () => {
    return formData.discount_id.map((discount) => ({
      discount_id: discount.discount_id,
    }));
  };
  

  const addDiscountToCompany = async () => {
    setLoading(true);

    const payload = createPayload();
    try {
      const response = await fetch(`${systemconfig.baseUrl}/auth/add-discount-to-company?company_id=${getCompanyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const req = await response.json();

      discountRefetch();
      registeredDiscountRefetch();
      setFormData({
        company_id: getCompanyId,
        discount_id: [],
        description: "",
      });
      toast.success("Class and Discount Created Successfully");

    } catch (error: any) {
      console.error(error); // Log the error for debugging
      toast.error(error?.message ?? "Failed to add discount");
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  const handleCreate = async () => {
    if (formData.discount_id.length <= 0) {
      toast.info("Select discount");
    } else {
      try {
        await addDiscountToCompany(); // Await the async function
      } catch (err) {
        console.error(err); // Log the error for debugging
        toast.error("Sorry, something went wrong");
      }
    }
  };


  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);

  const filterData = (filter: registeredDiscountType) => {
    const isTextMatch = filter?.business_class?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
      || filter?.discount?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
      || filter?.rate?.includes(searchText?.toLowerCase())
    return isTextMatch;
  };

  const filterClass = (filter: {
    name: string;
    description: string;
    status: string;
  }) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchClassText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchClassText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchClassText?.toLowerCase());
    return isTextMatch;
  };

  const getDiscountIds = (data: registeredDiscountType[]) => {
    let ids = [] as string[];
    data?.map((values: registeredDiscountType) => {
      ids.push(values?.discount?.id);
    });

    return ids;
  };

  const filterdiscount = (filter: {
    name: string;
    description: string;
    status: string;
    id: string
  }) => {
    let isTextMatch = false
    if (!getDiscountIds(registeredDiscountData?.company_discounts)?.includes(filter?.id)) {
      isTextMatch = filter?.name
        ?.toLowerCase()
        ?.includes(searchSubClassText?.toLowerCase());
    }
    return isTextMatch
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleClassSearch = (e: any) => {
    setSearchClassText(e.target.value);
  };

  const handleSubClassSearch = (e: any) => {
    setSearchSubClassText(e.target.value);
  };

  // Filtered and paginated data for the current page
  const paginatedData = registeredDiscountData?.company_discounts
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);


  const paginatedBusinessClass =
    classData?.business_classes?.filter(filterClass);

  const paginatedSubClass = discountData?.discount?.filter(filterdiscount);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (discountData?.discount?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleManagecoverType = (
    businessId: string,
    businessClass: string,
    subClass: string,
    description: string,
    subClassId: string
  ) => {
    history.push("/dashboard/setup/manage_policy_cover_type", {
      businessId: businessId,
      subClassId: subClassId,
      getCompanyId,
      getClass: businessClass,
      getSubClass: subClass,
      getDescription: description,
    });

    ;
  };

  const [DeleteCoverType, { error: deleteError, loading: DeleteLoading }] =
    useMutation(DELETECOMPANYDISCOUNT, {
      onCompleted: (data) => {
        console.log("deletedt", data)
        toast.success("You have successfully deleted covertype");
        discountRefetch();
        registeredDiscountRefetch()

      },
      onError: (error) => toast.error(error?.message),
    });

  const handleDelete = (id: any) => {
    DeleteCoverType({
      variables: {
        id: id,
      },
    });

  }

  return (
    <>
      <CustomHeader title="Manage Company Policy Setups" />
      <div className="mt-5 flex items-end gap-5 mb-5 ">
        <Button
          onClick={() => {
            history.push("/dashboard/setup/manage_policy");
            ;
          }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          SubClass/Covertypes
        </Button>
        <Button
          //   onClick={() => handleCreate()}
          size="md"
          className="capitalize flex flex-row items-center bg-red-400  tracking-widest text-sm font-medium"
        >
          Discount
        </Button>
        <Button
          onClick={() => { history.push("/dashboard/setup/manage_benefit") }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          Additional Benefit
        </Button>
        <Button
           onClick={() => { history.push("/dashboard/setup/manage_policy_loadings"); }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          Loadings
        </Button>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Select Business Class</Typography>
            </div>
            <div className="p-3 bg-white">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchClassText}
                  onChange={handleClassSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Class"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBusinessClass?.map((item: { id: string; name: string }) => (
                  <tr
                    onClick={() => {


                      setSelectedClassId(item?.id);
                      if (selectedClassId === item?.id) {
                        setSelectedClassId(null);
                      } else {
                        setSelectedClassId(item?.id);
                      }
                    }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${selectedClassId === item?.id
                      ? "bg-red-200 hover:bg-red-200"
                      : "bg-white"
                      }`}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {classLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          <div className="capitalize bg-white rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Select Discount</Typography>
            </div>
            <div className="p-3 ">
              <div className="w-full mr-3 flex justify-center items-center ">
                <div className=" text-black bg-gray-50/50 text-sm flex space-x-4 gap-2 w-[40%]">
                  Select all
                  <input
                    type="checkbox"
                    checked={selectAll} // Bind the select all state
                    onChange={handleSelectAll} // Handle the select all functionality
                  />
                </div>
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchSubClassText}
                  onChange={handleSubClassSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Discount", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedSubClass?.map(
                  (item: { id: string; name: string; description: string }) => {
                    // Check if the current item is selected
                    const isSelected = formData.discount_id.some(discount => discount.discount_id === item.id);

                    return (
                      <tr
                        onClick={() => handleSelectItem(item.id)}
                        key={item?.id}
                        className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${isSelected ? "bg-white hover:bg-red-200" : "bg-white"}`}
                      >
                        <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm capitalize flex gap-5"
                          >
                            {isSelected ? (
                              <IoMdCheckbox />
                            ) : (
                              <MdOutlineCheckBoxOutlineBlank />
                            )}
                            {item?.name}
                          </Typography>
                        </td>
                        <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm capitalize"
                          >
                            {item?.description}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}


              </tbody>
            </table>

            {discountLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          {/* <div className="w-full my-2 bg-white p-5">
            <Typography
              variant="h6"
              color="blue-gray"
              className="mb-2 font-semibold text-[14px] w-1/3"
            >
              Description
            </Typography>
            <Textarea
              size="lg"
                value={formData.description}
                onChange={(event)=>    setFormData({...formData, description:event.target.value})}
              name="description"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </div> */}

          <div className="mt-5 flex items-end justify-end">
            <Button
              onClick={() => handleCreate()}
              size="sm"
              className="capitalize flex flex-row items-center bg-red-400 font-normal"
            >
              <BiPlus size={30} />

              {loadingSave ? "Creating..." : "Discount"}
            </Button>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">
              List of
              {" " + getCompanyName + " "}
              Discounts
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "Business Class",
                      "Discount",
                      "Action"
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map(
                    (item: registeredDiscountType, index: number) => {
                      const isLast = index === paginatedData?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                          onClick={() => { }}
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {index + 1}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.discount?.businessClassByBusinessClass?.name}
                              {/* {`${item?.peril_name} - ${item?.exclusion_name}`} */}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.discount?.description}
                            </Typography>
                          </td>

                          {/* <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.rate}
                            </Typography>
                          </td> */}


                          <td className={classes}>
                            <div className="flex flex-row  justify-center items-center gap-x-2">
                              <IconButton
                                onClick={(e) => {
                                  handleDelete(item?.id);

                                }}
                                color="red"
                                size="sm"
                              >
                                <BiTrash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>

            {discountLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !discountLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (discountData?.discount?.filter(filterData).length ||
                          0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (discountData?.discount?.filter(filterData).length ||
                          0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManagePolicyDiscount;
