import React, { useEffect, useState } from "react";
import { DashboardNavbar } from "../headers/DashboardHeader";
import { Route, Switch, useHistory } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { AdminprivateRoutes, CompanyPrivateRoutes } from "../../routes";
import { SidebarContent } from "../Sidebar/SidebarContent";
import { MobileSidebar } from "../Sidebar/MobileSidebar";
import { CompanySidebarContent } from "../Sidebar/CompanyDasboard";
import { DASHBOARD_USERTYPE } from "../../types/role";

const DashboardContent = () => {
  const history = useHistory();
  const user_type = localStorage.getItem("user_type");
  const [routes, setRoutes] = useState(
    DASHBOARD_USERTYPE.administrator === user_type
      ? AdminprivateRoutes
      : DASHBOARD_USERTYPE.company === user_type
      ? CompanyPrivateRoutes
      : DASHBOARD_USERTYPE.brokers === user_type
      ? null
      : null
  );

  return (
    <>
      <div className="flex bg-black h-full w-[100vw]">
        <div className="w-80 h-screen hidden lg:flex">
          {DASHBOARD_USERTYPE.administrator === user_type ? (
            <SidebarContent />
          ) : DASHBOARD_USERTYPE.company === user_type ? (
            <CompanySidebarContent />
          ) : DASHBOARD_USERTYPE.brokers === user_type ? (
            <></>
          ) : (
            <></>
          )}
        </div>
        <div className="m-0 md:m-2 lg:m-5 w-[100vw]  ">
          <div className="dashboard-container overflow-y-scroll h-[95vh] relative px-4 lg:px-5 bg-[#222020] rounded-3xl transition-all lg:rounded-3xl md:rounded-3xl">
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes?.map((route, index) => (
                  <Route
                    path={route.layout + route.path}
                    component={route.component}
                    exact={route.exact}
                    key={index}
                  />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardContent;
