import React, { useEffect, useMemo, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { BiCheck, BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { BASEURL } from "../../../util";
import { CustomModal } from "../../../components";
import { useQuery } from "@apollo/client";
import { GETADMINS, GETROLES } from "../../../GraphQl/queries";
import { MdCancel } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { UserType } from "../../../types/user";

type FormType = {
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  phone_number: string;
} & AdminType;

type AdminType = {
  active_status: boolean;
  balance: number;
  block: boolean;
  block_reason: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  phone_number: string;
  pin: string;
  role: {
    id: string;
    name: string;
  };
};

const ManageAdminView = () => {
  const storageData = localStorage.getItem("user");
  const [user, setUser] = useState<UserType>();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectedID, setSlectedID] = useState<string | null>(null);
  const [selectedRow, setSlectedRow] = useState<AdminType | null>(null);
  const [open, setOpen] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [open]);

  let PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating },
    setValue: setUpdateValue,
    watch: watchUpdateValue,
  } = useForm<AdminType>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const block = watchUpdateValue("block");

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const {
    data: admins,
    loading: loadingAdmins,
    refetch,
  } = useQuery<{
    admins: AdminType[];
  }>(GETADMINS);

  const { data, loading: loadingRoles } = useQuery<{
    roles: {
      id: string;
      name: string;
    }[];
  }>(GETROLES);

  const filterData = (filter: AdminType) => {
    const isTextMatch =
      filter?.balance
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.block_reason
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.first_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.last_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.phone_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.role?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = admins?.admins
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (admins?.admins?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onEditClick = (item: AdminType) => {
    setSlectedRow(item);
    setOpen(true);
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      const response = await fetch(`${BASEURL}/auth/create-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
      const req = await response.json();
     
      if (req?.status === 200) {
        toast.success(req?.message ?? "Admin added successfully");
        refetch();
        reset();
      } else {
        toast.error(req?.detail ?? "Error creating Admin");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating Admin");
    }
  };

  const onUpdate: SubmitHandler<AdminType> = async (data) => {
    try {
      const response = await fetch(`${BASEURL}/auth/edit-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          role_id: data?.role?.id,
          phone_number: data?.phone_number,
          admin_id: user?.id,
        }),
      });
      const req = await response.json();
      console.log(req.status)
      
      if (req?.status === 200) {
        toast.success(req?.message ?? "Admin added successfully");
        refetch();
        resetUpdate();
        setOpen(false);
      } else {
        toast.error(req?.detail ?? "Error creating Admin");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating Admin");
    }
  };

  const deleteAdmin = async (admin_id: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/auth/delete-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          admin_id: admin_id,
        }),
      });
      const req = await response.json();
      console.log("Reqa",req)
      if (response.ok) {
        toast.success(req?.message ?? "Admin deleted successfully");
        refetch();

        reset();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      toast.error(error?.message ?? "Error deleting Admin");
    }
  };

  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setUpdateValue(key as keyof typeof defaultValues as any, value);
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    if (storageData) {
      setUser(JSON.parse(storageData));
    }
  }, []);

  console.log(selectedRow);

  return (
    <>
      <CustomHeader title="Manage Admin" />

      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        title="Update Admin"
        size={"md"}
        children={
          <>
            <ToastContainer />
            <form
              onSubmit={handleUpdateSubmit(onUpdate)}
              className="capitalize bg-white rounded-b-xl"
            >
              <div className=" flex flex-col">
                <div className="w-full ">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className=" flex flex-col gap-2">
                      <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Role
                        </Typography>
                        <select
                          id="Class"
                          defaultChecked
                          defaultValue={selectedRow?.role?.id}
                          className="capitalize h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          {...updateRegister("role.id", { required: true })}
                        >
                          <option>
                            {loadingRoles ? "Loading..." : "Select role"}
                          </option>
                          {data?.roles?.map((data) => (
                            <option
                              className="capitalize"
                              label={
                                data?.name === "super_admin"
                                  ? "super admin"
                                  : data?.name
                              }
                              value={data?.id}
                            >
                              {data?.name === "super_admin"
                                ? "super admin"
                                : data?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        first name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("first_name", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Last name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("last_name", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("email", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("phone_number", { required: true })}
                      />
                    </div>
                    {/* <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Block Admin
                      </Typography>
                      <div className="h-full w-10 mr-1">
                        <Input
                          placeholder="Block Reason"
                          crossOrigin="anonymous"
                          size="lg"
                          type="checkbox"
                          className=" !border-t-blue-gray-200 min-w-0 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("block", {
                            required: true,
                          })}
                        />
                      </div>
                      {block === true ? (
                        <Input
                          placeholder="Block Reason"
                          crossOrigin="anonymous"
                          size="lg"
                          className="w-full !border-t-blue-gray-200 flex-1 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...updateRegister("block_reason", {
                            required: true,
                          })}
                        />
                      ) : (
                        <div className="w-full"></div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  className="capitalize flex flex-row items-center bg-red-400 font-normal"
                >
                  {updating ? "Updating..." : "Update Admin"}
                </Button>
              </div>
            </form>
          </>
        }
      />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">Create Admin</Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col">
              <div className="w-full ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Role
                      </Typography>
                      <select
                        id="Class"
                        className="capitalize h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register("role_id", { required: true })}
                      >
                        <option>
                          {loadingRoles ? "Loading..." : "Select role"}
                        </option>
                        {data?.roles?.map((data) => (
                          <option
                            className="capitalize"
                            label={
                              data?.name === "super_admin"
                                ? "super admin"
                                : data?.name
                            }
                            value={data?.id}
                          >
                            {data?.name === "super_admin"
                              ? "super admin"
                              : data?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      first name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("first_name", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Last name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("last_name", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Email
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Phone number
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("phone_number", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
                {isSubmitting ? "Creating..." : "Create Admin"}
              </Button>
            </div>
          </form>
        </div>

        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of Admins</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "No",
                      "Name",
                      "Email",
                      "Phone number",
                      "Role",
                      "Is block",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === []?.length - 1;
                    const className = isLast
                      ? "px-4 py-3  text-left"
                      : "px-4 py-3 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 hover:cursor-pointer transition-all even:bg-gray-200"
                      >
                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {`${item?.first_name} ${item?.last_name}`}
                          </Typography>
                        </td>

                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.email}
                          </Typography>
                        </td>
                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.phone_number}
                          </Typography>
                        </td>

                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.role?.name === "super_admin"
                              ? "super admin"
                              : item?.role?.name}
                          </Typography>
                        </td>

                        {/* <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            GHC{" "}
                            {(item?.balance as any).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td> */}

                        <td
                          className={className}
                          onClick={() => onEditClick(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal ml-4 text-xs capitalize "
                          >
                            {item?.block ? (
                              <BiCheck size={17} className="text-green-500" />
                            ) : (
                              <MdCancel size={17} />
                            )}
                          </Typography>
                        </td>

                        <td className={className}>
                          <div className="flex flex-row items-center gap-x-2 justify-center">
                            <IconButton
                              color="blue"
                              size="sm"
                              onClick={() => onEditClick(item)}
                            >
                              <BiEdit />
                            </IconButton>
                            <IconButton
                              className="flex items-center justify-center"
                              color="red"
                              size="sm"
                              onClick={() => {
                                setSlectedID(item?.id);
                                deleteAdmin(item?.id);
                              }}
                            >
                              {loading && selectedID === item?.id ? (
                                <Spinner className="h-4 w-4" />
                              ) : (
                                <BiTrash />
                              )}
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
          </div>
          {loadingAdmins && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}
          {paginatedData?.length! < 1 && !loadingAdmins && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
          {paginatedData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={prevPage}
                  disabled={activePage === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="white" className="font-normal">
                  Page <strong className="text-white">{activePage}</strong> of{" "}
                  <strong className="text-white">
                    {Math.ceil(
                      (admins?.admins?.filter(filterData).length || 0) /
                        PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={nextPage}
                  disabled={
                    activePage ===
                    Math.ceil(
                      (admins?.admins?.filter(filterData).length || 0) /
                        PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageAdminView;
