import React, { useMemo, useState, useEffect } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import { BASEURL } from "../../../util";
import useSWR from "swr";
import { useForm } from "react-hook-form";
import { CustomModal } from "../../../components";
import { toast } from "react-toastify";
import moment from "moment";
import { RiAdminLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

const FAKEPAYMENTDATA = [
  {
    policy_id: "22424g-422f2444",
    amount: "40,000",
    status: "Not paid",
    channel: "Momo mobile",
    intermediary_name: "Paul Dwamena",
    paid_by: "Paul Dwamena",
  },
];

const options = ["pending payments", "payment history"];

const Payments = () => {
  const [user, setUser] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<any>();

  const PAGE_SIZE = 6;

  const handleOpen = () => setOpen(!open);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  let storageToken = localStorage.getItem("token");

  const { data, isLoading, error, mutate } = useSWR(
    `${BASEURL}/admin/auth/admins`,
    () =>
      fetch(`${BASEURL}/admin/auth/admins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json()),
    { refreshInterval: 1000 }
  );

  const filterData = (admin: any) => {
    const isTextMatch =
      admin?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.role?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.phoneNumber?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = data?.admins
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.admins?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  return (
    <>
      <CustomHeader title="Payments on policies" />

      <div className="mt-10">
        <div className="w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5">
          <Card className="bg-brand-success rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  300
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  TOTAL PAYMENTS
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-purple rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  GH₵7,032.00
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  PAYMENT MADE
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  10
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  OUTSTANDING PAYMENT
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-red rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  GH₵ 60,000.00
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Outstanding amount
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
        </div>

        <div className="mt-5 flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="flex flex-row items-center w-full mr-3">
              <Typography className="w-28">Filter by</Typography>
              <select className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected disabled>
                  Select filter
                </option>
                {["Admins", "Intermediaries", "Brokers"]?.map((item) => (
                  <option
                    className="capitalize"
                    value={item}
                    key={item}
                    label={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <div className="relative flex w-full max-w-[24rem]">
                <Button
                  ripple={false}
                  variant="text"
                  color="blue-gray"
                  className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                >
                  Date
                </Button>
                <Input
                  crossOrigin="anonymous"
                  type="date"
                  placeholder="Mobile Number"
                  className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  size="md"
                  containerProps={{
                    className: "min-w-0",
                  }}
                />
              </div>
            </div>

            <Button className="bg-red-400 text-xs font-normal w-1/2" size="md">
              Filter
            </Button>
          </div>
        </div>

        <div className="w-full mt-6 ">
          <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
            <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
          </div>
        </div>

        <CardBody className="mt-6 shadow-sm overflow-x-scroll  px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              color="black"
              className="px-4 pb-4 font-bold text-brand-primary leading-none opacity-70 capitalize"
            >
              List of All Payments
            </Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Payment Transaction Type",
                  "Transaction Reference",
                  "Payee",
                  "Class",
                  "Subclass",
                  "Amount Paid",
                  "Date Paid",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {FAKEPAYMENTDATA?.map((item: any, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "py-4 px-2  text-center"
                  : "py-4 px-2 border-b border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.name + index.toString()}
                    className="py-3 hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        Subscription payment
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        BrComm44568448
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        KEK Brokers
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        Fire
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        Home Owners
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        USD 150
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment().format("LLL")}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        {/* {isLoading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {paginatedData?.length! < 1 && !isLoading && (
          <div className="mt-10 flex text-white items-center justify-center">
            <Typography variant="h6">No data found</Typography>
          </div>
        )} */}

        {/* Paginator */}
        {/* {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-gray-900">{activePage}</strong> of{" "}
                <strong className="text-gray-900">
{Math.ceil(
  (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
)}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={nextPage}
                disabled={
activePage ===
Math.ceil(
  (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
)
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Payments;
