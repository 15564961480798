import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const CustomBarChart = (props: {
  data:
    | {
        month: string;
        total_amount: number;
      }[]
    | undefined;
}) => {
  let demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  let sortedData = [...(props?.data || [])]?.sort((a, b) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndexA = months?.indexOf(a?.month);
    const monthIndexB = months?.indexOf(b?.month);

    return monthIndexA - monthIndexB;
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
        <YAxis dataKey="total_amount" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="total_amount"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey={"month"}
          label="Month"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomSubscriopChart = (props: {
  data:
    | {
        month: string;
        subscription: number;
      }[]
    | undefined;
}) => {
  let demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  let sortedData = [...(props?.data || [])]?.sort((a, b) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndexA = months?.indexOf(a?.month);
    const monthIndexB = months?.indexOf(b?.month);

    return monthIndexA - monthIndexB;
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
        <YAxis dataKey="subscription" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="subscription"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey={"month"}
          label="Month"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
