import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";

import { IconEdit } from "@tabler/icons-react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATETAX,
  DELETESUBSCRIPTION,
  DELETETAX,
  UPDATE_SUBSCRIPTION_RATE,
} from "../../../GraphQl/mutations";
import {
  GETALLINSURANCECOMPANIES,
  GETALLINTERMEDIARIESBROKER,
  GETALLSUBSCRIPTION,
  GETALLTAX,
  GETBUSINESSCLASSES,
  GETSUBCLASSES,
  GET_CLIENT_TYPES,
} from "../../../GraphQl/queries";
import moment from "moment";
import { BASEURL } from "../../../util";
import { BusinessClassType } from "../../../types/class";
import { SubClassType } from "../business-class/manage";
import systemconfig from "../../../systemconfig";

type FormType = {
  name: string;
  business_class_id: string;
  subclass_id: string;
  description: string;
  usertype: string;
  rate: string;
  user: string;
};

type SUbscriptionType = {
  name(arg0: string, name: any): unknown;
  id: string;
  class: string;
  subclass: string;
  user_type: string;
  rate: string;
  description: string;
  client_name: string;
  created_at: string;
};

const SubscriptionView = () => {
  const usertypes = [
    {
      value: "insurance_company ",
      label: "Insurance Company ",
    },
    {
      value: "broker ",
      label: "Broker",
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;
  const [data, setData] = useState<SUbscriptionType[]>([]);
  const [editingSubscription, setEditingSubscription] =
    useState<SUbscriptionType | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const watchClass = watch("business_class_id");

  const { data: brokers, loading: loadingBrokers } = useQuery<{
    intemediary: {
      address: string;
      bank_details: string;
      email: string;
      id: string;
      intermediary_type: string;
      momo_details: string;
      name: string;
      active_status: string;
      branch: {
        code: string;
      };
      contact_person: string;
      nic_registration_number: string;
      phone_number: string;
      tin_number: string;
      registration_number: string;
    }[];
  }>(GETALLINTERMEDIARIESBROKER);

  const { data: insuranceCompanies, loading: loadingCompanies } = useQuery<{
    insurer_companies: {
      email: string;
      id: string;
      momo_details: string;
      bank_details: string;
      tin_number: string;
      phone: string;
      name: string;
      clientTypeByClientType: {
        name: string;
        id: string;
      };
      client_type: string;
      company_person: string;
      created_at: string;
    }[];
  }>(GETALLINSURANCECOMPANIES);

  const {
    data: subclasses,
    loading: loadingSubClass,
    refetch: refetchSubClass,
  } = useQuery<{
    subclass: SubClassType[];
  }>(GETSUBCLASSES, {
    variables: {
      class_id: watchClass,
    },
  });

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[];
  }>(GETBUSINESSCLASSES);

  const [userttype, setUserttype] = useState("insurance_company");

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch(`${systemconfig.baseUrl}/get-subscription-rates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const req = await response.json();
      if (response?.ok) {
        setData(req.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch subscriptions. Try again");
    }
  };

  const [DeleteSubscription] = useMutation(DELETESUBSCRIPTION, {
    onCompleted: (data) => {
      toast.success("Subscription deleted successfully");
      fetchSubscriptions();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting subscription"),
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: SUbscriptionType) => {
    const isTextMatch =
      filter?.class?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.user_type?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.subclass?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = data
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if ((activePage - 1) * PAGE_SIZE < (data?.filter(filterData).length || 0)) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const [updateSubscriptionRate, { loading: RequestLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_RATE,
    {
      onCompleted: () => {
        toast.success("Subscription updated successfully");
        fetchSubscriptions();
        setIsEditing(false);
        reset();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error updating subscription"),
    }
  );

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    if (data?.business_class_id === "Select class") {
      return toast.error("Select a valid class");
    }
    if (data?.subclass_id === "Select subclass") {
      return toast.error("Select a valid subclass");
    }
  
    try {
      let payload = {
        name: data?.name,
        rate: data?.rate,
        description: data?.description,
        user_type: userttype,
        insurer_company: data?.user,
        subclass_id: data?.subclass_id,
        business_class_id: data?.business_class_id,
        intermediary: "",
      };
  
      const response = await fetch(`${BASEURL}/setup/create_subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        // Handle different HTTP statuses
        const error = await response.json();
        return toast.error(error.message ?? "An error occurred");
      }
  
      const req = await response.json();
      if (req?.status === 200) {
        toast.success(req?.message ?? "Subscription added successfully");
        fetchSubscriptions();
        reset();
      } else {
        toast.error(req?.message ?? "An error occurred");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An unexpected error occurred");
    }
  };
  
  const openEditForm = (subscription: SUbscriptionType) => {
    console.log(subscription);
    setEditingSubscription(subscription);
    setIsEditing(true);
    // Populate form fields
    setValue("name", subscription.client_name);
    setValue("description", subscription.description);
    setValue("usertype", subscription.user_type);
    setValue("user", subscription.client_name);
    setValue("business_class_id", subscription.class);
    setValue("subclass_id", subscription.subclass);
    setValue("rate", subscription.rate);
  };

  // const onSubmit: SubmitHandler<FormType> = async (data) => {
  //   if (data?.business_class_id === "Select class") {
  //     return toast.error("Select a valid class");
  //   }
  //   if (data?.subclass_id === "Select subclass") {
  //     return toast.error("Select a valid subclass");
  //   }

  //   try {
  //     let payload = {
  //       name: data?.name,
  //       rate: data?.rate,
  //       description: data?.description,
  //       user_type: userttype,
  //       insurer_company: data?.user,
  //       subclass_id: data?.subclass_id,
  //       business_class_id: data?.business_class_id,
  //       intermediary: "",
  //     };

  //     const response = await fetch(`${deploymentMode()}/setup/create_subscription`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       body: JSON.stringify(payload),
  //     });
  //     const req = await response.json();
  //     if (req?.status === 200) {
  //       toast.success(req?.message ?? "Subscription added successfully");
  //       fetchSubscriptions();
  //       reset();
  //     }
  //   } catch (error: any) {
  //     toast.error(error?.message ?? "Error creating subscription");
  //   }
  // };

  const deleteSubscription = (id: string) => {
    try {
      DeleteSubscription({
        variables: {
          id,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting subscription");
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <>
      <CustomHeader title="company subscriptions" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              Create Subscription
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Select usertype
                    </Typography>
                    <select
                      onChange={(e) => {
                        setUserttype(e.target.value);
                        setValue("usertype", e.target.value);
                      }}
                      // {...register("usertype", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option
                        label={"Insurance Company"}
                        value={"insurance_company"}
                      >
                        Insurance Company
                      </option>
                      <option label={"Broker"} value={"broker"}>
                        Broker
                      </option>
                      {/* {usertypes?.map((client_type) => (
                        <option
                          key={client_type.label}
                          label={client_type?.label}
                          value={client_type?.value}
                          onClick={() => {
                            console.log(client_type?.value);
                          }}
                        >
                          {client_type?.label}
                        </option>
                      ))} */}
                    </select>
                  </div>
                  <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Select User
                    </Typography>

                    {userttype === "insurance_company" ? (
                      <select
                        {...register("user", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {insuranceCompanies?.insurer_companies?.map(
                          (client_type) => (
                            <option
                              label={client_type?.name}
                              value={client_type?.id}
                            >
                              {client_type?.id}
                            </option>
                          )
                        )}
                      </select>
                    ) : userttype === "broker" ? (
                      <select
                        {...register("user", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {brokers?.intemediary?.map((broker) => (
                          <option label={broker?.name} value={broker?.id}>
                            {broker?.id}
                          </option>
                        ))}
                      </select>
                    ) : null}
                  </div>

                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Business class
                      </Typography>
                      <select
                        id="Class"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register("business_class_id", { required: true })}
                      >
                        <option>
                          {loadingClasses ? "Loading..." : "Select class"}
                        </option>
                        {classes?.business_classes?.map((data) => (
                          <option label={data?.name} value={data?.id}>
                            {data?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Subclass
                    </Typography>

                    <select
                      id="subclass"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("subclass_id", { required: true })}
                    >
                      <option>
                        {loadingSubClass ? "Loading..." : "Select subclass"}
                      </option>
                      {subclasses?.subclass?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                      <option
                        label="All"
                        value="00000000-0000-0000-0000-000000000000"
                      >
                        All
                      </option>
                    </select>
                  </div>

                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Rate
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("rate", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading ? (
                  <Spinner className=" text-white p-2" />
                ) : (
                  <BiPlus size={20} />
                )}
                {editingSubscription ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">
              List of All Subscriptions
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "Client Name",
                      "Business Class",
                      "Subclass",
                      "rate",
                      "Date added",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const className = isLast
                      ? "px-4 py-3  text-left"
                      : "px-4 py-3 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.client_name}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.class}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.subclass ===
                            "00000000-0000-0000-0000-000000000000"
                              ? "all"
                              : item?.subclass}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.rate}%
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {moment(item?.created_at).format("LL")}
                          </Typography>
                        </td>

                        <td className={className}>
                          <div className="flex flex-row items-center justify-center">
                            <button onClick={() => openEditForm(item)}>
                              <IconButton className="bg-[#4CAF4F]" size="sm">
                                <IconEdit stroke={2} size={13} />
                              </IconButton>
                            </button>
                            <IconButton
                              onClick={() => deleteSubscription(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.filter(filterData).length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.filter(filterData).length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default SubscriptionView;
