import React, {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  Checkbox,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import CreatableSelect from "react-select/creatable";
import { ToastContainer, toast } from "react-toastify";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATEPERMISSION, UPDATEPERMISSION } from "../../../GraphQl/mutations";
import {
  GETPERMISSIONS,
  GETPERMISSIONSFORSPECIFICROLE,
} from "../../../GraphQl/queries";
import { PermissionAction, PermissionResponseType } from "../../../types/role";
import { BASEURL } from "../../../util";
import { CustomModal } from "../../../components";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

interface Option {
  readonly label: string;
  readonly value: string;
}

type ResponseType = {
  id: string;
  name: string;
  platform: string;
  actions: ActionType[];
};

// platform;

type ActionType = {
  id: string;
  name?: string;
  action_type?: string;
};

type FormType = {
  platform: string;
  name: string;
  action: string;
  colors: string[];
};

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

type RolesType = {
  created_at: string;
  description: string;
  id: string;
  name: string;
};

type RolePermissionType = {
  permission_setup: {
    id: string;
    name: string;
  };
  role_id: string;
  selected_actions: PermissionAction[];
};

let allCheckbox = [
  {
    id: 1,
    name: "Show Menu Tab",
    action_type: "show_menu",
  },
  {
    id: 2,
    name: "Can Add",
    action_type: "can_add",
  },
  {
    id: 3,
    name: "Can Edit",
    action_type: "can_edit",
  },
  {
    id: 4,
    name: "Can Delete",
    action_type: "can_delete",
  },
  {
    id: 5,
    name: "Can View",
    action_type: "can_view",
  },
];

const RolesAndPermissions = () => {
  const [selected, setSelcted] = useState("");
  const [value, _setValue] = useState<readonly Option[]>([]);
  const [updateValue, _setUpdateValue] = useState<readonly Option[]>([]);
  const [searchText, setSearchText] = useState("");
  const [_loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;
  const [selectedItem, setSelectedItem] = useState<
    RolesType | null | undefined
  >();
  const [permissions, setPermissions] = useState<PermissionResponseType[]>();
  const [existingPermissions, setExistingPermissions] = useState<any[]>([]);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);

  const [state, setState] = useState<{
    name: string;
    description: string;
    id: string;
  }>({
    id: "",
    name: "",
    description: "",
  });
  const [perrmissionRows, setPermissionRows] = useState<RolePermissionType[]>(
    []
  );
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState([
    {
      name: "show menu",
      value: "show_menu",
      check: false,
    },
    {
      name: "can add",
      value: "can_add",
      check: false,
    },
    {
      name: "can edit",
      value: "can edit",
      check: false,
    },
    {
      name: "can delete",
      value: "can delete",
      check: false,
    },
    {
      name: "can view",
      value: "can view",
      check: false,
    },
  ]);

  const defaultValues = useMemo(() => {
    return {
      ...selectedItem,
    };
  }, [showModal]);

  const {
    register: updateRegister,
    handleSubmit: handleSubmitUpdate,
    reset: resetUpdate,
    setValue: setUpdateValue,
    formState: { isSubmitting: isUpdating },
  } = useForm<FormType>({
    defaultValues: defaultValues,
  });

  const [
    GetPermissionForRole,
    { data: role_permissions, loading: loadingPermission, refetch },
  ] = useLazyQuery<{
    company_role_permissions: RolePermissionType[];
  }>(GETPERMISSIONSFORSPECIFICROLE, {
    variables: {
      role_id: selectedItem?.id,
    },
  });

  console.log(selectedItem);

  const {
    data,
    loading,
    refetch: refreshResponse,
  } = useQuery<{
    permission_setup: ResponseType[];
  }>(GETPERMISSIONS);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormType>();

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.platform?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedData = data?.permission_setup
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.permission_setup?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const fetchPermissions = async () => {
    const res = await fetch(`${BASEURL}/get-all-permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const req = await res.json();

    try {
      if (res.ok) {
        setPermissions(req?.data);
      } else {
        toast.error(req?.message ?? req?.detail);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const [CreatePermission, { loading: updating }] = useMutation(
    CREATEPERMISSION,
    {
      onCompleted: (data) => {
        refreshResponse();
        // setUpdateModal(false);
        // setSelectedRow(null);
        _setValue([]);
        reset();
        toast.success("Permission created successfully");
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating permission"),
    }
  );

  const [UpdatePermission, { loading: isdating }] = useMutation(
    UPDATEPERMISSION,
    {
      onCompleted: (data) => {
        refreshResponse();
        // setUpdateModal(false);
        // setSelectedRow(null);
        setShowModal(false);
        setSelectedItem(null);
        resetUpdate();
        _setValue([]);
        reset();
        toast.success("Permission updated successfully");
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error updated permission"),
    }
  );

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const formatedActions = data?.colors?.map((item, index) => {
      return {
        id: index,
        name: item,
        action_type: "show_menu",
      };
    });

    CreatePermission({
      variables: {
        platform: data?.platform,
        name: data?.name,
        action: selected === "menu" ? formatedActions : allCheckbox,
      },
    });
  };

  const onSubmitUpdate: SubmitHandler<FormType> = async (data) => {
    const formatedActions = data?.colors?.map((item, index) => {
      return {
        id: index,
        name: item,
        action_type: "show_menu",
      };
    });

    UpdatePermission({
      variables: {
        id: selectedItem?.id,
        platform: data?.platform,
        name: data?.name,
        action: selected === "menu" ? formatedActions : allCheckbox,
      },
    });
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        _setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  useEffect(() => {
    const selectedValues = value.map((option) => option.value);
    setValue("colors", selectedValues);
  }, [value]);

  useEffect(() => {
    const selectedValues = value.map((option) => option.value);
    setUpdateValue("colors", selectedValues);
  }, [updateValue]);

  useEffect(() => {
    fetchPermissions();
  }, []);

  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setUpdateValue(key as keyof typeof defaultValues as any, value);
      });
    }
  }, [defaultValues]);

  return (
    <>
      <CustomHeader title="Permissions" />

      <CustomModal
        title="Update Permission"
        onClose={() => setShowModal(false)}
        open={showModal}
        children={
          <>
            <ToastContainer />
            <form
              className=" bg-white rounded-b-xl"
              onSubmit={handleSubmitUpdate(onSubmitUpdate)}
            >
              <div className="w-full">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      Platform
                    </Typography>
                    <select
                      className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...updateRegister("platform", { required: true })}
                    >
                      {[
                        { label: "broker", value: "broker" },
                        {
                          label: "insurance company",
                          value: "insurance_company",
                        },
                      ]?.map((cal) => (
                        <option
                          className="capitalize"
                          label={cal?.label}
                          value={cal?.value}
                        >
                          {cal?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      {...updateRegister("name", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      Type
                    </Typography>
                    <select
                      onChange={(e) => {
                        setSelcted(e.target.value);
                      }}
                      className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option>Select type</option>
                      {["menu", "others"]?.map((cal) => (
                        <option className="capitalize" label={cal} value={cal}>
                          {cal}
                        </option>
                      ))}
                    </select>
                  </div>

                  {selected === "menu" ? (
                    <>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Add menus
                        </Typography>
                        <div className="w-full">
                          <CreatableSelect
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(newValue) => _setUpdateValue(newValue)}
                            onInputChange={(newValue) =>
                              setInputValue(newValue)
                            }
                            onKeyDown={handleKeyDown}
                            placeholder="Type and press enter..."
                            value={value}
                            className="w-full"
                          />
                          <p className="text-xs text-red-600 mt-1 font-semibold">
                            Type and press enter to add another
                          </p>
                        </div>
                      </div>
                      <div className="w-full flex flex-row items-center my-1">
                        <Checkbox defaultChecked disabled label="Show menu" />
                      </div>
                    </>
                  ) : selected === "others" ? (
                    <>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Permissions
                        </Typography>
                      </div>
                      <div className="w-full grid grid-cols-2 items-center justify-center">
                        {options?.map((_action) => (
                          <div
                            className="flex flex-row items-center"
                            key={_action?.value}
                          >
                            <label className="text-sm capitalize">
                              {_action?.name}
                            </label>
                            <Checkbox defaultChecked disabled />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <div className="flex items-end justify-end">
                    <Button
                      size="sm"
                      type="submit"
                      className="flex flex-row items-center bg-red-400 font-normal"
                    >
                      {isdating ? (
                        <Spinner />
                      ) : (
                        <span className="flex flex-row items-center">
                          Update Permission
                        </span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              Add Permission
            </Typography>
          </div>
          <form
            className="p-3 bg-white rounded-b-xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full">
              <div className="mb-6 flex flex-col gap-2">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                  >
                    Platform
                  </Typography>
                  <select
                    className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register("platform", { required: true })}
                  >
                    {[
                      { label: "broker", value: "broker" },
                      {
                        label: "insurance company",
                        value: "insurance_company",
                      },
                    ]?.map((cal) => (
                      <option
                        className="capitalize"
                        label={cal?.label}
                        value={cal?.value}
                      >
                        {cal?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                  >
                    Name
                  </Typography>
                  <Input
                    {...register("name", { required: true })}
                    crossOrigin="anonymous"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                  >
                    Type
                  </Typography>
                  <select
                    onChange={(e) => {
                      setSelcted(e.target.value);
                    }}
                    className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option>Select type</option>
                    {["menu", "others"]?.map((cal) => (
                      <option className="capitalize" label={cal} value={cal}>
                        {cal}
                      </option>
                    ))}
                  </select>
                </div>

                {selected === "menu" ? (
                  <>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Add menus
                      </Typography>
                      <div className="w-full">
                        <CreatableSelect
                          components={components}
                          inputValue={inputValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => _setValue(newValue)}
                          onInputChange={(newValue) => setInputValue(newValue)}
                          onKeyDown={handleKeyDown}
                          placeholder="Type and press enter..."
                          value={value}
                          className="w-full"
                        />
                        <p className="text-xs text-red-600 mt-1 font-semibold">
                          Type and press enter to add another
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex flex-row items-center my-1">
                      <Checkbox defaultChecked disabled label="Show menu" />
                    </div>
                  </>
                ) : selected === "others" ? (
                  <>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Permissions
                      </Typography>
                    </div>
                    <div className="w-full grid grid-cols-2 items-center justify-center">
                      {options?.map((_action) => (
                        <div
                          className="flex flex-row items-center"
                          key={_action?.value}
                        >
                          <label className="text-sm capitalize">
                            {_action?.name}
                          </label>
                          <Checkbox defaultChecked disabled />
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}

                <div className="flex items-end justify-end">
                  <Button
                    size="sm"
                    type="submit"
                    className="flex flex-row items-center bg-red-400 font-normal"
                  >
                    {updating ? (
                      <Spinner />
                    ) : (
                      <span className="flex flex-row items-center">
                        Add Permission
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of permissions</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="flex items-center ">
                <div className="w-full">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["Name", "Platform", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index) => (
                    <tr
                      onClick={() => {
                        setSelectedItem(item as any);

                        setState({
                          ...state,
                          name: item?.name,
                          description: "",
                          id: item?.id,
                        });
                        GetPermissionForRole({
                          variables: { role_id: item?.id },
                        }).then((res: any) => {
                          console.log("res", res);

                          setPermissionRows(res.data.company_role_permissions);
                          //   createExistingPermissions(
                          //     res.data.company_role_permissions
                          //   );
                        });
                        setShowModal(true);
                      }}
                      key={item.id}
                      className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${state?.id === item?.id
                        ? "bg-red-200 hover:bg-red-200"
                        : "bg-white"
                        }`}
                    >
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize "
                        >
                          {item?.name}
                        </Typography>
                      </td>
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize "
                        >
                          {item?.platform?.toLowerCase() === "insurance_company"
                            ? "Insurance company"
                            : item?.platform}
                        </Typography>
                      </td>
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Button
                          onClick={() => {
                            setSelectedItem(item as any);

                            setState({
                              ...state,
                              name: item?.name,
                              description: "",
                              id: item?.id,
                            });
                            GetPermissionForRole({
                              variables: { role_id: item?.id },
                            }).then((res: any) => {
                              setPermissionRows(
                                res.data.company_role_permissions
                              );
                              //   createExistingPermissions(
                              //     res.data.company_role_permissions
                              //   );
                            });
                            setShowModal(true);
                          }}
                          size="sm"
                          color="teal"
                          className="text-xs font-normal capitalize bg-green-500"
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}
            </CardBody>
          </div>

          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}

          {paginatedData?.length! < 1 && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {/* Paginator */}
          {paginatedData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={prevPage}
                  disabled={activePage === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                  Page <strong className="text-white">{activePage}</strong> of{" "}
                  <strong className="text-white">
                    {Math.ceil(
                      (data?.permission_setup?.filter(filterData).length || 0) /
                      PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={nextPage}
                  disabled={
                    activePage ===
                    Math.ceil(
                      (data?.permission_setup?.filter(filterData).length || 0) /
                      PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default RolesAndPermissions;
