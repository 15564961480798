import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";

interface ModalProps {
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  loading?: boolean;
  size?: string | "md" | any;
  children?: React.ReactNode | null;
  open: boolean;
}

export function CustomDeleteModal({
  onClose,
  title,
  size,
  loading,
  open,
  onSubmit,
}: ModalProps) {
  return (
    <>
      <Dialog open={open} handler={onClose}>
        <ToastContainer />
        <DialogHeader>{title ? title : "Delete"}</DialogHeader>

        <DialogFooter>
          <Button
            variant="text"
            color="green"
            onClick={onClose}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="flex items-center justify-center"
            variant="gradient"
            color="red"
            onClick={onSubmit}
          >
            {loading ? <Spinner className="w-4 h-4" /> : <span>Delete</span>}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
