import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_DIVISIONS } from "../../../GraphQl/queries";
import { CREATE_NEW_DIVISION } from "../../../GraphQl/mutations";
import ConfirmationDialog from "../../../config/confirmationbox";

const SetupWorkGroupView = () => {
  const { data: clientData, loading, refetch } = useQuery(GET_ALL_DIVISIONS);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [AddCLient, { error, loading: RequestLoading }] = useMutation(
    CREATE_NEW_DIVISION,
    {
      onCompleted: (data) => {
        alert("You have successfully added a new division");
        refetch();
      },
      onError: (error) => alert(error),
    }
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setShowConfirmation(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfirmSubmit = () => {
    AddCLient({
      variables: {
        name: formData.name,
        description: formData.description,
      },
    });
    setShowConfirmation(false);
  };
  return (
    <>
      <CustomHeader title="Divisions and WorkGroups " />
      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={handleConfirmSubmit}
      />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              New Workgroup
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      value={formData.name}
                      onChange={handleChange}
                      name="name"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      value={formData.description}
                      onChange={handleChange}
                      name="description"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={(e) => handleSubmit(e)}
                size="sm"
                className="flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
                {RequestLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of All Workgroups</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    // value={searchText}
                    // onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["name", "description", "", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {clientData?.divisions?.map((item: any, index: number) => {
                    const isLast = index === [{}]?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>
                        <td className={classes}></td>
                        <td className={classes}>
                          <IconButton color="red" size="sm">
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {true && (
              <div className="my-4 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={prevPage}
                    // disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{1}</strong> of{" "}
                    <strong className="text-gray-900">
                      {/* {Math.ceil(
                        (data?.admins?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )} */}
                      1
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={nextPage}
                    // disabled={
                    //   activePage ===
                    //   Math.ceil(
                    //     (data?.admins?.filter(filterData).length || 0) /
                    //       PAGE_SIZE
                    //   )
                    // }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default SetupWorkGroupView;
