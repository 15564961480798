import React, { useState } from "react";
import { NotificationType } from "../../types/notification";
import moment from "moment";
import { Card } from "@material-tailwind/react";
import { NotificationModal } from "../modal/notification-modal";
import { BASEURL } from "../../util";
import useSWR from "swr";
import { toast } from "react-toastify";

const NotificationCard = (props: any) => {
  const cardColor = props?.read
    ? "bg-gray-100 hover:bg-white"
    : "bg-white hover:bg-gray-100";
  const [selected, setSelected] = useState<NotificationType>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  let storageToken = localStorage.getItem("token");

  const handleClick = () => {
    setSelected({
      ...props?.notification,
    });
    readNotification(props?.notification?.id);
    setOpen(true);
  };

  const readNotification = async (id: string | undefined) => {
    try {
      const response = await fetch(
        `${BASEURL}/notifications/read-single-message/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {}
  };

  const deleteSingleNotification = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASEURL}/notifications/delete-single-message/${props?.notification?.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const req = await response.json();
      if (response.ok) {
        setOpen(false);
        toast.success(req.msg);
      } else {
        toast.info(req.msg);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  const toggleModal = () => setOpen(!open);

  React.useEffect(() => {
    if (storageToken) {
    }
  }, []);

  return (
    <>
      <NotificationModal
        onClose={toggleModal}
        onDelete={deleteSingleNotification}
        loading={loading}
        open={open}
        notification={selected}
      />
      {/* <Card
        onClick={handleClick}
        className={`px-3 py-3 max-w-sm mx-auto ${cardColor} shadow-md rounded-md mb-4 hover:cursor-pointer`}
      >
        <h2 className="text-sm font-semibold capitalize mb-1">
          {props?.notification?.title}
        </h2>
        <p className="text-gray-600 text-xs mb-1">
          {props?.notification?.message}
        </p>
        <div className="flex items-center justify-between">
          <p className="text-xs text-brand-indigo">
            {moment(props?.date_created).format("LLL")}
          </p>
          <span
            className={`px-2 py-1 rounded-full text-[8px] font-semibold ${
              props?.read ? "bg-white" : "bg-red-400 text-white"
            }`}
          >
            {props?.read ? "Read" : "Unread"}
          </span>
        </div>
      </Card> */}
    </>
  );
};

export default NotificationCard;
