import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  Card,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALLRISKCLASS,
  GET_COMPREHENSIVE,
  GET_THIRDPARTY,
} from "../../../GraphQl/queries";
import moment from "moment";
import { COMPREHENSIVETYPE } from "../../../types/comprehensive";
import { THIRDPARTYPE } from "../../../types/third_party";
import { CustomModal } from "../../../components";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import {
  ADDTOCOMPREHENSIVE,
  ADDTOTHIRDPARTY,
} from "../../../GraphQl/mutations";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const RiskClass = () => {
  const [Insurance_type, setInsuranceType] = useState("motor");
  const [risk_type, setRiskType] = useState("comprehensive");
  const [selectedSubClass, setSubClass] = useState({
    name: "",
    id: "",
  });
  const [open, setOpen] = useState(false);
  const [comprehensiveState, setComprehensiveState] =
    useState<COMPREHENSIVETYPE>({
      comprehensive: "",
      created_at: "",
      name: "",
      own_damage_excess_rate: "",
      risk_class: {
        id: "",
      },
      third_party_rate_theft: "",
    });

  const [thirdPartyState, setThirdPartyState] = useState<THIRDPARTYPE>({
    basic_premium: "",
    fee_amount: "",
    loading_amount: "",
    risk_class: {
      id: "",
    },
    seat_amount: "",
    total_amount: "",
    created_at: "",
  });

  const {
    data: comprehensive_data,
    loading: comprehensive_loading,
    refetch: refetchComprehensive,
  } = useQuery(GET_COMPREHENSIVE);

  const { data: riskClass, loading: risk_class_loading } =
    useQuery(GET_ALLRISKCLASS);

  const {
    data: third_party_data,
    loading: third_party_loading,
    refetch,
  } = useQuery(GET_THIRDPARTY);

  const handleSubClassChange = (e: any) => {
    setSubClass({
      name: e.target.value,
      id: e.target.id,
    });
  };

  const [CreateThirdParty, { error, loading: RequestLoading }] = useMutation(
    ADDTOTHIRDPARTY,
    {
      onCompleted: (data) => {
        toast.success("Successfully added");
        setThirdPartyState({
          basic_premium: "",
          fee_amount: "",
          loading_amount: "",
          risk_class: {
            id: "",
          },
          seat_amount: "",
          total_amount: "",
          created_at: "",
        });
        refetch();
        refetchComprehensive();
      },
      onError: (error) => toast.error(error?.message),
    }
  );

  const [CreateComprehensive, { loading }] = useMutation(ADDTOCOMPREHENSIVE, {
    onCompleted: (data) => {
      toast.success("Successfully added");
      setComprehensiveState({
        comprehensive: "",
        created_at: "",
        name: "",
        own_damage_excess_rate: "",
        risk_class: {
          id: "",
        },
        third_party_rate_theft: "",
      });
      refetch();
      refetchComprehensive();
    },
    onError: (error) => toast.error(error?.message),
  });

  const SubmitThirdParty = async () => {
    try {
      const data = [
        {
          seat_amount: thirdPartyState?.seat_amount,
          loading_amount: thirdPartyState?.loading_amount,
          basic_premium: thirdPartyState?.basic_premium,
          fee_amount: thirdPartyState?.fee_amount,
          total_amount: thirdPartyState?.total_amount,
          risk_id: selectedSubClass?.name,
        },
      ];

      await CreateThirdParty({ variables: { data } });
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const SubmitComprehensive = async () => {
    try {
      const data = [
        {
          comprehensive: comprehensiveState?.comprehensive,
          own_damage_excess_rate: comprehensiveState?.own_damage_excess_rate,
          third_party_rate_theft: comprehensiveState?.third_party_rate_theft,
          risk_id: selectedSubClass?.name,
        },
      ];

      await CreateComprehensive({ variables: { data } });
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <CustomHeader title="Setup" />

      <CustomModal
        open={open}
        size={"lg"}
        title="New risk class"
        onClose={() => setOpen(false)}
        children={
          <>
            <ToastContainer />
            <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-5">
              <div className="w-full">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-1 font-bold text-sm"
                >
                  Sub class
                </Typography>
                <select
                  id="type"
                  onChange={handleSubClassChange}
                  value={selectedSubClass?.name}
                  className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected disabled>
                    {risk_class_loading ? "Loading..." : "Choose class"}
                  </option>
                  {riskClass?.risk_class?.map(
                    (item: { name: string; id: string }) => (
                      <option
                        className="capitalize"
                        key={item?.id}
                        label={item.name}
                        value={item?.id}
                      >
                        {item.name}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="w-full">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-1 font-bold text-sm"
                >
                  Risk type
                </Typography>
                <select
                  id="type"
                  onChange={(e) => setRiskType(e.target.value)}
                  value={risk_type}
                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option label="Third party" value={"third_party"}>
                    Third party
                  </option>
                  <option label="Comprehensive" value={"comprehensive"}>
                    Comprehensive
                  </option>
                </select>
              </div>
            </div>

            {risk_type === "third_party" ? (
              <ThirdPartForm
                state={thirdPartyState}
                setState={setThirdPartyState}
              />
            ) : (
              <ComprehensiveForm
                state={comprehensiveState}
                setState={setComprehensiveState}
              />
            )}

            <div className="flex items-end justify-end">
              <Button
                onClick={
                  risk_type === "third_party"
                    ? SubmitThirdParty
                    : SubmitComprehensive
                }
                size="md"
                className="bg-brand-primary"
              >
                {RequestLoading || loading ? (
                  <Spinner className="w-6 h-6" />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </>
        }
      />

      <div className="bg-white p-5 rounded-xl">
        <form>
          <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-bold text-sm"
              >
                Insurance type
              </Typography>
              <select
                id="type"
                onChange={(e) => setInsuranceType(e.target.value)}
                value={Insurance_type}
                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option label="Motor" value={"motor"}>
                  Motor
                </option>
                <option label="Fire" value={"fire"}>
                  Fire
                </option>
              </select>
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-bold text-sm"
              >
                Risk type
              </Typography>
              <select
                id="type"
                onChange={(e) => setRiskType(e.target.value)}
                value={risk_type}
                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option label="Third party" value={"third_party"}>
                  Third party
                </option>
                <option label="Comprehensive" value={"comprehensive"}>
                  Comprehensive
                </option>
              </select>
            </div>
          </div>
          <div className="flex items-end justify-end">
            <Button
              onClick={() => setOpen(true)}
              className="bg-brand-primary mx-5"
            >
              {false ? <Spinner className="w-5 h-5" /> : "Add risk class"}
            </Button>
          </div>
        </form>
      </div>

      <div className="bg-white mt-4 rounded-xl">
        {Insurance_type === "motor" && risk_type === "third_party" ? (
          <>
            {third_party_loading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <Card className="rounded-xl h-full w-full overflow-x-scroll">
                <table className="rounded-xl w-full min-w-max table-auto  text-center">
                  <thead>
                    <tr>
                      {[
                        "risk class name",
                        "basic premium",
                        "fee amount",
                        "loading amount",
                        "seat amount",
                        "total amount",
                        "created at",
                        "",
                      ].map((head) => (
                        <th
                          key={head}
                          className="border-b capitalize border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {third_party_data?.third_party_only_risk_class.map(
                      (item: THIRDPARTYPE) => (
                        <tr
                          key={item?.id}
                          className="hover:bg-white even:bg-blue-gray-50/50"
                        >
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.risk_class?.name}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.basic_premium}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.fee_amount}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.loading_amount}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.seat_amount ? item?.seat_amount : "---"}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.total_amount}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {moment(item?.created_at).format("LLL")}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Tooltip content="Edit">
                              <IconButton variant="text">
                                <AiOutlineEdit size={18} color="#222230" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip content="Delete">
                              <IconButton variant="text">
                                <AiOutlineDelete size={18} color="red" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Card>
            )}
          </>
        ) : Insurance_type === "motor" && risk_type === "comprehensive" ? (
          <>
            {comprehensive_loading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <Card className="rounded-xl h-full w-full overflow-x-scroll">
                <table className="rounded-xl w-full min-w-max table-auto  text-center">
                  <thead>
                    <tr>
                      {[
                        "comprehensive rate",
                        "risk class name",
                        "own damage excess rate",
                        "created at",
                        "",
                      ].map((head) => (
                        <th
                          key={head}
                          className="border-b capitalize border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {comprehensive_data?.comprehensive_third_party_risk_class.map(
                      (item: COMPREHENSIVETYPE, index: number) => (
                        <tr key={index} className="even:bg-blue-gray-50/50">
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.comprehensive}%
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.risk_class?.name}
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.own_damage_excess_rate}%
                            </Typography>
                          </td>
                          <td className="py-1 px-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {moment(item?.created_at).format("LLL")}
                            </Typography>
                          </td>

                          <td className="py-1 px-4">
                            <Tooltip content="Edit">
                              <IconButton variant="text">
                                <AiOutlineEdit size={18} color="#222230" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip content="Delete">
                              <IconButton variant="text">
                                <AiOutlineDelete size={18} color="red" />
                              </IconButton>
                            </Tooltip>
                          </td>

                          <td></td>
                          <td></td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Card>
            )}
          </>
        ) : null}
      </div>

      <div className="mt-16"></div>
    </>
  );
};

const ComprehensiveForm = ({
  state,
  setState,
}: {
  state: COMPREHENSIVETYPE;
  setState: React.Dispatch<React.SetStateAction<COMPREHENSIVETYPE>>;
}) => {
  return (
    <>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        {/* <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Name
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div> */}
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Third party rate theft
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.third_party_rate_theft}
            onChange={(e) =>
              setState({ ...state, third_party_rate_theft: e.target.value })
            }
          />
        </div>
      </div>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Comprehensive rate
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.comprehensive}
            onChange={(e) =>
              setState({ ...state, comprehensive: e.target.value })
            }
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Own damage excess rate
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.own_damage_excess_rate}
            onChange={(e) =>
              setState({ ...state, own_damage_excess_rate: e.target.value })
            }
          />
        </div>
      </div>
    </>
  );
};

const ThirdPartForm = ({
  state,
  setState,
}: {
  state: THIRDPARTYPE;
  setState: React.Dispatch<React.SetStateAction<THIRDPARTYPE>>;
}) => {
  return (
    <>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Basic premium
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.basic_premium}
            onChange={(e) =>
              setState({ ...state, basic_premium: e.target.value })
            }
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Seat amount
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.seat_amount}
            onChange={(e) =>
              setState({ ...state, seat_amount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Total amount
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.total_amount}
            onChange={(e) =>
              setState({ ...state, total_amount: e.target.value })
            }
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Fee amount
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.fee_amount}
            onChange={(e) => setState({ ...state, fee_amount: e.target.value })}
          />
        </div>
      </div>
      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-bold text-sm"
          >
            Basic + loading amount
          </Typography>
          <Input
            crossOrigin="anonymous"
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={state?.loading_amount}
            onChange={(e) =>
              setState({ ...state, loading_amount: e.target.value })
            }
          />
        </div>
      </div>
    </>
  );
};

export default RiskClass;
