import {
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { ReactNode } from "react";
import { IoClose } from "react-icons/io5";

interface ModalProps {
  onClose: () => void;
  title?: string;
  size?: string | "lg" | any;
  children?: ReactNode | null;
  open: boolean;
  className?: string;
}

export default function CustomModal({
  onClose,
  open,
  children,
  size,
  title,
  className,
}: ModalProps) {
  return (
    <Dialog
      open={open}
      handler={onClose}
      size={size}
      className={className ? className : ""}
    >
      <div className="flex flex-row items-center justify-between mt-1">
        {title ? (
          <DialogHeader className={(className = "font-semibold text-lg")}>
            {title}
          </DialogHeader>
        ) : (
          <div></div>
        )}
        <IconButton onClick={onClose} className="mr-4">
          <IoClose className="text-lg" />
        </IconButton>
      </div>
      <DialogBody>{children}</DialogBody>
    </Dialog>
  );
}
