import React, { useState, useEffect } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import ConfirmationDialog from "../../../../config/confirmationbox";
import { toast } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import EditConstructionType from "./EditConstructionType";
import { ADD_USE_OF_PROPERTIES } from "../../../../GraphQl/mutations";
import { GET_USE_OF_PROPERTIES } from "../../../../GraphQl/queries";

const UseOfProperty = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [RequestLoading, setRequestLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [constructionFormData, setConstructionFormData] = useState({
    type:"",
    description:""
  });
  const [isCancleEdit, setIsCancleEdit] = useState(true);
  const [selectedConstruction, setSelectedConstruction] = useState<{name:string, id:string}>({name:"", id:""})

  const history = useHistory();
  const PAGE_SIZE = 8;

  const {
    data: property_type,
    loading,
    refetch,
  } = useQuery<{ property_type: { type: string; description: string, id:string }[] }>(GET_USE_OF_PROPERTIES);




  const [AddUseOfProperty, { error: updateError, loading: updateLoading }] =
  useMutation(ADD_USE_OF_PROPERTIES, {
    onCompleted: (data) => {
        toast.success("Use of Property added successfull");
        refetch();
    },
    onError: (error) => {
        toast.error(error?.message ?? "Sorry an error occured")
    },
  });



  const handleSubmit = (event: any) => {
    event.preventDefault();
    setShowConfirmation(true);
  };


//   const handleDelete = (event: any) => {
//     event.preventDefault();
//     setShowConfirmation(true);
//   };

  const handleConfirmSubmit = async () => {
        setRequestLoading(true)
      if (!constructionFormData.type) {
        return toast.error("Construction Type is required");
      }
      else if (!constructionFormData.description){
        return toast.error("Description required")
      }


      AddUseOfProperty({
        variables: {
            // remember to remove code at the backend and here as well
            code :"444",
          desc: constructionFormData.description,
          type: constructionFormData.type
         
        },
      });
    
      setConstructionFormData({
        type:"",
        description:""
      })
      setShowConfirmation(false);
      setRequestLoading(false)
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (item: { type: string }) => {
    const isTextMatch = item?.type
      ?.toLowerCase()
      ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData =property_type?.property_type
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (property_type?.property_type.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleCancleEdit=()=>{
    setIsCancleEdit(true)
  }


  return (
    <>
      <CustomHeader title="Property" />
      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={handleConfirmSubmit}
      />

      

      <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-5">

      <div className={`w-full h-full top-0 left-0  z-10 backdrop-blur-sm bg-black/80 pt-[5rem] flex justify-center transition-all ease-out  ${isCancleEdit ? "hidden":"absolute"}`}>
        <EditConstructionType setCancle={handleCancleEdit} value={selectedConstruction} />
      </div>

        <div className="w-full rounded-lg overflow-hidden">
          <div className="bg-red-400 p-3">
            <Typography className="text-base text-white">Use Of Property</Typography>

          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Type 
                     
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      value={constructionFormData.type}
                      onChange={(event)=>setConstructionFormData({...constructionFormData,type:event.target.value})}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      value={constructionFormData.description}
                      onChange={(event)=>setConstructionFormData({...constructionFormData,description:event.target.value})}
                      name="description"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={(e) => handleSubmit(e)}
                size="sm"
                className="flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
               
                {updateLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">Added Use Of Properties</Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<CiSearch className="cursor-pointer" />}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto">
                <thead className="">
                  <tr>
                    {["name", "Description", "",""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={item?.type + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.type}
                          </Typography>
                        </td>
                        <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>

                        <td className={classes }>
                        <button
                            onClick={() => {
                              localStorage.setItem("construction", item?.type);
                              setSelectedConstruction({name:item?.type, id:item?.id})
                              setIsCancleEdit(false)
                            }}
                            disabled={true}
                            className="font-normal text-xs capitalize bg-brand-indigo px-5 py-2  rounded-md text-white hover:shadow-md "
                          >
                            Edit
                          </button>
                        </td>

                        <td className={classes}>
                          <IconButton
                            // onClick={(e) => {
                            //   handleDelete(e);
                            //   setSelectedId(item?.id);
                           
                            // }}
                            color="red"
                            size="sm"
                            disabled={true}
                          >
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1
             && !loading 
             && (
              <div className="mt-10 flex text-white items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                  
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-700">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-700">
                      {Math.ceil(
                        (property_type?.property_type?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (property_type?.property_type?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="h-20" />
      </div>
    </>
  );
};

export default UseOfProperty;


  