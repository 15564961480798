import React, { useState, useEffect } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiSearch } from "react-icons/bi";
import { FiDownloadCloud } from "react-icons/fi";
import {
  Subscriptions_Table_Head,
  Clients_Table_Head,
  PoliciesSold_Table_Head,
} from "./data";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import {
  GETCLIENTDETAILS,
  GETPOLICIES,
  GETSUBSCRIPTION,
} from "../../../GraphQl/queries";
import moment from "moment";

type SubscriptionType = {
  amount: number;
  datecreated: string;
  reference_number: string;
  insurer_company: {
    name: string;
    phone: string;
    email: string;
  };
  intemediary: {
    email: string;
    id: string;
    name: string;
    phone_number: string;
  };
  status: string;
  subscription_rate: {
    id: string;
    rate: string;
    name: string;
  };
  policy: {
    policy_number: string;
  };
};

type ClientType = {
  address: string;
  client_type: string;
  colors: string[];
  company_person: {
    name: string;
    phone_number: string;
    phone: string;
  };
  contact_person: {
    name: string;
    phone_number: string;
    phone: string;
  };
  created_at: string;
  disbursement_bank_details: {
    name: string;
    app_id: string;
    app_key: string;
    bank_code: string;
    account_name: string;
    account_number: string;
  };
  email: string;
  id: string;
  logo_url: string;
  momo_details: {
    network: string;
  };
  name: string;
  phone: string;
  tin_number: string;
};

type PolicyType = {};

const ReportView = () => {
  const options = ["subscriptions", "clients", "policies sold", ""];
  const [filter, setFilter] = useState("subscriptions");
  const [tableHead, setTableHead] = useState(Subscriptions_Table_Head);

  const [token, setToken] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredData, setFilteredData] = useState<any>([]);
  const [startData, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(``);

  const handleDateChange = () => {
    const currentDate = new Date(startData);
    const currentEndDate = new Date(endDate);

    const startDataTimeStamp = currentDate.getTime();
    const endDataTimeStamp = currentEndDate.getTime();

    if (filter === "subscriptions") {
      refechSubscription({
        enddate: endDate,
        startdate: startData,
      });
    } else if (filter === "clients") {
      refechClientDetails({
        enddate: endDate,
        startdate: startData,
      });
    } else if (filter === "policies sold") {
      refechPoliciesSold({
        enddate: endDate,
        startdate: startData,
      });
    }
  };

  const {
    data: subscriptionData,
    loading: loadingSubscription,
    refetch: refechSubscription,
  } = useQuery<{
    subscription_payment: SubscriptionType[];
  }>(GETSUBSCRIPTION);

  const {
    data: ClientDetails,
    loading: loadingClientDetails,
    refetch: refechClientDetails,
  } = useQuery<{
    insurer_companies: ClientType[];
  }>(GETCLIENTDETAILS);

  const {
    data: policiesSold,
    loading: loadingPoliciesSold,
    refetch: refechPoliciesSold,
  } = useQuery<{
    policy: PolicyType[];
  }>(GETPOLICIES);

  useEffect(() => {
    // Replace your mock data with the actual SWR data
    const selectedData =
      filter === "subscriptions"
        ? subscriptionData?.subscription_payment
        : filter === "clients"
        ? ClientDetails?.insurer_companies
        : filter === "policies sold"
        ? policiesSold?.policy
        : [];

    const filteredItems =
      selectedData && selectedData.length > 0
        ? selectedData.filter((item: any) => {
            if (filter === "subscriptions") {
              // Adjust the filtering logic for the Brand type
              const amount = item?.amount
                ?.toString()
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const insurer_company_email =
                item?.insurer_company?.email?.includes(searchTerm);
              const insurer_company_name =
                item?.insurer_company?.name?.includes(searchTerm);
              const intemediary_ =
                item?.insurer_company?.phone?.includes(searchTerm);
              const intemediary_email =
                item?.intemediary?.email?.includes(searchTerm);
              const intemediary_name =
                item?.intemediary?.name?.includes(searchTerm);
              const intemediary_name_phone =
                item?.intemediary?.phone_number?.includes(searchTerm);
              const policy_number =
                item?.policy?.policy_number?.includes(searchTerm);
              const status = item?.status?.includes(searchTerm);

              return (
                amount ||
                insurer_company_email ||
                insurer_company_name ||
                intemediary_ ||
                intemediary_email ||
                intemediary_name ||
                intemediary_name_phone ||
                policy_number ||
                status
              );
            } else if (filter === "clients") {
              // Adjust the filtering logic for the clients
              const address = item?.address
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const client_type = item?.client_type
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const company_person_name = item?.company_person?.name
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const phone_number = item?.company_person?.phone_number
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const brandNameMatches = item?.email
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const network = item?.momo_details?.network
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const name = item?.name
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const phone = item?.phone
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const tin_number = item?.tin_number
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const account_name = item?.disbursement_bank_details?.account_name
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const disbursement_bank_details_name =
                item?.disbursement_bank_details?.name
                  ?.toLowerCase()
                  ?.includes(searchTerm?.toLowerCase());
              const bank_code = item?.disbursement_bank_details?.bank_code
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());

              return (
                address ||
                client_type ||
                company_person_name ||
                phone_number ||
                brandNameMatches ||
                network ||
                name ||
                phone ||
                tin_number ||
                account_name ||
                disbursement_bank_details_name ||
                bank_code
              );
            } else if (filter === "policies sold") {
              // Adjust the filtering logic for the policies sold
              const nameMatches1 = item?.status
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const policy_number = item?.policy_number
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches2 = item?.policy_stage
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches3 = item?.request?.cover_type
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches4 = item?.request?.amount
                ?.toString()
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches5 = item?.request?.duration
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches6 = item?.request?.payment_channel
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches7 = item?.request?.registration_number_text
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const nameMatches9 = item?.request?.request_stage
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const _nameMatches8 = item?.request?.user_type
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const _nameMatches1 = item?.request?.risk_class?.name
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const _nameMatches2 =
                item?.request?.insurerCompanyByInsurerCompany?.name
                  .toLowerCase()
                  ?.includes(searchTerm?.toLowerCase());
              const _nameMatches3 = item?.request?.currencyByCurrency?.name
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const _nameMatches4 =
                item?.request?.businessClassByBusinessClass?.name
                  .toLowerCase()
                  ?.includes(searchTerm?.toLowerCase());
              const _nameMatches5 =
                item?.request?.vehicle_details?.vehicle_model
                  .toLowerCase()
                  ?.includes(searchTerm?.toLowerCase());
              const _nameMatches6 = item?.request?.vehicle_details?.body_type
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
              const _nameMatches7 =
                item?.request?.vehicle_details?.number_of_seats
                  ?.toString()
                  .toLowerCase()
                  ?.includes(searchTerm?.toLowerCase());

              return (
                policy_number ||
                nameMatches1 ||
                nameMatches2 ||
                nameMatches3 ||
                nameMatches4 ||
                nameMatches5 ||
                nameMatches6 ||
                nameMatches7 ||
                nameMatches9 ||
                _nameMatches8 ||
                _nameMatches1 ||
                _nameMatches2 ||
                _nameMatches3 ||
                _nameMatches4 ||
                _nameMatches5 ||
                _nameMatches6 ||
                _nameMatches7
              );
            } else {
              return false; // Handle other filter types if needed
            }
          })
        : [];

    setFilteredData(filteredItems);
  }, [
    searchTerm,
    filter,
    subscriptionData?.subscription_payment,
    ClientDetails?.insurer_companies,
    policiesSold?.policy,
  ]);

  const handleFilterChange = (value: string) => {
    switch (value) {
      case "subscriptions":
        setTableHead(Subscriptions_Table_Head);
        break;
      case "clients":
        setTableHead(Clients_Table_Head);
        break;
      default:
        setTableHead(PoliciesSold_Table_Head);
        break;
    }
    setFilter(value);
  };

  const handleDownload = () => {
    // Check if there is data to download
    if (filteredData.length === 0) {
      alert("No data to download.");
      return;
    }

    // Define custom headers based on the selected filter
    const firstItem = filteredData[0];
    const headers = Object.keys(firstItem);

    // Transform data to match headers
    const transformedData = filteredData.map((item: any) => {
      const transformedItem: Record<string, any> = {};
      headers.forEach((header) => {
        if (typeof item[header] === "object") {
          transformedItem[header] = JSON.stringify(item[header]);
        } else {
          transformedItem[header] = item[header];
        }
      });
      return transformedItem;
    });

    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData,
    });

    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filter}.csv`;
    document.body.appendChild(a);

    // Trigger a click on the link to start the download
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // const handleDownload = () => {
  //   // Check if there is data to download
  //   if (filteredData.length === 0) {
  //     alert("No data to download.");
  //     return;
  //   }

  //   const transformedData = filteredData.map((item: any) => {
  //     const transformedItem: Record<string, any> = {};
  //     tableHead.forEach((field) => {
  //       if (typeof item[field] === "object") {
  //         transformedItem[field] = JSON.stringify(item[field]);
  //       } else {
  //         transformedItem[field] = item[field];
  //       }
  //     });
  //     return transformedItem;
  //   });

  //   // Convert data to CSV format
  //   const csvData = Papa.unparse({
  //     fields: tableHead,
  //     data: transformedData,
  //   });

  //   // Create a Blob and download link
  //   const blob = new Blob([csvData], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);

  //   // Create a download link
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = `${filter}.csv`;
  //   document.body.appendChild(a);

  //   // Trigger a click on the link to start the download
  //   a.click();

  //   // Cleanup
  //   window.URL.revokeObjectURL(url);
  //   document.body.removeChild(a);
  // };

  useEffect(() => {
    // setFilter("subscriptions");
  }, []);

  return (
    <>
      <CustomHeader title="Reports" />

      <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row gap-3  w-full">
          <div className="flex flex-row items-center w-full mr-3">
            <Typography className="w-28">Filter by</Typography>
            <select
              className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => handleFilterChange(e?.target?.value)}
            >
              <option selected disabled>
                Select filter
              </option>
              {options?.map((item) => (
                <option
                  className="capitalize"
                  value={item}
                  key={item}
                  label={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                From
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={startData}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                To
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>

          <Button
            onClick={handleDateChange}
            className="bg-red-400 text-xs flex items-center justify-center font-normal lg:w-1/2 md:w-1/2"
            size="md"
          >
            {loadingClientDetails ||
            loadingPoliciesSold ||
            loadingSubscription ? (
              <Spinner />
            ) : (
              "Generate Report"
            )}
          </Button>
        </div>
      </div>

      <div className="mt-5 bg-white p-3 rounded-lg">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-3 flex items-center justify-between gap-8">
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <div className="w-full md:w-72">
                <Input
                  crossOrigin="anonymous"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label="Search"
                  icon={<BiSearch className="h-5 w-5" />}
                />
              </div>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Tooltip content="Export as CSV">
                <IconButton onClick={handleDownload} variant="filled" size="sm">
                  <FiDownloadCloud size={20} />
                </IconButton>
              </Tooltip>
              {/* <Tooltip content="Export as Excel">
                <IconButton onClick={handleDownload} variant="filled" size="sm">
                  <FiDownloadCloud size={20} />
                </IconButton>
              </Tooltip> */}
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            {filter === "subscriptions" && Subscriptions_Table_Head && (
              <HeaderRenderer tableHead={Subscriptions_Table_Head} />
            )}

            {filter === "clients" && Clients_Table_Head && (
              <HeaderRenderer tableHead={Clients_Table_Head} />
            )}

            {filter === "policies sold" && PoliciesSold_Table_Head && (
              <HeaderRenderer tableHead={PoliciesSold_Table_Head} />
            )}
            <tbody>
              {filteredData?.map((item: any, index: number) => {
                const isLast = index === filteredData?.length - 1;
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center";

                return (
                  <>
                    {filter === "subscriptions" ? (
                      <SubscriptionTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : filter === "clients" ? (
                      <ClientTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : filter === "policies sold" ? (
                      <RentalTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
          {loadingClientDetails ||
            loadingSubscription ||
            (loadingPoliciesSold && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            ))}

          {filteredData?.length < 0 &&
            (!loadingClientDetails ||
              !loadingSubscription ||
              !loadingPoliciesSold) && (
              <div className="mt-10 flex items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}
        </CardBody>
      </div>
    </>
  );
};

const HeaderRenderer = ({ tableHead }: { tableHead: string[] }) => (
  <thead>
    <tr>
      {tableHead.map((head) => (
        <th
          key={head}
          className="capitalize text-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
        >
          <Typography
            variant="small"
            color="blue-gray"
            className="font-bold leading-none opacity-70"
          >
            {head}
          </Typography>
        </th>
      ))}
    </tr>
  </thead>
);

const SubscriptionTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: SubscriptionType;
}) => (
  <tr
    key={index.toString()}
    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
  >
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className=" font-normal text-xs capitalize"
      >
        {item?.insurer_company?.name}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        {item?.insurer_company?.name}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        {item?.insurer_company?.email}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.insurer_company?.phone}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.policy?.policy_number}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        {item?.intemediary?.name}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        GH₵
        {((item?.amount as any) ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        <span
          className={`${
            item?.status?.toLowerCase() === "paid"
              ? "text-brand-teal"
              : "text-black"
          } p-2 rounded-md`}
        >
          {item?.status}
        </span>
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        {moment(item?.datecreated).format("LLL")}
      </Typography>
    </td>
  </tr>
);

const ClientTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: ClientType;
}) => (
  <tr
    key={item?.name + index.toString()}
    className=" hover:bg-gray-100 transition-all hover:cursor-pointer"
  >
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.client_type}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.name}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs"
      >
        {item?.email}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.phone}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.address}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.momo_details?.network}
      </Typography>
    </td>

    <td className={classes}>
      <div className="flex flex-col items-start justify-start">
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          <span className="font-semibold">Name: </span>
          {item?.company_person?.name || item?.contact_person?.name}
        </Typography>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          <span className="font-semibold">Phone number: </span>
          {item?.company_person?.phone || item?.contact_person?.phone_number}
        </Typography>
      </div>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {moment(item?.created_at).format("LLL")}
      </Typography>
    </td>
  </tr>
);

const RentalTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: any;
}) => (
  <tr
    key={index.toString()}
    className=" hover:bg-gray-100 transition-all hover:cursor-pointer"
  >
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.policy_number}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.policy_stage}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.request?.cover_type}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        GH₵
        {((item?.request?.amount as any) ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    </td>

    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.request?.duration}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.request?.payment_channel}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.request?.registration_number_text}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.request?.request_stage}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {item?.status}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {moment(item?.request?.start_date).format("LLL")}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {moment(item?.request?.end_date).format("LLL")}
      </Typography>
    </td>
    <td className={classes}>
      <Typography
        variant="small"
        color="blue-gray"
        className="font-normal text-xs capitalize"
      >
        {moment(item?.request?.created_by).format("LLL")}
      </Typography>
    </td>
  </tr>
);

export default ReportView;
