import React, { useMemo, useState } from "react";
import { CustomHeader } from "../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useMutation, useQuery } from "@apollo/client";
import { GETALLBANKS, GETALLEXTERNALCLIENTS, GETALLTAXES } from "../../GraphQl/queries";
import moment from "moment";
import { BiCheck, BiPlus, BiTrash } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import {
  DELETECLIENT,
  INSERNEWEXTERNALCLIENT,
  UPDATEEXTERNALCLIENT,
} from "../../GraphQl/mutations";
import { CustomModal } from "../../components";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa6";

type ClientType = {
  account_details: {
    branch: string;
    bank_name: string;
    account_number: string;
    code: string;
    account_name: string;
  };
  active_status: true;
  created_at: string;
  email: string;
  id: string;
  momo_details: {
    number: string;
    network: string;
  };
  name: string;
  phone_number: string;
  tax_details: {
    id: string;
    name: string;
    rate: number;
  }[];
};

type TaxType = {
  id: string;
  name: string;
}

const ExternalUsers = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ClientType | null>();

  const defaultClientValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  const {
    register,
    handleSubmit,
    control,
    reset: resetData,
    setValue,
  } = useForm<ClientType>({
    defaultValues: {
      tax_details: [
        {
          id: "",
          name: "",
          rate: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tax_details",
  });

  const {
    fields: updateFields,
    append: appendUpdate,
    remove: removeUpdate,
  } = useFieldArray({
    control,
    name: "tax_details",
  });

  const {
    register: updateRegister,
    handleSubmit: handleSubmitUpdate,
    setValue: setUpdateValue,
    reset,
    formState: { errors },
  } = useForm<ClientType>({
    defaultValues: defaultClientValues,
  });

  const onError = (errors: any) => {
    console.log('Form Errors:', errors);
  };

  const {
    data: banks,
    loading: loadingBanks,
    refetch: refechBanks,
  } = useQuery<{
    bank: {
      code: string;
      id: string;
      name: string;
    }[];
  }>(GETALLBANKS);

  const {
    data: alltax,
    loading: loadingTaxes,
    refetch: refechTaxes,
  } = useQuery<{
    taxes: TaxType[];
  }>(GETALLTAXES);

  const {
    data: clientsData,
    loading: loadingClients,
    refetch: refechClients,
  } = useQuery<{
    external_client: ClientType[];
  }>(GETALLEXTERNALCLIENTS);

  const [DeleteClient, { loading }] = useMutation(DELETECLIENT, {
    onCompleted: (data) => {
      refechClients();
      toast.success("Client deleted successfully");
    },
    onError: (error) => toast.error(error?.message ?? "Error deleting client"),
  });

  const [UpdateClient, { loading: updating }] = useMutation(
    UPDATEEXTERNALCLIENT,
    {
      onCompleted: (data) => {
        refechClients();
        setUpdateModal(false);
        setSelectedRow(null);
        reset();
        toast.success("Client updated successfully");
      },
      onError: (error) => toast.error(error?.message ?? "Error updated client"),
    }
  );

  const [CreateClient, { loading: creating }] = useMutation(
    INSERNEWEXTERNALCLIENT,
    {
      onCompleted: (data) => {
        refechClients();
        resetData();
        toast.success("Client created successfully");
      },
      onError: (error) => toast.error(error?.message ?? "Error created client"),
    }
  );

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (item: ClientType) => {
    const isTextMatch =
      item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.momo_details?.number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.account_details?.bank_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.account_details?.branch
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.account_details?.account_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.phone_number?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const onCreateClient: SubmitHandler<ClientType> = (data) => {

    CreateClient({
      variables: {
        data: {
          ...data,
        },
      },
    });
  };

  const onUpdateClient: SubmitHandler<ClientType> = (data) => {
    console.log(data)
    UpdateClient({
      variables: {
        id: selectedRow?.id,
        data: {
          account_details: {
            branch: data?.account_details?.branch,
            bank_name: data?.account_details?.bank_name,
            account_number: data?.account_details?.account_number,
            code: data?.account_details?.code,
            account_name: data?.account_details?.account_name,
          },
          active_status: data?.active_status,
          created_at: data?.created_at,
          email: data?.email,
          id: data?.id,
          momo_details: {
            number: data?.momo_details?.number,
            network: data?.momo_details?.network,
          },
          name: data?.name,
          phone_number: data?.phone_number,
          tax_details: data?.tax_details,
        },
      },
    });
  };

  // Filtered and paginated data for the current page
  const paginatedData = clientsData?.external_client
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (clientsData?.external_client?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  React.useEffect(() => {
    if (defaultClientValues) {
      Object.entries(defaultClientValues).forEach(([key, value]) => {
        setUpdateValue(key as keyof typeof defaultClientValues as any, value);
      });
    }
  }, [defaultClientValues]);

  return (
    <>
      <CustomHeader title="External Users" />
      <CustomModal
        onClose={() => setUpdateModal(false)}
        open={showUpdateModal}
        size={"lg"}
        title={`Update client`}
        className="h-full overflow-scroll scrolls "
        children={
          <>
            <ToastContainer />
            <form
              onSubmit={handleSubmitUpdate(onUpdateClient,onError)}
              className="p-3 -mt-7 capitalize bg-white rounded-b-xl"
            >
              {" "}
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("name", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("email", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...updateRegister("phone_number", { required: true })}
                      />
                    </div>
                    <div className="mt-3">
                      <Typography className="text-md text-gray-600 mt-4">
                        Account Details
                      </Typography>
                      <hr className="my-1" />
                      <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                        <div className="mt-3 w-full">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className=" font-semibold text-[14px] w-1/3"
                            >
                              Bank Name
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                defaultChecked
                                defaultValue={
                                  selectedRow?.account_details.bank_name
                                }
                                onChange={(e) => {
                                  const selectedBankCode =
                                    e.target.options[
                                      e.target.selectedIndex
                                    ].getAttribute("data-code");
                                  setUpdateValue(
                                    "account_details.bank_name",
                                    e.target.value as never
                                  );
                                  setUpdateValue(
                                    "account_details.code",
                                    selectedBankCode!
                                  );
                                }}
                                // {...updateRegister(
                                //   `account_details.bank_name`,
                                //   {
                                //     required: true,
                                //   }
                                // )}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {banks?.bank?.map((item) => (
                                  <option
                                    key={item?.code}
                                    value={item?.name}
                                    data-code={item?.code}
                                    label={`${item?.name}`}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Branch
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account Name"
                              size="md"
                              {...updateRegister("account_details.branch", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Bank code
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Bank code"
                              size="md"
                              disabled
                              {...updateRegister("account_details.code", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Account name
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account name"
                              size="md"
                              {...updateRegister(
                                "account_details.account_name",
                                {
                                  required: true,
                                }
                              )}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Account number
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account number"
                              size="md"
                              {...updateRegister(
                                "account_details.account_number",
                                {
                                  required: true,
                                }
                              )}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Typography className="text-md text-gray-600">
                      Debit details
                    </Typography>
                    <hr className="my-1" />
                    <div className="mt-3">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Momo network
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            {...updateRegister("momo_details.network", {
                              required: true,
                            })}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option label="MTN" value={"MTN"}>
                              MTN
                            </option>
                            <option label="AirtelTigo" value={"AirtelTigo"}>
                              AirtelTigo
                            </option>
                            <option label="Vodafone" value={"Vodafone"}>
                              Vodafone
                            </option>
                          </select>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Mobile money mumber"
                            size="md"
                            {...updateRegister("momo_details.number", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Typography className="text-md text-gray-600">
                      Tax Setup
                    </Typography>
                    <hr className="my-1" />{" "}
                    {updateFields.map((_, index) => (
                      <div
                        key={index}
                        className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                      >
                        <div className="-mt-3 w-full">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className=" font-semibold text-[14px] w-1/3"
                            >
                              Name
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                defaultValue={selectedRow?.tax_details[index]?.name || ""}
                                onChange={(e) => {
                                  const selectedTaxCode = e.target.options[e.target.selectedIndex].getAttribute("data-code");
                                  setUpdateValue(`tax_details.${index}.name`, e.target.value as never);
                                  setUpdateValue(`tax_details.${index}.id`, selectedTaxCode!);
                                }}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option value="">Select tax type</option>
                                {alltax?.taxes.map((item) => (
                                  <option
                                    key={item?.id}
                                    value={item?.name}
                                    data-code={item?.id}
                                    label={item?.name}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </select>


                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Rate
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <Input
                                crossOrigin="anonymous"
                                size="lg"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...updateRegister(
                                  `tax_details.${index}.rate`,
                                  {
                                    required: true,
                                  }
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <Button
                            className={`${index === 0 && ""}`}
                            size="md"
                            color="red"
                            type="button"
                            onClick={() => removeUpdate(index)}
                          >
                            <BiTrash />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div className="flex items-end justify-end">
                      <Button
                        className="mt-4 flex items-center gap-1 capitalize font-normal"
                        type="button"
                        size="md"
                        onClick={() =>
                          appendUpdate({
                            id: "",
                            name: "",
                            rate: 0,
                          })
                        }
                      >
                        <FaPlus />
                        <span>Add tax</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  className="capitalize flex flex-row items-center bg-red-400 font-normal"
                >
                  <BiPlus size={20} />
                  {updating ? "Updating..." : "Update client"}
                </Button>
              </div>
            </form>
          </>
        }
      />

      <div className="w-full flex flex-col  lg:flex-row gap-5">
        <div className="flex-col overflow-hidden w-full  lg:w-3/4">
          <div className=" rounded-lg overflow-hidden w-full">
            <div className="bg-red-400 p-3">
              <Typography className="text-base text-white">
                New Client
              </Typography>
            </div>
            <form
              onSubmit={handleSubmit(onCreateClient)}
              className="p-3 capitalize bg-white rounded-b-xl"
            >
              {" "}
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("name", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="w-full my-2 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone_number", { required: true })}
                      />
                    </div>
                    <div className="mt-3">
                      <Typography className="text-md text-gray-600 mt-4">
                        Account Details
                      </Typography>
                      <hr className="my-1" />
                      <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                        <div className="mt-3 w-full">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className=" font-semibold text-[14px] w-1/3"
                            >
                              Bank Name
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                defaultChecked
                                defaultValue={
                                  selectedRow?.account_details.bank_name
                                }
                                onChange={(e) => {
                                  const selectedBankCode =
                                    e.target.options[
                                      e.target.selectedIndex
                                    ].getAttribute("data-code");
                                  setValue(
                                    "account_details.account_name",
                                    e.target.value as never
                                  );
                                  setValue(
                                    "account_details.code",
                                    selectedBankCode!
                                  );
                                }}
                                // {...updateRegister(
                                //   `account_details.bank_name`,
                                //   {
                                //     required: true,
                                //   }
                                // )}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option>Select Bank</option>
                                {banks?.bank?.map((item) => (
                                  <option
                                    key={item?.code}
                                    value={item?.name}
                                    data-code={item?.code}
                                    label={`${item?.name}`}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Branch
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account Name"
                              size="md"
                              {...register("account_details.branch", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Bank code
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Bank code"
                              disabled
                              size="md"
                              {...register("account_details.code", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Account name
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account name"
                              size="md"
                              {...register("account_details.account_name", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Account number
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account number"
                              size="md"
                              {...register("account_details.account_number", {
                                required: true,
                              })}
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Typography className="text-md text-gray-600">
                      Debit details
                    </Typography>
                    <hr className="my-1" />
                    <div className="mt-3">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Momo network
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            {...register("momo_details.network", {
                              required: true,
                            })}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option label="MTN" value={"MTN"}>
                              MTN
                            </option>
                            <option label="AirtelTigo" value={"AirtelTigo"}>
                              AirtelTigo
                            </option>
                            <option label="Vodafone" value={"Vodafone"}>
                              Vodafone
                            </option>
                          </select>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Mobile money mumber"
                            size="md"
                            {...register("momo_details.number", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Typography className="text-md text-gray-600">
                      Tax Setup
                    </Typography>
                    <hr className="my-1" />{" "}
                    {fields.map((_, index) => (
                      <div
                        key={index}
                        className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                      >
                        <div className="-mt-3 w-full">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className=" font-semibold text-[14px] w-1/3"
                            >
                              Name
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select


                                onChange={(e) => {
                                  const selectedTaxCode =
                                    e.target.options[
                                      e.target.selectedIndex
                                    ].getAttribute("data-code");
                                  setValue(
                                    `tax_details.${index}.name`,
                                    e.target.value as never
                                  );
                                  setValue(
                                    `tax_details.${index}.id`,
                                    selectedTaxCode!
                                  );
                                }}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option>Select tax type</option>
                                {alltax?.taxes.map((item) => (
                                  <option
                                    key={item?.id}
                                    value={item?.name}
                                    data-code={item?.id}
                                    label={`${item?.name}`}
                                  >
                                    {item?.name}
                                  </option>
                                ))
                                }

                              </select>

                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="font-semibold text-[14px] w-1/3"
                            >
                              Rate
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <Input
                                crossOrigin="anonymous"
                                size="lg"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                                {...register(`tax_details.${index}.rate`, {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <Button
                            className={`${index === 0 && ""}`}
                            size="md"
                            color="red"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <BiTrash />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div className="flex items-end justify-start">
                      <Button
                        className="mt-4 flex items-center gap-1 capitalize font-normal"
                        type="button"
                        size="md"
                        onClick={() =>
                          append({
                            id: "",
                            name: "",
                            rate: 0,
                          })
                        }
                      >
                        <FaPlus />
                        <span>Add tax</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  className="capitalize flex flex-row items-center bg-red-400 font-normal"
                >
                  <BiPlus size={20} />
                  {creating ? "Creating..." : "Create client"}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">
              List of external clients
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3 flex items-center ">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "name",
                      "email",
                      "account details",
                      "momo details",
                      "phone number",
                      "active status",
                      "createdAt",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.email}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-col gap-2">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Name:{" "}
                              <span className="font-normal">
                                {item?.account_details?.bank_name}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              account number:{" "}
                              <span className="font-normal">
                                {item?.account_details?.account_number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Network:{" "}
                              <span className="font-normal">
                                {item?.momo_details?.network}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.phone_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color={item?.active_status ? "green" : "black"}
                            className="font-normal text-xs capitalize text-center"
                          >
                            {item?.active_status ? <BiCheck /> : <IoClose />}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {moment(item?.created_at).format("LLL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row items-center justify-center gap-1">
                            <Button
                              onClick={() => {
                                setSelectedRow(item);
                                setUpdateModal(true);
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-green-500"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setSelectedRow(item);
                                DeleteClient({
                                  variables: {
                                    id: item?.id,
                                  },
                                });
                              }}
                              size="sm"
                              color="white"
                              className="text-xs text-white font-normal capitalize bg-red-500 flex items-center justify-center"
                            >
                              {item?.id === selectedRow?.id && loading ? (
                                <Spinner />
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
          </div>
          {loadingClients && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}

          {paginatedData?.length! < 1 && !loadingClients && (
            <div className="mt-10 flex text-white items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )}

          {paginatedData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={prevPage}
                  disabled={activePage === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                  Page <strong className="text-gray-700">{activePage}</strong>{" "}
                  of{" "}
                  <strong className="text-gray-700">
                    {Math.ceil(
                      (clientsData?.external_client?.filter(filterData)
                        .length || 0) / PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="filled"
                  color="white"
                  onClick={nextPage}
                  disabled={
                    activePage ===
                    Math.ceil(
                      (clientsData?.external_client?.filter(filterData)
                        .length || 0) / PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalUsers;
