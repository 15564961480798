import React from "react";
import ReactDOM from "react-dom/client";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
// import "./theme.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import { Provider } from "react-redux";
import store from "./store/store";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const HASURA_GRAPHQL_URL = process.env.REACT_APP_HASURA_GRAPHQL_URL;
const HASURA_GRAPHQL_TOKEN: any = process.env.REACT_APP_HASURA_GRAPHQL_TOKEN;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);



const client = new ApolloClient({
  uri: HASURA_GRAPHQL_URL,
  cache: new InMemoryCache(),
  headers: {
    "x-hasura-admin-secret": HASURA_GRAPHQL_TOKEN,
    "content-type": "application/json",
  },
});

root.render(
  <React.StrictMode>
    <ToastContainer />
    <SWRConfig value={{ fetcher: fetcher }}>
      <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
        </Provider>
      </ApolloProvider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
