import { useQuery } from "@apollo/client";
import { Card, Spinner, Typography } from "@material-tailwind/react";
import React from "react";
import { GETINSURANCECOMPANYSUBSCRIPTION } from "../../GraphQl/queries";

const SubscriptionCard = ({
  name,
  company_id,
}: {
  name: string;
  company_id: string;
}) => {
  const { data, loading } = useQuery<{
    total_amount: {
      aggregate: {
        sum: {
          amount: number;
        };
      };
    };
    subscription_payment: [];
  }>(GETINSURANCECOMPANYSUBSCRIPTION, {
    variables: {
      id: company_id,
    },
  });

  return (
    <Card className="bg-brand-indigo rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
      <div className="flex flex-row justify-between text">
        <div>
          <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
            {loading ? (
              <Spinner className="mb-2 text-white" />
            ) : (
              "GH₵" +
              (data?.total_amount?.aggregate?.sum?.amount ?? 0).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            )}
          </Typography>
          <Typography className="text-sm uppercase font-normal text-white">
            {name}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionCard;
