import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { GETALLMESSAGETYPES } from "../../../GraphQl/queries";
import { ADDMESSAGETYPE } from "../../../GraphQl/mutations";
import {
  UPDATEMESSAGETYPE,
  DELETEMESSAGETYPE,
} from "../../../GraphQl/mutations";

import { useMutation, useQuery } from "@apollo/client";

const Index = () => {
  interface MessageType {
    id: any;
    name: string;
    description: string;
    status: string;
  }

  //   const [paginatedData, setPaginatedData] = useState<MessageType[]>([
  //     {
  //       name: "Onboarding",
  //       description: `Dear {}, you are about to sign on the Commercial Drivers Welfare Scheme. If you agree that
  //     your motor insurance premium (when due) should be paid out of your welfare fund, please provide your
  //     one time pin./ Please share your one time pin to complete your registration.`,
  //       status: "success",
  //     },
  //     {
  //       name: "Onboarding 2",
  //       description: `Dear {}, you are about to sign on to the GLICO Commercial Drivers Welfare Scheme. If you
  // accept that your motor insurance Premium (when due) should be paid out of your Welfare fund, please
  // share your one-time Pin to complete the Onboarding process.`,
  //       status: "success",
  //     },
  //     {
  //       name: "Completed Registration",
  //       description: `You have successfully registered for the Commercial Drivers Welfare Scheme.
  // Your unique ID is{}.`,
  //       status: "success",
  //     },
  //   ]);

  const { loading, error, data } = useQuery(GETALLMESSAGETYPES);
  const [addMessageType] = useMutation(ADDMESSAGETYPE, {
    refetchQueries: [{ query: GETALLMESSAGETYPES }],
  });
  const [updateMessageType] = useMutation(UPDATEMESSAGETYPE, {
    refetchQueries: [{ query: GETALLMESSAGETYPES }],
  });

  const [deleteMessageType] = useMutation(DELETEMESSAGETYPE, {
    refetchQueries: [{ query: GETALLMESSAGETYPES }],
  });

  const paginatedData: MessageType[] = data?.message_types || [];
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState<number | null>(null);
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 4;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const onCreateOrUpdateMessage = (formData: any) => {
    console.log("formData", formData);
    if (isEditing && currentEditIndex !== null) {
      // reset(paginatedData[currentEditIndex]);
      updateMessageType({
        variables: {
          id: paginatedData[currentEditIndex].id,
          name: formData.name,
          des: formData.description,
          status: formData.status,
        },
      });

      setIsEditing(false);
      reset({ name: "", description: "", status: "" });
      setCurrentEditIndex(null);
    } else {
      addMessageType({
        variables: {
          name: formData.name,
          description: formData.description,
          status: formData.status,
        },
      });
    }

    reset();
  };

  //   if (isEditing && currentEditIndex !== null) {
  //     updateMessageType({
  //       variables: {
  //         id: paginatedData[currentEditIndex].id,
  //         name: formData.name,
  //         description: formData.description,
  //         status: formData.status,
  //       },
  //     }).then(() => {
  //       setIsEditing(false);
  //       reset({}); // Clear the form after updating
  //       setCurrentEditIndex(null);
  //     });
  //   } else {
  //     addMessageType({
  //       variables: {
  //         name: formData.name,
  //         description: formData.description,
  //         status: formData.status,
  //       },
  //     }).then(() => {
  //       reset(); // Clear the form after creating
  //     });
  //   }
  // };

  const handleEdit = (index: number) => {
    const message = paginatedData[index];
    reset(message);
    setIsEditing(true);

    setCurrentEditIndex(index);
  };

  const handleDelete = (index: number) => {
    const messageId = paginatedData[index].id;
    deleteMessageType({
      variables: { id: messageId },
    });
  };

  const paginatedItems = paginatedData.slice(
    (activePage - 1) * PAGE_SIZE,
    activePage * PAGE_SIZE
  );

  const nextPage = () => {
    if (activePage * PAGE_SIZE < paginatedData.length) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  console.log(paginatedData);
  return (
    <>
      <CustomHeader title="Messages" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="rounded-lg overflow-hidden w-full">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              {isEditing ? "Update Type of Message" : "Create Type of Message"}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onCreateOrUpdateMessage)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col">
              <div className="w-full">
                <div className="w-full my-2 gap-3 mb-6 flex flex-col justify-between lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 mr-2 font-semibold text-[14px] w-3/3"
                  >
                    Name
                  </Typography>

                  <input
                    {...register("name")}
                    className="ml-6 w-full border-[#B0BEC5] border-[1px] rounded-lg px-3 py-2"
                  ></input>
                </div>

                <div className="w-full mb-6 flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 mr-2 font-semibold text-[14px] w-3/3"
                  >
                    Description
                  </Typography>
                  <textarea
                    {...register("description")}
                    className="rounded-lg border-[#B0BEC5] border-[1px] p-3 h-28 w-full"
                  ></textarea>
                </div>
                <div className="w-full flex flex-col lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    className="mr-2 mb-2 font-semibold text-[14px] w-18"
                  >
                    Status
                  </Typography>
                  <select
                    id="Class"
                    {...register("status")}
                    className="capitalize h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ml-8 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option>Failure</option>
                    <option>Success</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="m-2 flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
                onClick={() => console.log()}
              >
                {isSubmitting
                  ? isEditing
                    ? "Updating..."
                    : "Creating..."
                  : isEditing
                  ? "Update"
                  : "Create"}
              </Button>
            </div>
          </form>
        </div>
        <div>
          <CardBody className="shadow-sm overflow-x-scroll px-0 py-0 rounded-3xl">
            <div className="bg-black pt-5">
              <Typography
                variant="h6"
                color="black"
                className="px-4 pb-4 font-bold text-brand-primary leading-none opacity-70 capitalize"
              >
                List of All Message Types
              </Typography>
            </div>
            <div className="w-full">
              <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
                <div className="flex flex-col md:flex-row lg:flex-row gap-3 w-full">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
              </div>
            </div>
            <table className="w-full bg-white min-w-max table-auto">
              <thead>
                <tr>
                  {["No.", "Name", "Description", "Status", ""].map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedItems &&
                  paginatedItems.map((item, index) => {
                    const isLast = index === paginatedItems.length - 1;
                    const className = isLast
                      ? "px-2 py-3 text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {(activePage - 1) * PAGE_SIZE + index + 1}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item.name && item?.name.length > 20
                              ? item.name.split(" ").slice(0, 1).join(" ") +
                                " " +
                                item.name.split(" ")[1].slice(0, 5) +
                                "..."
                              : item.name}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize text-wrap"
                          >
                            {item.description &&
                              item?.description
                                .split(" ")
                                .slice(0, 3)
                                .join(" ") + "..."}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.status}
                          </Typography>
                        </td>
                        <td
                          className={`flex flex-row items-center justify-center gap-2 ${className}`}
                        >
                          <button
                            onClick={() =>
                              handleEdit((activePage - 1) * PAGE_SIZE + index)
                            }
                          >
                            <IconButton className="bg-[#4CAF4F]" size="sm">
                              <IconEdit stroke={2} size={13} />
                            </IconButton>
                          </button>
                          <button
                            onClick={() =>
                              handleDelete((activePage - 1) * PAGE_SIZE + index)
                            }
                          >
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {paginatedData && paginatedData.length > 0 && (
              <div className="p-3 flex items-center justify-center bg-white">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(paginatedData.length / PAGE_SIZE)}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={activePage * PAGE_SIZE >= paginatedData.length}
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
            {paginatedData?.length < 1 && (
              <div className="bg-white p-3 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
          </CardBody>
        </div>
      </div>
    </>
  );
};

export default Index;
