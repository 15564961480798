import { Typography, Input, Button } from "@material-tailwind/react";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useMutation } from "@apollo/client";
import { UPDATE_COLOR } from "../../../../GraphQl/mutations";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../../../config/confirmationbox";

interface props {
    setCancle:()=>void
    value:{
        id:string,
        name:string
    }

}

const EditConstructionType = ({setCancle, value}:props) => {
   
  const [name, setName] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);


  const handleConfirmSubmit = () => {
    setIsUpdating(true)
   
    setShowConfirmation(false);
    setName("")
  };


  return (
    <div className="w-[30rem] rounded-lg overflow-hidden">
        <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={handleConfirmSubmit}
      />
      <div className="bg-red-400 p-3">
        <Typography className="text-base text-white">Edit Construction </Typography>
      </div>
      <div className="p-3 bg-white rounded-b-xl">
        <div className="mt-5 flex flex-col items-end justify-end">
          <div className="w-full max-w-2xl ">
            <div className="mb-6 flex flex-col gap-2">
              <div className="w-full my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  New Name
                </Typography>
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={name.length <=0 ? value.name: name}
                  onChange={(e)=>setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-end justify-end gap-5 items-center">
          <Button
              onClick={()=>{setCancle(); setName("")}}
            size="sm"
            className="flex flex-row items-center bg-[#e2e8f0] font-normal text-black h-[20]"
            
          >
            
            Cancel
          </Button>

          <Button
              onClick={(e) => setShowConfirmation(true)}
            size="sm"
            className="flex flex-row items-center bg-red-400 font-normal"
          >
            <BiPlus size={20} />
                    Save
            {/* {isUpdating ? "Saving..." : "Save"} */}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditConstructionType;
