import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  GETALLEXCLUSIONS,
  GETALLPERILS,
  GETALLPERILSNEXCLUSIONSOFCOVERTYPE,
} from "../../../GraphQl/queries";
import { ExclusionType, PerilType } from "../../../types/query";
import { toast } from "react-toastify";
import { ADDPERILOREXCLUSIONTOCOVERTYPE } from "../../../GraphQl/mutations";

type FormType = {
  cover_type_id: string;
  exclusion_id: string;
  peril_id: string;
};

type CoverType = {
  exclusion_name: string;
  exclusions_id: string;
  exclusion_description: string;
  peril_description: string;
  peril_id: string;
  peril_name: string;
};

const ManagePerilAndExlusion = () => {
  const getStorageCover = localStorage.getItem("covertype");
  const [searchPerilText, setSearchPerilText] = useState("");
  const [searchExclusionText, setSearchExclusionText] = useState("");
  const [selectedPeril, setSelectedPeril] = useState<string | null>("");
  const [selectedExclusion, setSelectedExclusion] = useState<string | null>("");

  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [selectedCovertype, setCovertype] = useState<{
    id: string;
    name: string;
    className: string;
    description: string;
  }>();
  const PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const {
    data: perilData,
    loading: loadingPeril,
    refetch: refreshPeril,
  } = useQuery<{
    perils: PerilType[];
  }>(GETALLPERILS);

  const {
    data: exclusionData,
    loading: loadingExclusion,
    refetch: refreshExclusion,
  } = useQuery<{
    exclusions: ExclusionType[];
  }>(GETALLEXCLUSIONS);

  const { data, loading, refetch } = useQuery<{
    view_coverage_details: CoverType[];
  }>(GETALLPERILSNEXCLUSIONSOFCOVERTYPE, {
    variables: {
      id: selectedCovertype?.id,
    },
  });

  const [AddPerilorExclusionToCoverType, { loading: RequestLoading }] =
    useMutation(ADDPERILOREXCLUSIONTOCOVERTYPE, {
      onCompleted: (data) => {
        toast.success("Peril Exclusion cover type Successfully added");
        setSelectedPeril(null);
        setSelectedExclusion(null);
        refetch();
        refreshExclusion();
        refreshPeril();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error Peril Exclusion cover type"),
    });

  const filterData = (filter: CoverType) => {
    const isTextMatch =
      filter?.exclusion_description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.peril_description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.peril_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.exclusion_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const filterPeril = (filter: PerilType) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchPerilText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchPerilText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchPerilText?.toLowerCase());
    return isTextMatch;
  };

  const filterExclusion = (filter: ExclusionType) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchExclusionText?.toLowerCase()) ||
      filter?.name
        ?.toLowerCase()
        ?.includes(searchExclusionText?.toLowerCase()) ||
      filter?.status
        ?.toLowerCase()
        ?.includes(searchExclusionText?.toLowerCase());
    return isTextMatch;
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handlePerilSearch = (e: any) => {
    setSearchPerilText(e.target.value);
  };

  const handleExclusionSearch = (e: any) => {
    setSearchExclusionText(e.target.value);
  };

  // Filtered and paginated data for the current page
  const paginatedData = data?.view_coverage_details
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const paginatedPeril = perilData?.perils?.filter(filterPeril);

  const paginatedExclusion = exclusionData?.exclusions?.filter(filterExclusion);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.view_coverage_details?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  useEffect(() => {
    if (getStorageCover) {
      setCovertype(JSON.parse(getStorageCover));
      refetch();
    }
  }, []);

  return (
    <>
      <CustomHeader title="COVERAGE MANAGEMENT - Perils and Exclusions" />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Class", "Type of Cover", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedCovertype?.className}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedCovertype?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedCovertype?.description}
                  </Typography>
                </td>
                {/* Add other columns or data you want from the first item */}
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Perils and Exclusions</Typography>
            </div>
            <div className="p-3 bg-white">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchPerilText}
                  onChange={handlePerilSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Perils", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedPeril?.map((item) => (
                  <tr
                    onClick={() => {
                      if (selectedPeril === item?.id) {
                        setSelectedPeril(null);
                      } else {
                        setSelectedPeril(item?.id);
                      }
                    }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${
                      selectedPeril === item?.id
                        ? "bg-red-200 hover:bg-red-200"
                        : "bg-white"
                    }`}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.description}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingPeril && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          <div className="capitalize bg-white rounded-b-xl mb-5">
            <div className="p-3 ">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchExclusionText}
                  onChange={handleExclusionSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Exclusions", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedExclusion?.map((item) => (
                  <tr
                    onClick={() => {
                      if (selectedExclusion === item?.id) {
                        setSelectedExclusion(null);
                      } else {
                        setSelectedExclusion(item?.id);
                      }
                    }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${
                      selectedExclusion === item?.id
                        ? "bg-red-200 hover:bg-red-200"
                        : "bg-white"
                    }`}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.description}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingExclusion && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          <div className="mt-5 flex items-end justify-end">
            <Button
              onClick={() => {
                AddPerilorExclusionToCoverType({
                  variables: {
                    cover_type_id: selectedCovertype?.id,
                    exclusion_id: selectedExclusion,
                    peril_id: selectedPeril,
                  },
                });
              }}
              size="sm"
              className="capitalize flex flex-row items-center bg-red-400 font-normal"
            >
              <BiPlus size={30} />
              {RequestLoading ? "Creating..." : "Create Peril and Exclusion"}
            </Button>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">
              List of {selectedCovertype?.name} Perils and Exclusions
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {["no", "Peril and Exclusions", "Description", ""].map(
                      (head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-center"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {`${item?.peril_name} - ${item?.exclusion_name}`}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.exclusion_description}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.view_coverage_details?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.view_coverage_details?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
      
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManagePerilAndExlusion;
