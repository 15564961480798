import React, { useState } from "react";
import {
  CustomDeleteModal,
  CustomModal,
  HeaderIcon,
} from "../../../components";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { type } from "os";
import { useQuery } from "@apollo/client";
import { GET_ALL_SETUPS } from "../../../GraphQl/queries";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from "moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiCheck } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { MdClose } from "react-icons/md";

type BantType = {
  name: string;
  code: string;
  is_active: boolean;
  id?: string;
  created_at?: string;
};

const BanksPage = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const handleOpen = () => setOpen(!open);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<BantType>();

  let PAGE_SIZE = 50;

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const { data: banks, loading } = useQuery(GET_ALL_SETUPS);

  const filterData = (admin: BantType) => {
    const isTextMatch =
      admin?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.code?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = banks?.banks
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (banks?.banks?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<BantType> = (data) => {};

  return (
    <>
      {" "}
      <CustomModal
        title="Add Bank"
        onClose={() => setOpen(false)}
        open={open}
        children={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6 flex flex-col gap-6">
                <div className="w-full">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-normal capitalize text-base"
                  >
                    name
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    error={!!errors.name}
                    size="lg"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("name", { required: true })}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-normal capitalize text-base"
                  >
                    code
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    error={!!errors.name}
                    size="lg"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("code", { required: true })}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-normal text-base"
                  >
                    Is active
                  </Typography>

                  <select
                    id="Status"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register("is_active", { required: true })}
                  >
                    <option label="False" value={"false"}>
                      False
                    </option>
                    <option label="True" value={"true"}>
                      True
                    </option>
                  </select>
                </div>
              </div>

              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  type="submit"
                  className="flex items-center justify-center"
                  variant="gradient"
                  color="green"
                >
                  {isSubmitting ? (
                    <Spinner className="h-4 w-4 text-white" />
                  ) : (
                    <span>Submit</span>
                  )}
                </Button>
              </DialogFooter>
            </form>
          </>
        }
      />
      <CustomHeader title="Banks" />
      <div className="w-full">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          <div className="mx-3" />

          <Button
            onClick={handleOpen}
            className=" bg-red-400 w-full md:w-1/4 lg:w-1/4"
          >
            Add bank
          </Button>
        </div>
      </div>
      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              color="black"
              className="px-4 pb-4 font-bold text-brand-primary leading-none opacity-70 capitalize"
            >
              Total count{" "}
              {banks?.banks && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {banks?.banks?.length}
                </span>
              )}
            </Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {["name", "code", "status", "createdAt", ""].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item: BantType, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.code}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize flex items-center justify-center text-center"
                      >
                        {item?.is_active ? (
                          <BiCheck color="green" size={19} />
                        ) : (
                          <MdClose color="red" size={15} />
                        )}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {moment(item?.created_at).format("LLL")}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <div className="h-7"></div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {loading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {paginatedData?.length! < 1 && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography variant="h6">No data found</Typography>
          </div>
        )}

        {/* Paginator */}
        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="test-white">{activePage}</strong> of{" "}
                <strong className="test-white">
                  {Math.ceil(
                    (banks?.banks?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (banks?.banks?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BanksPage;
