import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AuthState {
  userEmail: string
  password: string
}

const initialState: AuthState = {
  userEmail: "glicoadmin@gmail.com",
  password: "password",
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      state.userEmail = action.payload.userEmail
      state.password = action.payload.password
  
    },
    // clearAuthState: (state) => {
    //   state.usertype = null
    //   state.userId = null
    //   state.roleId = null
    //   state.companyId = null
    //   state.token = null
    //   state.expiresAt = null
    //   state.showI2I = false
    //   state.user = null
    //   saveStateToLocalStorage(state)
    // },
  },
})

export const { setAuthState } = authSlice.actions
export default authSlice.reducer
