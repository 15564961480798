import React, {
  useState,
  KeyboardEventHandler,
  useEffect,
  useMemo,
} from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useHistory } from "react-router-dom";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { FaPlus, FaUsers } from "react-icons/fa6";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BrokerType, createCompanyType } from "../../../types/company";
import {
  BASEURL,
  cardNumberPattern,
  id_types,
  intermediary_types,
} from "../../../util";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { useQuery } from "@apollo/client";
import {
  GET_CLIENT_TYPES,
  GET_ALL_CLIENT,
  GETALLCOMMMISSIONS,
  GETALLBANKS,
  GETSUBSCRIBECLASS,
} from "../../../GraphQl/queries";
import { CustomModal } from "../../../components";
import { MdPolicy } from "react-icons/md";

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface Client {
  address?: string;
  bank_details?: {
    branch: string;
    bank_name: string;
    acct_number: string;
    code: string;
    account_name: string;
  };
  email: string;
  id: string;
  intermediary_type: string;
  momo_details?: {
    number: string;
    network: string;
  };
  name: string;
  clientTypeByClientType?: {
    id: string;
    name: string;
  };
  active_status: boolean;
  branch_code: string;
  contact_person?: {
    name: string;
    email: string;
    phone: string;
    address: string;
    card_number: string;
    national_id_type: string;
  };
  insurer_company?: {
    id: string;
    name: string;
  };
  nic_registration_number: string;
  phone_number: string;
  tin_number: string;
}

interface InsurerCompany {
  bank_details?: {
    name: string;
  };
  client_type: string;
  email: string;
  id: string;
  momo_details?: {
    network: string;
  };
  phone: string;
  name: string;
  tin_number: string;
  clientTypeByClientType?: {
    id: string;
    name: string;
  };
}

interface Data {
  intermediary: Client[];
  insurer_companies: InsurerCompany[];
}

interface AllClientsQueryResult {
  data?: {
    allclients: Data[];
  };
}
const Company = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [branches, setBranch] = useState<{ code: string; name: string }[]>();
  const [inputValue, setInputValue] = React.useState("");
  const [value, _setValue] = React.useState<readonly Option[]>([]);
  const [selectedOption, setSelectedOption] = useState("Insurance Company");
  const [intermediaries, setIntermediary] = React.useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = React.useState([]);
  const [selectedUsertype, setselectedUserType] =
    React.useState("insurance_company");
  const PAGE_SIZE = 10;
  const [selectedID, setSelectedID] = useState("");
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [type, setType] = useState<string>("");
  const [disbursement_bank_code, setDisbursement] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<createCompanyType>();
  const [file, setFile] = useState(null);

  const defaultCompanyValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  const defaultBrokerValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  const {
    data: banks,
    loading: loadingBanks,
    refetch: refechBanks,
  } = useQuery<{
    bank: {
      code: string;
      id: string;
      name: string;
    }[];
  }>(GETALLBANKS);

  const {
    data: subscribed_business_list,
    loading: loadingsubscribed_business_list,
    refetch: refechSubscribed_business_list,
  } = useQuery<{
    subscribed_business_list: {
      id: string;
      name: string;
    }[];
  }>(GETSUBSCRIBECLASS);

  console.log(subscribed_business_list)

  const {
    data: client_types,
    loading: loadingClient,
    refetch,
  } = useQuery<{
    client_types: {
      id: string;
      name: string;
      description: string;
      created_at: string;
      created_by: string;
    }[];
  }>(GET_CLIENT_TYPES);

  const {
    data: clientsData,
    loading: loadingAllClients,
    refetch: refetchClients,
  } = useQuery(GET_ALL_CLIENT);

  const { data: commissions, loading: loadingCommissions } = useQuery<{
    commisions: {
      description: string;
      id: string;
      name: string;
      status: string;
      created_at: string;
    }[];
  }>(GETALLCOMMMISSIONS);

  useEffect(() => {
    if (clientsData) {
      const clients = clientsData.intemediary;
      const insurerCompanies = clientsData.insurer_companies;

      setIntermediary(clients);
      setInsuranceCompanies(insurerCompanies);
    }
  }, [clientsData]);

  const combinedArray = [
    ...(clientsData?.intemediary ?? []),
    ...(clientsData?.insurer_companies ?? []),
  ];

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        _setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleChange = (e: any) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const getAllBranches = async () => {
    try {
      const response = await fetch(`${BASEURL}/get-all-mid-branches`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const req = await response.json();
      if (response.ok) {
        setBranch(req.data);
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error getting branches");
    }
  };

  useEffect(() => {
    getAllBranches();
  }, []);

  const handleSelectChange = (e: any) => {
    const selected_id = e.target.options[e.target.selectedIndex];
    setSelectedID(selected_id.dataset.code);
    setSelectedOption(e.target.value);
    if (e.target.value === "Insurance Broker") {
      setselectedUserType("broker");
    } else {
      setselectedUserType("insurance_company");
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<createCompanyType>({
    defaultValues: {
      subscribed_business_class: [
        {
          id: "",
          name: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commisions_setup",
  });

  const {
    fields: subscribed_business_class_fields,
    append: append_subscribed_business_class,
    remove: remove_subscribed_business_class,
  } = useFieldArray({
    control,
    name: "subscribed_business_class",
  });

  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating },
    setValue: setUpdateValue,
    control: updateControl,
  } = useForm<createCompanyType>({
    defaultValues: {
      ...defaultCompanyValues,
      subscribed_business_class: defaultCompanyValues.subscribed_business_class,
    },
  });



  const {
    fields: update_subscribed_business_class_fields,
    append: append_subscribed_business_class_update,
    remove: remove_subscribed_business_class_update,
  } = useFieldArray({
    control: updateControl,
    name: "subscribed_business_class",
  });

  const {
    register: updateBrokerRegister,
    handleSubmit: handleUpdateBrokerSubmit,
    reset: resetBrokerUpdate,
    formState: { isSubmitting: updatingBroker },
    setValue: setUpdateBrokerValue,
  } = useForm<BrokerType>({
    defaultValues: defaultBrokerValues,
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (item: Client | InsurerCompany | any) => {
    const isTextMatch =
      item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.intermediary_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.clientTypeByClientType?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.tin_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.clientTypeByClientType?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.email?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = combinedArray
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (combinedArray?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };


  const handleManageSubscriptions = () => {

    history.push("/dashboard/setup/subscriptions");
    
  };

  const handleManagePolicy = (company_id:string, company_name:string) => {

    history.push("/dashboard/setup/manage_policy");
    localStorage.setItem("company_id", company_id)
    localStorage.setItem("company_name", company_name)

  };


  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<createCompanyType> = async (data) => {
    try {
      const response = await fetch(
        `${BASEURL}/setup/add-client-insurer-company`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            ...data,
            client_type: selectedID,
          }),
        }
      );
      const req = await response.json();
      if (response.ok && req?.status === 200) {
        toast.success(req?.message ?? "Company created successfully");
        refetchClients();
        reset();
      } else {
        toast.error(req?.data ?? "Something went wrong?");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating Company");
    }
  };

  const onSubmitIntermediary: SubmitHandler<createCompanyType> = async (
    data
  ) => {
    try {
      const response = await fetch(`${BASEURL}/setup/add-intemediary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          ...data,
          initials: data?.initials,
          client_type: "broker",
          nic_registration_number: data?.nic_registration_number,
          intermediary_type_code: "Broker",
          contact_person: {
            name: data?.company_person?.name,
            email: data?.company_person?.email,
            phone: data?.company_person?.phone,
            address: data?.company_person?.address,
            national_id_type: data?.company_person?.national_id_type,
            card_number: data?.company_person?.card_number,
          },
        }),
      });

      const req = await response.json();
      if (response.ok && !req?.error) {
        toast.success(req?.message ?? "Company created successfully");
        refetchClients();
        reset();
      } else {
        toast.error(req?.data ?? "Something went wrong?");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating Company");
    }
  };

  const onCompanyUpdate: SubmitHandler<createCompanyType> = async (data) => {
    try {
      let payload = {
        client_type: data?.client_type,
        name: data?.name,
        company_id: selectedRow!?.id,
        address: data?.address,
        email: data?.email,
        phone: data.phone,
        colors: data?.colors,
        tin_number: data?.tin_number,
        logo_url: data?.logo_url,
        momo_details: {
          network: data?.momo_details?.network,
          number: data?.momo_details?.number,
        },
        subscribed_business_class: data?.subscribed_business_class,
        bank_details: {
          bank_name: data?.bank_details?.bank_name,
          acct_number: data?.bank_details?.acct_number,
          branch: data?.bank_details?.branch,
          account_name: data?.bank_details?.account_name,
          code: data?.bank_details?.code,
          app_id: data?.bank_details?.app_id,
          app_key: data?.bank_details?.app_key,
        },
        disbursement_bank_details: {
          bank_name: data?.disbursement_bank_details?.bank_name,
          acct_number: data?.disbursement_bank_details?.acct_number,
          branch: data?.disbursement_bank_details?.branch,
          account_name: data?.disbursement_bank_details?.account_name,
          code: data?.disbursement_bank_details?.code,
          app_id: data?.disbursement_bank_details?.app_id,
          app_key: data?.disbursement_bank_details?.app_key,
        },
        mid_credentials_metadata: {
          base_url: data?.mid_credentials_metadata?.base_url,
          user_name: data?.mid_credentials_metadata?.user_name,
          password: data?.mid_credentials_metadata?.password,
          token: data?.mid_credentials_metadata?.token,
        },
      };
      // let payload = {
      //   ...data,
      //   name: data?.name,
      //   company_id: selectedRow.id,
      //   address: data?.address,
      //   email: data?.email,
      //   phone: data.phone,
      //   colors: data?.colors,
      //   tin_number: data?.tin_number,
      //   momo_details: {
      //     network: data?.momo_details?.network,
      //     number: data?.momo_details?.number,
      //   },
      //   bank_details: {
      //     bank_name: data?.bank_details?.bank_name,
      //     acct_number: data?.bank_details?.acct_number,
      //     branch: data?.bank_details?.branch,
      //   },
      // };
      const response = await fetch(`${BASEURL}/setup/edit-company-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });
      const req = await response.json();
      if (response.ok && !req?.error) {
        toast.success(req?.message ?? "Company updated successfully");
        refetchClients();
        resetUpdate();
        setUpdateModal(false);
      } else {
        toast.error(req?.data ?? "Something went wrong?");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error updating Company");
    }
  };

  const onBrokerUpdate: SubmitHandler<BrokerType> = async (data) => {
    try {
      let payload = {
        ...data,
        colors: data?.colors,
        name: data?.name,
        intermediary_id: selectedRow?.id,
        intermediary_type: data?.intermediary_type,
        intermediary_type_code: "broker",
        address: data?.address,
        email: data?.email,
        phone: data?.phone_number,
        preferred_payment_channel: data?.preferred_payment_channel,
        tin_number: data?.tin_number,
        initials: data?.initials,
        momo_details: {
          network: data?.momo_details?.network,
          number: data?.momo_details?.number,
        },
        bank_details: {
          bank_name: data?.bank_details?.bank_name,
          acct_number: data?.bank_details?.acct_number,
          branch: data?.bank_details?.branch,
          account_name: data?.bank_details?.account_name,
          code: data?.bank_details?.code,
          app_id: data?.bank_details?.app_id,
          app_key: data?.bank_details?.app_key,
        },
        mid_branch_details: JSON.parse(data?.mid_branch_details as any),
        nic_registration_number: data?.nic_registration_number,
        role_id: selectedRow?.id,
      };

      const response = await fetch(
        `${BASEURL}/setup/edit-intermediary-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const req = await response.json();
      if (response.ok && !req?.error) {
        toast.success(req?.message ?? "Intermediary updated successfully");
        refetchClients();
        resetUpdate();
        setUpdateModal(false);
      } else {
        toast.error(req?.data ?? "Something went wrong?");
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error updating Intermediary");
    }
  };

  React.useEffect(() => {
    if (defaultCompanyValues) {
      Object.entries(defaultCompanyValues).forEach(([key, value]) => {
        setUpdateValue(key as keyof typeof defaultCompanyValues as any, value);
      });
    }
  }, [defaultCompanyValues]);

  React.useEffect(() => {
    if (defaultBrokerValues) {
      Object.entries(defaultBrokerValues).forEach(([key, value]) => {
        setUpdateBrokerValue(
          key as keyof typeof defaultBrokerValues as any,
          value
        );
      });
    }
  }, [defaultBrokerValues]);

  useEffect(() => {
    const selectedValues = value.map((option) => option.value);
    let colors = selectedValues;
    setValue("colors", selectedValues);
    updateBrokerRegister("colors", {
      value: selectedValues,
    });
    setUpdateValue("colors", selectedValues);
  }, [value]);

  return (
    <>
      <CustomHeader title="Register Client" />

      <CustomModal
        onClose={() => setUpdateModal(false)}
        open={showUpdateModal}
        size={"xl"}
        title={`Update ${type === "company" ? "Company" : "Broker"}`}
        className="h-full overflow-scroll scrolls"
        children={
          <>
            <ToastContainer />
            {type === "company" ? (
              <>
                <form
                  onSubmit={handleUpdateSubmit(onCompanyUpdate)}
                  className="capitalize p-3 bg-white rounded-b-xl "
                >
                  <div className="flex flex-col items-end justify-end">
                    <div className="w-full ">
                      <div className="mb-6 flex flex-col gap-2">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Company name
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("name", { required: true })}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Company Initials (Not less than 3 characters)
                          </Typography>
                          <Input
                            maxLength={3}
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("company_initials", {
                              required: true,
                            })}
                          />
                        </div>

                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Address
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("address", { required: true })}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Phone
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("phone", { required: true })}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Email
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("email", { required: true })}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Company colours
                          </Typography>
                          <CreatableSelect
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(newValue) => _setValue(newValue)}
                            onInputChange={(newValue) =>
                              setInputValue(newValue)
                            }
                            onKeyDown={handleKeyDown}
                            placeholder="Type and press enter..."
                            value={value}
                            className="w-full"
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            TIN number
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("tin_number", {
                              required: true,
                            })}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Company Logo Url
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            size="md"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            {...updateRegister("logo_url", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Typography className="text-md text-gray-600">
                    Debit details
                  </Typography>
                  <hr className="my-1" />
                  <div className="mt-3">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Momo network
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          defaultValue={selectedRow?.momo_details?.network}
                          {...updateRegister("momo_details.network", {
                            required: true,
                          })}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option label="MTN" value={"MTN"}>
                            MTN
                          </option>
                          <option label="AirtelTigo" value={"AirtelTigo"}>
                            AirtelTigo
                          </option>
                          <option label="Vodafone" value={"Vodafone"}>
                            Vodafone
                          </option>
                        </select>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Mobile money mumber"
                          size="md"
                          {...updateRegister("momo_details.number", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                    </div>
                    <Typography className="text-md text-gray-600 mt-4">
                      Collection Bank Details
                    </Typography>
                    <hr className="my-1" />
                    <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                      <div className="mt-3 w-full">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className=" font-semibold text-[14px] w-1/3"
                          >
                            Bank Name
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              defaultValue={
                                selectedRow?.bank_details?.bank_name
                              }
                              onChange={(e) => {
                                const selectedBankCode =
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("data-code");
                                setUpdateValue(
                                  "bank_details.code",
                                  selectedBankCode ? selectedBankCode : ""
                                );
                                setUpdateValue(
                                  "bank_details.name",
                                  e.target.value as never
                                );
                              }}
                              // {...updateRegister(`bank_details.bank_name`, {
                              //   required: true,
                              // })}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              {banks?.bank?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  data-code={item?.code}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Branch
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateRegister("bank_details.branch", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Code
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            disabled
                            {...updateRegister("bank_details.code", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Name
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateRegister("bank_details.account_name", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Number
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account number"
                            size="md"
                            {...updateRegister("bank_details.acct_number", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            APP ID
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="APP ID"
                            size="md"
                            {...updateRegister("bank_details.app_id", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            API KEY
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="API KEY"
                            size="md"
                            {...updateRegister("bank_details.app_key", {
                              required: true,
                            })}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Typography className="text-md text-gray-600 mt-4">
                      Disbursement bank details
                    </Typography>
                    <hr className="my-1" />
                    <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                      <div className="mt-3 w-full">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className=" font-semibold text-[14px] w-1/3"
                          >
                            Bank Name
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              defaultValue={
                                selectedRow?.disbursement_bank_details
                                  ?.bank_name
                              }
                              onChange={(e) => {
                                const selectedBankCode =
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("data-code");
                                setUpdateValue(
                                  "disbursement_bank_details.bank_name",
                                  e.target.value as never
                                );
                                setUpdateValue(
                                  "disbursement_bank_details.code",
                                  selectedBankCode!
                                );
                              }}
                              // {...updateRegister(
                              //   `disbursement_bank_details.bank_name`,
                              //   {
                              //     required: true,
                              //   }
                              // )}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              {banks?.bank?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  data-code={item?.code}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Branch
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.branch",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Code
                          </Typography>
                          <Input
                            disabled
                            crossOrigin="anonymous"
                            placeholder="Code"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.code",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Name
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.account_name",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Number
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account number"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.acct_number",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            APP ID
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="APP ID"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.app_id",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            API KEY
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="API KEY"
                            size="md"
                            {...updateRegister(
                              "disbursement_bank_details.app_key",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Typography className="text-md text-gray-600 mt-4">
                    MID Details setup
                  </Typography>
                  <hr className="my-1" />
                  <div className="mt-3">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Credentials
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Base url"
                          size="md"
                          {...updateRegister(
                            "mid_credentials_metadata.base_url",
                            {
                              required: true,
                            }
                          )}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />

                        <Input
                          crossOrigin="anonymous"
                          placeholder="Username"
                          size="md"
                          {...updateRegister(
                            "mid_credentials_metadata.user_name",
                            {
                              required: true,
                            }
                          )}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />

                        <Input
                          crossOrigin="anonymous"
                          placeholder="Password"
                          size="md"
                          {...updateRegister(
                            "mid_credentials_metadata.password",
                            {
                              required: true,
                            }
                          )}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Token"
                          size="md"
                          {...updateRegister("mid_credentials_metadata.token", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Typography className="text-md mt-10 text-gray-600">
                    subscribed business class
                  </Typography>
                  <hr className="my-1" />{" "}

                  {update_subscribed_business_class_fields.map((subscribed_business_class, index) => (
                    <div key={index} className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                      <div className="-mt-3 w-full">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography variant="h6" color="blue-gray" className="font-semibold text-[14px] w-1/3">
                            Name
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              onChange={(e) => {
                                const selectedId = e.target.options[e.target.selectedIndex].getAttribute("data-code");
                                const value = {
                                  id: selectedId,
                                  name: e.target.value,
                                };

                                updateRegister(`subscribed_business_class.${index}`, value as never);
                              }}
                              value={subscribed_business_class.name || "Select Class"} // Set the current value here
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option disabled>Select Class</option>
                              {loadingsubscribed_business_list
                                ? <option>Loading...</option>
                                : subscribed_business_list?.subscribed_business_list?.map((item) => (
                                  <option
                                    key={item?.id}
                                    value={item?.name}
                                    data-code={item?.id}
                                  >
                                    {item?.name}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Button
                          className={`${index === 0 && ""}`}
                          size="md"
                          color="red"
                          type="button"
                          onClick={() => remove_subscribed_business_class_update(index)}
                        >
                          <BiTrash />
                        </Button>
                      </div>
                    </div>
                  ))}

                  <Button
                    className="mt-4 flex items-center gap-1 capitalize font-normal"
                    type="button"
                    size="sm"
                    onClick={() =>
                      append_subscribed_business_class_update({
                        id: "",
                        name: "",
                      })
                    }
                  >
                    <FaPlus />
                    <span>Add subscribed business class</span>
                  </Button>
                  <div className="flex items-end justify-end">
                    <Button
                      size="sm"
                      type="submit"
                      className="flex flex-row items-center bg-red-400 font-normal"
                    >
                      {updating ? (
                        <Spinner />
                      ) : (
                        <span className="flex flex-row items-center">
                          <BiPlus size={20} />
                          Update
                        </span>
                      )}
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <form
                onSubmit={handleUpdateBrokerSubmit(onBrokerUpdate)}
                className="p-3 bg-white rounded-b-xl"
              >
                <div className="flex flex-col items-end justify-end">
                  <div className="w-full">
                    <div className="mb-6 flex flex-col gap-2">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          name
                        </Typography>
                        <Input
                          {...updateBrokerRegister("name", { required: true })}
                          crossOrigin="anonymous"
                          size="md"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          Broker Initials (Not less than 3 characters)
                        </Typography>
                        <Input
                          {...updateBrokerRegister("initials", {
                            required: true,
                          })}
                          crossOrigin="anonymous"
                          size="md"
                          maxLength={3}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>

                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          email
                        </Typography>
                        <Input
                          {...updateBrokerRegister("email", { required: true })}
                          crossOrigin="anonymous"
                          size="md"
                          type="email"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          address
                        </Typography>
                        <Input
                          {...updateBrokerRegister("address", {
                            required: true,
                          })}
                          crossOrigin="anonymous"
                          size="md"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          Phone
                        </Typography>
                        <Input
                          {...updateBrokerRegister("phone_number", {
                            required: true,
                          })}
                          crossOrigin="anonymous"
                          size="md"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Company colours
                        </Typography>
                        <CreatableSelect
                          components={components}
                          inputValue={inputValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => _setValue(newValue)}
                          onInputChange={(newValue) => setInputValue(newValue)}
                          onKeyDown={handleKeyDown}
                          placeholder="Type and press enter..."
                          value={value}
                          className="w-full"
                        />
                      </div>
                      {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Intermediary type code
                        </Typography>
                        <select
                          defaultChecked
                          defaultValue={defaultBrokerValues?.intermediary_type}
                          {...updateBrokerRegister("intermediary_type", {
                            required: true,
                          })}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option>Select type</option>
                          <option
                            defaultChecked
                            selected
                            label="Broker"
                            value="broker"
                          >
                            Broker
                          </option>
                        </select>
                      </div> */}
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          ⁠TIN number
                        </Typography>
                        <Input
                          {...updateBrokerRegister("tin_number", {
                            required: true,
                          })}
                          crossOrigin="anonymous"
                          size="md"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          nic registration number
                        </Typography>
                        <Input
                          {...updateBrokerRegister("nic_registration_number", {
                            required: true,
                          })}
                          crossOrigin="anonymous"
                          size="md"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>

                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                        >
                          Branch code
                        </Typography>
                        <select
                          {...updateBrokerRegister("mid_branch_details", {
                            required: true,
                          })}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {branches?.map((item) => (
                            <option
                              value={JSON.stringify(item)}
                              label={item?.name}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <Typography className="text-md text-gray-600 mt-4">
                  Debit details
                </Typography>
                <hr className="my-1" />
                <div className="mt-3">
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Momo network
                    </Typography>
                    <div className="w-full flex flex-col gap-2 my-1">
                      <select
                        {...updateBrokerRegister("momo_details.network", {
                          required: true,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option label="MTN" value={"MTN"}>
                          MTN
                        </option>
                        <option label="AirtelTigo" value={"AirtelTigo"}>
                          AirtelTigo
                        </option>
                        <option label="Vodafone" value={"Vodafone"}>
                          Vodafone
                        </option>
                      </select>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Mobile money mumber"
                        size="md"
                        {...updateBrokerRegister("momo_details.number", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Bank transfer
                    </Typography>
                    <div className="w-full flex flex-col my-1 gap-2">
                      <select
                        {...updateBrokerRegister("bank_details.bank_name", {
                          required: true,
                        })}
                        onChange={(e) => {
                          const selectedBankCode =
                            e.target.options[
                              e.target.selectedIndex
                            ].getAttribute("data-code");
                          setUpdateBrokerValue(
                            "bank_details.code",
                            selectedBankCode ? selectedBankCode : ""
                          );
                        }}
                        placeholder="Bank name"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loadingBanks ? "Loading..." : "Select Bank"}
                        </option>
                        {banks?.bank?.map((item) => (
                          <option
                            key={item?.code}
                            value={item?.name}
                            data-code={item?.code}
                            label={`${item?.name}`}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>

                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account name"
                        size="md"
                        {...updateBrokerRegister("bank_details.account_name", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account number"
                        size="md"
                        {...updateBrokerRegister("bank_details.acct_number", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Branch"
                        size="md"
                        {...updateBrokerRegister("bank_details.branch", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div> */}

                  <Typography className="text-md text-gray-600 mt-4">
                    Collection Bank Details
                  </Typography>
                  <hr className="my-1" />
                  <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                    <div className="mt-3 w-full">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className=" font-semibold text-[14px] w-1/3"
                        >
                          Bank Name
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            defaultValue={selectedRow?.bank_details?.bank_name}
                            onChange={(e) => {
                              const selectedBankCode =
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("data-code");
                              updateBrokerRegister(
                                "bank_details.code",
                                selectedBankCode as any
                              );
                              updateBrokerRegister(
                                "bank_details.account_name",
                                e.target.value as never
                              );
                            }}
                            // {...updateRegister(`bank_details.bank_name`, {
                            //   required: true,
                            // })}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            {banks?.bank?.map((item) => (
                              <option
                                key={item?.code}
                                value={item?.name}
                                data-code={item?.code}
                                label={`${item?.name}`}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          Branch
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Account Name"
                          size="md"
                          {...updateBrokerRegister("bank_details.branch", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          Code
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Account Name"
                          size="md"
                          disabled
                          {...updateBrokerRegister("bank_details.code", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          Account Name
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Account Name"
                          size="md"
                          {...updateBrokerRegister(
                            "bank_details.account_name",
                            {
                              required: true,
                            }
                          )}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          Account Number
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="Account number"
                          size="md"
                          {...updateBrokerRegister("bank_details.acct_number", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          APP ID
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="APP ID"
                          size="md"
                          {...updateBrokerRegister("bank_details.app_id", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          API KEY
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          placeholder="API KEY"
                          size="md"
                          {...updateBrokerRegister("bank_details.app_key", {
                            required: true,
                          })}
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <Typography className="text-md text-gray-600 mt-4">
                      Disbursement bank details
                    </Typography>
                    <hr className="my-1" />
                    <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                      <div className="mt-3 w-full">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className=" font-semibold text-[14px] w-1/3"
                          >
                            Bank Name
                          </Typography>
                          <div className="w-full flex flex-col gap-2 my-1">
                            <select
                              defaultValue={
                                selectedRow?.disbursement_bank_details
                                  ?.bank_name
                              }
                              onChange={(e) => {
                                const selectedBankCode =
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("data-code");
                                updateBrokerRegister(
                                  "disbursement_bank_details.bank_name",
                                  e.target.value as never
                                );
                                updateBrokerRegister(
                                  "disbursement_bank_details.code",
                                  selectedBankCode as any
                                );
                              }}
                              // {...updateRegister(
                              //   `disbursement_bank_details.bank_name`,
                              //   {
                              //     required: true,
                              //   }
                              // )}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              {banks?.bank?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  data-code={item?.code}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Branch
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.branch",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Code
                          </Typography>
                          <Input
                            disabled
                            crossOrigin="anonymous"
                            placeholder="Code"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.code",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Name
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account Name"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.account_name",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            Account Number
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="Account number"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.acct_number",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            APP ID
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="APP ID"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.app_id",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-semibold text-[14px] w-1/3"
                          >
                            API KEY
                          </Typography>
                          <Input
                            crossOrigin="anonymous"
                            placeholder="API KEY"
                            size="md"
                            {...updateBrokerRegister(
                              "disbursement_bank_details.app_key",
                              {
                                required: true,
                              }
                            )}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Typography className="text-md text-gray-600">
                    Preferred payment Method
                  </Typography>
                  <hr className="my-1" />
                  <div className="mt-3">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Payment Method
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          {...updateBrokerRegister(
                            "preferred_payment_channel",
                            { required: true }
                          )}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option label="Momo" value={"momo"}>
                            Momo
                          </option>
                          <option label="Bank" value={"bank"}>
                            Bank
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-end justify-end">
                  <Button
                    size="sm"
                    type="submit"
                    className="flex flex-row items-center bg-red-400 font-normal"
                  >
                    {updatingBroker ? (
                      <Spinner />
                    ) : (
                      <span className="flex flex-row items-center">
                        <BiPlus size={20} />
                        Update
                      </span>
                    )}
                  </Button>
                </div>
              </form>
            )}
          </>
        }
      />

      <div className="w-full flex flex-col  lg:flex-row gap-5">
        <div className="flex-col overflow-hidden w-full  lg:w-3/4">
          <div className=" rounded-lg overflow-hidden w-full">
            <div className="bg-red-400 p-3">
              <Typography className="text-base text-white">
                New Client
              </Typography>
            </div>

            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1 bg-gray-50 ">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 capitalize pt-3 pl-3 font-semibold text-[14px] w-1/3"
              >
                client type
              </Typography>
              <select
                {...register("client_type", { required: true })}
                onChange={handleSelectChange}
                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option>Select client type</option>
                {client_types?.client_types
                  ?.slice(0, client_types?.client_types?.length - 2)
                  ?.map((client_type) => (
                    <option
                      data-code={client_type?.id}
                      label={client_type?.name}
                      value={client_type?.name}
                    >
                      {client_type?.id}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {selectedOption === "Insurance Company" ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="capitalize p-3 bg-white rounded-b-xl "
            >
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full ">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Company name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("name", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Company Initials (Not less than 3 characters)
                      </Typography>
                      <Input
                        maxLength={3}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("company_initials", { required: true })}
                      />
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Address
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("address", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Company colours(Press enter after typing each one)
                      </Typography>
                      <CreatableSelect
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(newValue) => _setValue(newValue)}
                        onInputChange={(newValue) => setInputValue(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type and press enter..."
                        value={value}
                        className="w-full"
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        TIN number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("tin_number", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Company Logo Url
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("logo_url", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Debit details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Momo network
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("momo_details.network", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Mobile money mumber"
                      size="md"
                      {...register("momo_details.number", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
                <Typography className="text-md text-gray-600 mt-4">
                  Collection Bank Details
                </Typography>
                <hr className="my-1" />
                <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                  <div className="mt-3 w-full">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className=" font-semibold text-[14px] w-1/3"
                      >
                        Bank Name
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          onChange={(e) => {
                            const selectedBankCode =
                              e.target.options[
                                e.target.selectedIndex
                              ].getAttribute("data-code");
                            setValue(
                              "bank_details.bank_name",
                              e.target.value as never
                            );
                            setValue(
                              "bank_details.code",
                              selectedBankCode ? selectedBankCode : ""
                            );
                          }}
                          // {...register(`bank_details.bank_name`, {
                          //   required: true,
                          // })}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected defaultChecked>
                            {loadingBanks ? "Loading..." : "Select Bank"}
                          </option>
                          {banks?.bank?.map((item) => (
                            <option
                              key={item?.code}
                              value={item?.name}
                              data-code={item?.code}
                              label={`${item?.name}`}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Branch
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account Branch"
                        size="md"
                        {...register("bank_details.branch", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Code
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Bank code"
                        size="md"
                        disabled
                        {...register("bank_details.code", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Account Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account Name"
                        size="md"
                        {...register("bank_details.account_name", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Account Number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account number"
                        size="md"
                        {...register("bank_details.acct_number", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        APP ID
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="APP ID"
                        size="md"
                        {...register("bank_details.app_id", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        API KEY
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="API KEY"
                        size="md"
                        {...register("bank_details.app_key", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Typography className="text-md text-gray-600 mt-4">
                  Disbursement bank details
                </Typography>
                <hr className="my-1" />
                <div className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200">
                  <div className="mt-3 w-full">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className=" font-semibold text-[14px] w-1/3"
                      >
                        Bank Name
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          onChange={(e) => {
                            const selectedBankCode =
                              e.target.options[
                                e.target.selectedIndex
                              ].getAttribute("data-code");
                            setValue(
                              "disbursement_bank_details.bank_name",
                              e.target.value as never
                            );
                            setValue(
                              "disbursement_bank_details.code",
                              selectedBankCode ? selectedBankCode : ""
                            );
                          }}
                          // {...register(`disbursement_bank_details.bank_name`, {
                          //   required: true,
                          // })}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected defaultChecked>
                            {loadingBanks ? "Loading..." : "Select Bank"}
                          </option>
                          {banks?.bank?.map((item) => (
                            <option
                              key={item?.code}
                              value={item?.name}
                              data-code={item?.code}
                              label={`${item?.name}`}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Branch
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Branch"
                        size="md"
                        {...register("disbursement_bank_details.branch", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Code
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Bank code"
                        size="md"
                        disabled
                        {...register("disbursement_bank_details.code", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Account Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account Name"
                        size="md"
                        {...register("disbursement_bank_details.account_name", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Account Number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="Account number"
                        size="md"
                        {...register("disbursement_bank_details.acct_number", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        APP ID
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="APP ID"
                        size="md"
                        {...register("disbursement_bank_details.app_id", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        API KEY
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        placeholder="API KEY"
                        size="md"
                        {...register("disbursement_bank_details.app_key", {
                          required: true,
                        })}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 w-1/3 "
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600 mt-4">
                MID Details setup
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Credentials
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Base url"
                      size="md"
                      {...register("mid_credentials_metadata.base_url", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />

                    <Input
                      crossOrigin="anonymous"
                      placeholder="Username"
                      size="md"
                      {...register("mid_credentials_metadata.user_name", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />

                    <Input
                      crossOrigin="anonymous"
                      placeholder="Password"
                      size="md"
                      {...register("mid_credentials_metadata.password", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Token"
                      size="md"
                      {...register("mid_credentials_metadata.token", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600 mt-4">
                Contact Person
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Name
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.name", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    phone
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.phone", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Address
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.address", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Email
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.email", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National id type
                  </Typography>
                  <select
                    {...register("company_person.national_id_type", {
                      required: true,
                    })}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {id_types?.map((item) => (
                      <option value={item?.code} label={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National ID/Ghana card
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.card_number", {
                      required: "Card number is required",
                      // pattern: {
                      //   value: cardNumberPattern,
                      //   message: "Invalid card number format",
                      // },
                    })}
                    placeholder=""
                    defaultValue=""
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {errors.company_person?.card_number && (
                    <p className="text-xs text-red-500">
                      {errors.company_person.card_number.message}
                    </p>
                  )}
                </div>
              </div>
              <Typography className="text-md mt-10 text-gray-600">
                subscribed business class
              </Typography>
              <hr className="my-1" />{" "}
              {subscribed_business_class_fields.map(
                (subscribed_business_class, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                  >
                    <div className="-mt-3 w-full">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className=" font-semibold text-[14px] w-1/3"
                        >
                          Class
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            onChange={(e) => {
                              const selectedId =
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("data-code");

                              const value = {
                                id: selectedId,
                                name: e.target.value,
                              };

                              setValue(
                                `subscribed_business_class.${index}`,
                                value as never
                              );
                            }}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option selected defaultChecked>
                              {loadingsubscribed_business_list
                                ? "Loading..."
                                : "Select Class"}
                            </option>
                            {subscribed_business_list?.subscribed_business_list?.map(
                              (item) => (
                                <option
                                  key={item?.id}
                                  value={item?.name}
                                  data-code={item?.id}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Button
                        className={`${index === 0 && ""}`}
                        size="md"
                        color="red"
                        type="button"
                        onClick={() => remove_subscribed_business_class(index)}
                      >
                        <BiTrash />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {/* <Typography className="text-md mt-10 text-gray-600">
                Upload agreement document
              </Typography>
              <input
                type="file"
                
                className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                onChange={handleChange}
              />
              {errors.file_name && (
                <p className="text-xs text-red-500">
                  {errors.file_name.message}
                </p>
              )} */}

              <Button
                className="mt-4 flex items-center gap-1 capitalize font-normal"
                type="button"
                size="sm"
                onClick={() =>
                  append_subscribed_business_class({
                    id: "",
                    name: "",
                  })
                }
              >
                <FaPlus />
                <span>Add subscribed business class</span>
              </Button>
              <div className="flex items-end justify-end">
                <Button
                  size="sm"
                  type="submit"
                  className="flex flex-row items-center bg-red-400 font-normal"
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <span className="flex flex-row items-center">
                      <BiPlus size={20} />
                      Save
                    </span>
                  )}
                </Button>
              </div>
            </form>
          ) : selectedOption === "Insurance Broker" ? (
            <form
              onSubmit={handleSubmit(onSubmitIntermediary)}
              className="p-3 bg-white rounded-b-xl"
            >
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        name
                      </Typography>
                      <Input
                        {...register("name", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[12px] w-1/3"
                      >
                        Broker Initials (Not less than 3 characters)
                      </Typography>
                      <Input
                        {...register("initials", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        maxLength={3}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        email
                      </Typography>
                      <Input
                        {...register("email", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        type="email"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        address
                      </Typography>
                      <Input
                        {...register("address", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        Phone
                      </Typography>
                      <Input
                        {...register("phone", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Intermediary type code
                      </Typography>
                      <select
                        {...register("intermediary_type", {
                          required: true,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option defaultChecked selected>
                          Select type
                        </option>
                        <option value={"broker"} label="Broker">
                          Broker
                        </option>
                        {/* {intermediary_types?.map((intermediary_type) => (
                          <option
                            disabled={
                              intermediary_type?.code !== "02" ? true : false
                            }
                            selected={
                              intermediary_type?.code === "02" ? true : false
                            }
                            label={intermediary_type?.name}
                            value={intermediary_type?.code}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))} */}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        ⁠TIN number
                      </Typography>
                      <Input
                        {...register("tin_number", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        Broker Logo
                      </Typography>
                      <Input
                        {...register("logo_url", {
                          required: true,
                        })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        nic registration number
                      </Typography>
                      <Input
                        {...register("nic_registration_number", {
                          required: true,
                        })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>

                    {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        Branch code
                      </Typography>
                      <select
                        {...register("mid_branch_details", {
                          required: true,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {branches?.map((item) => (
                          <option
                            value={JSON.stringify(item)}
                            label={item?.name}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        Mid branch
                      </Typography>
                      <select
                        onChange={(e) => {
                          const selectedBankCode =
                            e.target.options[
                              e.target.selectedIndex
                            ].getAttribute("data-code");
                          setValue(
                            "mid_branch_details.name",
                            e.target.value as never
                          );
                          setValue(
                            "mid_branch_details.code",
                            selectedBankCode ? selectedBankCode : ""
                          );
                        }}
                        // {...register("mid_credentials_metadata", {
                        //   required: true,
                        // })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {branches?.map((item) => (
                          <option
                            data-code={item?.code}
                            value={item?.code}
                            label={item?.name}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Contact Person
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Name
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.name", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Phone
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.phone", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Address
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.address", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Email
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.email", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National id type
                  </Typography>
                  <select
                    {...register("company_person.national_id_type", {
                      required: true,
                    })}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {id_types?.map((item) => (
                      <option value={item?.code} label={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National ID/Ghana card
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.card_number", {
                      required: "Card number is required",
                    })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {errors.company_person?.card_number && (
                    <p className="text-xs text-red-500">
                      {errors.company_person.card_number.message}
                    </p>
                  )}
                </div>
              </div>
              <Typography className="text-md text-gray-600 mt-4">
                Debit details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Momo network
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("momo_details.network", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Mobile money mumber"
                      size="md"
                      {...register("momo_details.number", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Collection Bank Details
                  </Typography>
                  <div className="w-full flex flex-col my-1 gap-2">
                    <select
                      // {...register("bank_details.bank_name", {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        const selectedBankCode =
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "data-code"
                          );
                        setValue("bank_details.bank_name", e.target.value);
                        setValue(
                          "bank_details.code",
                          selectedBankCode ? selectedBankCode : ""
                        );
                      }}
                      placeholder="Bank name"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected defaultChecked>
                        {loadingBanks ? "Loading..." : "Select Bank"}
                      </option>
                      {banks?.bank?.map((item) => (
                        <option
                          key={item?.code}
                          value={item?.name}
                          data-code={item?.code}
                          label={`${item?.name}`}
                        >
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Bank code"
                      disabled
                      size="md"
                      {...register("bank_details.code", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Account name"
                      size="md"
                      {...register("bank_details.account_name", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Account number"
                      size="md"
                      {...register("bank_details.acct_number", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Branch"
                      size="md"
                      {...register("bank_details.branch", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="App id"
                      size="md"
                      {...register("bank_details.branch", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="App key"
                      size="md"
                      {...register("bank_details.branch", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Disbursement bank details
                  </Typography>
                  <div className="w-full flex flex-col my-1 gap-2">
                    <select
                      // {...register("disbursement_bank_details.bank_name", {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        const selectedBankCode =
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "data-code"
                          );
                        setValue(
                          "disbursement_bank_details.bank_name",
                          e.target.value as never
                        );
                        setValue(
                          "disbursement_bank_details.code",
                          selectedBankCode ? selectedBankCode : ""
                        );
                      }}
                      placeholder="Bank name"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected defaultChecked>
                        {loadingBanks ? "Loading..." : "Select Bank"}
                      </option>
                      {banks?.bank?.map((item) => (
                        <option
                          key={item?.code}
                          value={item?.name}
                          data-code={item?.code}
                          label={`${item?.name}`}
                        >
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Bank code"
                      disabled
                      size="md"
                      {...register("disbursement_bank_details.code", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Account name"
                      size="md"
                      {...register("disbursement_bank_details.account_name", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Account number"
                      size="md"
                      {...register("disbursement_bank_details.acct_number", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Branch"
                      size="md"
                      {...register("disbursement_bank_details.branch", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="App id"
                      size="md"
                      {...register("disbursement_bank_details.app_id", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="App key"
                      size="md"
                      {...register("disbursement_bank_details.app_key", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
                <Typography className="text-md text-gray-600">
                  Preferred payment Method
                </Typography>
                <hr className="my-1" />
                <div className="mt-3">
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Payment Method
                    </Typography>
                    <div className="w-full flex flex-col gap-2 my-1">
                      <select
                        {...register("preferred_payment_channel", {
                          required: true,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option label="Momo" value={"momo"}>
                          Momo
                        </option>
                        <option label="Bank" value={"bank"}>
                          Bank
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <Typography className="text-md text-gray-600">
                  Commission Setup
                </Typography>
                <hr className="my-1" />{" "}
                {fields.map((commisions_setup, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                  >
                    <div className="-mt-3 w-full">
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className=" font-semibold text-[14px] w-1/3"
                        >
                          Commission Type
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            {...register(
                              `commisions_setup.${index}.commission_id`,
                              { required: true }
                            )}
                            defaultValue={commisions_setup.commission_id}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option selected defaultChecked>
                              {loadingCommissions
                                ? "Loading..."
                                : "Select Bank"}
                            </option>
                            {commissions?.commisions?.map((item: any) => (
                              <option
                                key={item?.code}
                                value={item?.name}
                                label={`${item?.name}`}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="font-semibold text-[14px] w-1/3"
                        >
                          Select mode of settlement
                        </Typography>
                        <div className="w-full flex flex-col gap-2 my-1">
                          <select
                            placeholder="Mode of settlement"
                            {...register(
                              `commisions_setup.${index}.settlement_mode`,
                              { required: true }
                            )}
                            defaultValue={commisions_setup.commission_id}
                            className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option selected defaultChecked>
                              {loadingCommissions
                                ? "Loading..."
                                : "Select settlement mode"}
                            </option>
                            <option
                              label="Instant"
                              className="capitalize"
                              value={"Instant"}
                            >
                              Instant
                            </option>
                            <option
                              label="manual"
                              className="capitalize"
                              value={"manual"}
                            >
                              Manual
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Button
                        className={`${index === 0 && ""}`}
                        size="md"
                        color="red"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <BiTrash />
                      </Button>
                    </div>
                  </div>
                ))}
                <Button
                  className="mt-4 flex items-center gap-1 capitalize font-normal"
                  type="button"
                  size="sm"
                  onClick={() =>
                    append({
                      commission_id: "",
                      settlement_mode: "",
                    })
                  }
                >
                  <FaPlus />
                  <span>Add commission</span>
                </Button>

                {/* <Typography>
                  Upload66 agreement document
                </Typography>
                <input
                  type="file"
                  {...register("company_person.card_number", {
                    required: "Card number is required",
                  })}
                  className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                  onChange={(e) => {
                    const file = e.target.files && e.target.files[0];
                    if (file) {
                      console.log(file);
                    }
                  }}
                />
                {errors.company_person?.card_number && (
                  <p className="text-xs text-red-500">
                    {errors.company_person.card_number.message}
                  </p>
                )} */}

              </div>

              <div className="flex items-end justify-end">
                <Button
                  size="sm"
                  type="submit"
                  className="flex flex-row items-center bg-red-400 font-normal"
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <span className="flex flex-row items-center">
                      <BiPlus size={20} />
                      Save
                    </span>
                  )}
                </Button>
              </div>
            </form>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="p-3 bg-white rounded-b-xl"
            >
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full max-w-2xl ">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        name
                      </Typography>
                      <Input
                        {...register("name", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        email
                      </Typography>
                      <Input
                        {...register("email", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        type="email"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        address
                      </Typography>
                      <Input
                        {...register("address", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        Phone
                      </Typography>
                      <Input
                        {...register("phone", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                      >
                        ⁠TIN number
                      </Typography>
                      <Input
                        {...register("tin_number", { required: true })}
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Contact Person
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Name
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.name", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    phone
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.phone", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Address
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.address", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Email
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    {...register("company_person.email", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National id type
                  </Typography>
                  <select
                    {...register("company_person.national_id_type", {
                      required: true,
                    })}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {id_types?.map((item) => (
                      <option value={item?.code} label={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    National ID/Ghana card
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="md"
                    placeholder=""
                    defaultValue=""
                    {...register("company_person.card_number", {
                      required: "Card number is required",
                      // pattern: {
                      //   value: cardNumberPattern,
                      //   message: "Invalid card number format",
                      // },
                    })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {errors.company_person?.card_number && (
                    <p className="text-xs text-red-500">
                      {errors.company_person.card_number.message}
                    </p>
                  )}
                </div>
              </div>
              <Typography className="text-md text-gray-600 mt-4">
                Debit details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Momo network
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("momo_details.network", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Mobile money mumber"
                      size="md"
                      {...register("momo_details.number", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Bank transfer
                  </Typography>
                  <div className="w-full flex flex-col my-1 gap-2">
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Bank name"
                      size="md"
                      {...register("bank_details.bank_name", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Account number"
                      size="md"
                      {...register("bank_details.acct_number", {
                        required: true,
                      })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      crossOrigin="anonymous"
                      placeholder="Branch"
                      size="md"
                      {...register("bank_details.branch", { required: true })}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end">
                <Button
                  size="sm"
                  type="submit"
                  className="flex flex-row items-center bg-red-400 font-normal"
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <span className="flex flex-row items-center">
                      <BiPlus size={20} />
                      Save
                    </span>
                  )}
                </Button>
              </div>
            </form>
          )}
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">List of Clients</Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3 flex items-center ">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
                <div className="flex flex-row items-center w-full lg:w-1/2 mr-3">
                  <Typography className="w-28">Filter by</Typography>
                  <select
                    value={selectedUsertype}
                    onChange={(e) => setselectedUserType(e.target.value)}
                    className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected disabled>
                      Select filter
                    </option>
                    {["insurance_company", "Broker"].map((item) => (
                      <option
                        className="capitalize"
                        value={item}
                        key={item}
                        label={item}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "client type",
                      "name",
                      "email",
                      // "bank details",
                      // "momo details",
                      // "tin number",
                      "phone number",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                {selectedUsertype === "insurance_company" ? ( //insurance_company
                  <tbody>
                    {insuranceCompanies?.map((item: any, index: number) => {
                      const isLast = index === [{}]?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-left"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                      return (
                        <tr
                          key={item?.name + index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              Insurance company
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              {item?.email}
                            </Typography>
                          </td>
                          {/* <td className={classes}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Name:{" "}
                                <span className="font-normal">
                                  {item?.bank_details?.bank_name}
                                </span>
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                account number:{" "}
                                <span className="font-normal">
                                  {item?.bank_details?.acct_number}
                                </span>
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Network:{" "}
                                <span className="font-normal">
                                  {item?.o_details?.network}
                                </span>
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                account number:{" "}
                                <span className="font-normal">
                                  {item?.momo_details?.number}
                                </span>
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.tin_number}
                            </Typography>
                          </td> */}
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.phone}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={() => {
                                setType("company");
                                setSelectedRow(item);
                                setUpdateModal(true);
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-green-500"
                            >
                              Edit
                            </Button>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={handleManageSubscriptions}
                              size="sm"
                              className="font-normal flex flex-row items-center gap-2 text-xs capitalize p-2 rounded-md text-white hover:shadow-md"
                            >
                              <FaUsers />
                              Manage Subscriptions
                            </Button>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={()=>handleManagePolicy(item?.id, item?.name)}
                              size="sm"
                              className="font-normal flex flex-row items-center gap-2 text-xs capitalize p-2 rounded-md text-white hover:shadow-md"
                            >
                              <MdPolicy />
                              Manage Policy SetUp
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    {intermediaries?.map((item: any, index: number) => {
                      const isLast = index === intermediaries?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-left"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                      return (
                        <tr
                          key={item?.name + index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.intermediary_type}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.email}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Name:{" "}
                                <span className="font-normal">
                                  {item?.bank_details?.bank_name}
                                </span>
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                account number:{" "}
                                <span className="font-normal">
                                  {item?.bank_details?.acct_number}
                                </span>
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Network:{" "}
                                <span className="font-normal">
                                  {item?.momo_details?.network}
                                </span>
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                account number:{" "}
                                <span className="font-normal">
                                  {item?.momo_details?.number}
                                </span>
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.tin_number}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.phone_number}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={() => {
                                setType("broker");
                                setSelectedRow(item);
                                setUpdateModal(true);
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-green-500"
                            >
                              Edit
                            </Button>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={() => { history.push("/dashboard/setup/subscriptions");  }}
                              size="sm"
                              className="font-normal flex flex-row items-center gap-2 text-xs capitalize p-2 rounded-md text-white hover:shadow-md"
                            >
                              <FaUsers />
                              Manage Subscriptions
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </CardBody>

            {loadingAllClients && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !loadingAllClients && (
              <div className="mt-10 flex text-white items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-700">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-700">
                      {Math.ceil(
                        (combinedArray?.filter(filterData).length || 0) /
                        PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (combinedArray?.filter(filterData).length || 0) /
                        PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default Company;
