export const Subscriptions_Table_Head = [
  "reference number",
  "company name",
  "company email",
  "company phone",
  "policy number",
  "intemediary",
  "amount",
  "status",
  "datecreated",
  "",
];

export const Clients_Table_Head = [
  "client type",
  "name",
  "email",
  "phone",
  "address",
  "momo network",
  "company person",
  "created at",
];

export const PoliciesSold_Table_Head = [
  "policy number",
  "policy stage",
  "cover type",
  "amount",
  "duration",
  "payment channel",
  "registration number text",
  "request stage",
  "status",
  "start date",
  "end date",
  "created by",
];
