import {
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { type } from "os";
import React, { ReactNode, useState } from "react";
import { MdDeleteSweep, MdOutlineMarkEmailRead } from "react-icons/md";
import NotificationCard from "../Cards/notification-card";
import { NotificationType } from "../../types/notification";
import useSWR from "swr";
import { toast } from "react-toastify";


interface ModalProps {
  onClose: () => void;
  title?: string;
  size?: number | 250 | any;
  notifications: NotificationType[] | undefined;
  open: boolean;
  loading: boolean;
}

const NotificationDrawer = ({
  onClose,
  open,
  notifications,
  loading,
  size,
}: ModalProps) => {
  const [reading, setReading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [token, setToken] = useState();
  let storageToken = localStorage.getItem("token");


  return (
    <Drawer
      open={open}
      onClose={onClose}
      size={size}
      placement="right"
      className="h-screen"
      overlay={false}
    >
      <div className="bg-gray-200 h-14 flex items-center justify-between px-4">
        <Typography className="text-base font-bold">
          Notification ({notifications?.length})
        </Typography>
        <div className="flex gap-3">
          <Tooltip content="Read all">
            <IconButton
              // onClick={readAllNotification}
              variant="filled"
              size="sm"
            >
              {reading ? (
                <Spinner />
              ) : (
                <MdOutlineMarkEmailRead className="text-white text-lg" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip content="Delete all">
            <IconButton
              // onClick={deleteAllNotification}
              variant="filled"
              size="sm"
              color="red"
            >
              {deleting ? (
                <Spinner />
              ) : (
                <MdDeleteSweep className="text-white text-lg" />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="mt-3 px-2  overflow-y-scroll h-full">
        {loading ? (
          <div className="flex items-center justify-center">
            <Spinner className="w-6 h-6" />
          </div>
        ) : (
          notifications?.map((notification, index) => (
            <NotificationCard {...notification} />
          ))
        )}
        <div className="h-52"></div>
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
