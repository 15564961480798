import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";
import { NotificationType } from "../../types/notification";
import moment from "moment";

interface ModalProps {
  onClose: () => void;
  onDelete: () => void;
  size?: string | "md" | any;
  notification: NotificationType | undefined;
  open: boolean;
  loading?: boolean;
}

export function NotificationModal({
  onClose,
  size,
  notification,
  loading,
  open,
  onDelete,
}: ModalProps) {
  return (
    <Dialog open={open} handler={onClose}>
      <ToastContainer />

      <DialogHeader className="text-lg capitalize">{notification?.title}</DialogHeader>
      <div className="p-4">
        <p className="text-black text-sm mb-2">{notification?.message}</p>
        <p className="text-xs text-gray-500">
          {moment(notification?.date_created).format("LLL")}
        </p>
      </div>

      <DialogFooter>
        <Button variant="text" color="green" onClick={onClose} className="mr-1">
          <span>Cancel</span>
        </Button>

        <Button
          className="flex items-center justify-center"
          variant="gradient"
          color="red"
          onClick={onDelete}
        >
          {loading ? <Spinner className="w-4 h-4" /> : <span>Delete</span>}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
