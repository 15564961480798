export enum DASHBOARD_USERTYPE {
  company = "company",
  brokers = "brokers",
  administrator = "administrator",
}

// Type for individual action
export type PermissionAction = {
  id: number;
  name: string;
  action_type: keyof typeof ActionType;
};


export enum PermissionGroupType {
  DashboardMenu = "Dashboard Menu",
  PaymentOrder = "Payment Order",
  AccountFunding = "Account Funding",
  GenerateReport = "Generate Report",
  AccountFundingApproval = "Account Funding Approval",
  PaymentApproval = "Payment Approval",
  ManageAppUsers = "Manage App Users",
  ManageAccessControl = "Manage Access Control",
  CommissionSetup = "Commission Setup",
}

export enum ActionType {
  ShowMenu = "show_menu",
  CanAdd = "can_add",
  CanEdit = "can_edit",
  CanDelete = "can_delete",
  CanView = "can_view",
}

export type PermissionResponseType = {
  actions: PermissionAction[];
  id: string;
  description?: string;
  name: keyof typeof PermissionGroupType;
};
