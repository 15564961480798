import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash, BiWindows } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { ADD_SUBCLASS_TO_COMPANY } from "../../../../GraphQl/mutations";

import { useHistory } from "react-router-dom";
import {
  GET_ALL_BUSINESS_CLASSES,
  GET_ALL_SUBCLASS,
  GETALLCLASSSUBCLASSOFCOMPANY,
} from "../../../../GraphQl/queries";
import { MdOutlineCheckBoxOutlineBlank, MdPolicy } from "react-icons/md";
import { DELETECOMPANYSUBCLASS } from "../../../../GraphQl/mutations";
import { IoMdCheckbox } from "react-icons/io";
import axios from "axios";
import { TbRuler2 } from "react-icons/tb";

type FormDataType = {
  sub_class_id: string[];
  businessId: string;
};

type CoverType = {
  exclusion_name: string;
  exclusions_id: string;
  exclusion_description: string;
  peril_description: string;
  peril_id: string;
  peril_name: string;
};

type classSubClassType = {
  id: string;
  description: string;
  business_class: {
    id: string;
    name: string;
  };
  subclass: {
    id: string;
    name: string;
  };
};

const ManagePolicySetup = () => {
  const history = useHistory();
  const getCompanyId = localStorage.getItem("company_id") as string;
  const getCompanyName = localStorage.getItem("company_name") as string;

  const [searchClassText, setSearchClassText] = useState("");
  const [searchSubClassText, setSearchSubClassText] = useState("");
  const [selectedSubClass, setSelectedSubClass] = useState<string | null>(null);
  const [selectedClassId, setSelectedClassId] = useState<string | null>(null);
  const [display, setDisplay] = useState<"DISCOUNT" | "BENEFIT" | "COVERTYPE">(
    "COVERTYPE"
  );
  const [isSelectAllIds, setIsSelectAllIds] = useState<boolean>(false);

  const PAGE_SIZE = 10;

  const [
    addSubclassToCompany,
    { data: formReturnData, loading: formLoading, error: formError },
  ] = useMutation(ADD_SUBCLASS_TO_COMPANY);

  const [formData, setFormData] = useState<FormDataType>({
    sub_class_id: [],
    businessId: "",
  });

  const {
    loading: classLoading,
    error: classError,
    data: classData,
    refetch: classRefetch,
  } = useQuery(GET_ALL_BUSINESS_CLASSES);

  const {
    loading: classSubclassLoading,
    error: classSubclassError,
    data: classSubclassData,
    refetch: classSubclassRefetch,
  } = useQuery(GETALLCLASSSUBCLASSOFCOMPANY, {
    variables: {
      company_id: getCompanyId,
    },
  });

  const {
    data: subClassData,
    loading: subClassLoading,
    refetch: subClassRefetch,
  } = useQuery<any>(GET_ALL_SUBCLASS, {
    variables: {
      id: selectedClassId,
    },
    skip: selectedClassId == null,
  });

  const handleCreate = async () => {
    if (formData?.businessId?.length <= 0) {
      toast.info("Select Class Type");
    } else if (formData.sub_class_id.length <= 0) {
      toast.info("Select at least one Subclass");
    } else {
   
      const url = `${process.env.REACT_APP_BASE_URL}/auth/add-subclass-to-company?business_class=${formData?.businessId}&company_id=${getCompanyId}`;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let data = [] as {sub_class_id:string}[]
      formData?.sub_class_id?.map(value=>{
        data.push({
          sub_class_id: value
        })
      })
   

      axios
        .post(url,data , { headers})
        .then((response) => {
            subClassRefetch();
            classSubclassRefetch(); // refetch the list of registered subclass with class
            toast.success("Class and SubClass Created Successful");
        })
        .catch((error) => {
          console.log(error)
          toast.error("Sorry Something went wrong");
        });

      setFormData({
        ...formData,
        sub_class_id: [],
      });
      setIsSelectAllIds(false)

     
    }
  };

  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);

  const filterData = (filter: classSubClassType) => {
    const isTextMatch =
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.business_class?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.subclass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());

    return isTextMatch;
  };

  const filterClass = (filter: {
    name: string;
    description: string;
    status: string;
  }) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchClassText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchClassText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchClassText?.toLowerCase());
    return isTextMatch;
  };

  const filterSubClass = (filter: {
    name: string;
    description: string;
    status: string;
  }) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchSubClassText?.toLowerCase()) ||
      filter?.name
        ?.toLowerCase()
        ?.includes(searchSubClassText?.toLowerCase()) ||
      filter?.status
        ?.toLowerCase()
        ?.includes(searchSubClassText?.toLowerCase());
    return isTextMatch;
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleClassSearch = (e: any) => {
    setSearchClassText(e.target.value);
  };

  const handleSubClassSearch = (e: any) => {
    setSearchSubClassText(e.target.value);
  };

  // Filtered and paginated data for the current page
  const paginatedData = classSubclassData?.company_subclass_covertypes
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const paginatedBusinessClass =
    classData?.business_classes?.filter(filterClass);

  const paginatedSubClass = subClassData?.subclass?.filter(filterSubClass);

  const subclassIdsFromPaginatedData = paginatedData?.map(
    (data: any) => data.subclass.id
  );

  const filteredPaginatedSubClass = paginatedSubClass?.filter(
    (subClass: any) => !subclassIdsFromPaginatedData.includes(subClass.id)
  );

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (classSubclassData?.company_subclass_covertypes?.filter(filterData)
        .length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleManagecoverType = (
    businessId: string,
    businessClass: string,
    subClass: string,
    description: string,
    subClassId: string
  ) => {
    history.push("/dashboard/setup/manage_policy_cover_type", {
      businessId: businessId,
      subClassId: subClassId,
      getCompanyId,
      getClass: businessClass,
      getSubClass: subClass,
      getDescription: description,
    });




   
  };

  const [DeleteCoverType, { error: deleteError, loading: DeleteLoading }] =
    useMutation(DELETECOMPANYSUBCLASS, {
      onCompleted: (data) => {
        toast.success("You have successfully deleted covertype");
        classSubclassRefetch();
        subClassRefetch();
      },
      onError: (error) => toast.error(error?.message),
    });

  const handleDelete = (id: any) => {
    DeleteCoverType({
      variables: {
        id: id,
      },
    });
  };

  const handleSelectAllSubClass = () => {
    if (formData?.businessId?.length <= 0) {
      toast.error("Select business class first");
    } else {
      if (isSelectAllIds === false) {
        setIsSelectAllIds(true);
        const ids = [] as string[];
        filteredPaginatedSubClass?.map((values: { id: string }) => {
          !formData?.sub_class_id?.includes(values?.id) && 
          ids.push(values?.id)
        });

        setFormData({ ...formData, sub_class_id: [...formData?.sub_class_id, ...ids] });
        
      } else {
        setIsSelectAllIds(false);

        setFormData({ ...formData, sub_class_id: [] });
      }
    }
  };

  const handleSelectSubClassId = (id: string) => {
  
    if (formData?.sub_class_id?.includes(id)) {
      if (isSelectAllIds === true) {
        setIsSelectAllIds(false);
      }
      const newData = formData?.sub_class_id?.filter((idx, index)=>{
        return idx !== id
      })

      setFormData({ ...formData, sub_class_id: newData });
    }
    else{
      setFormData({ ...formData, sub_class_id: [...formData?.sub_class_id, id] });
    }
  };

  return (
    <>
      <CustomHeader title="Manage Company Policy Setups" />
      <div className="mt-5 flex items-end gap-5 mb-5 ">
        <Button
          //   onClick={() => handleCreate()}
          size="md"
          className="capitalize flex flex-row items-center bg-red-400  tracking-widest text-sm font-medium"
        >
          SubClass/Covertypes
        </Button>
        <Button
          onClick={() => { history.push("/dashboard/setup/manage_discount") }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          Discount
        </Button>
        <Button
           onClick={() => { history.push("/dashboard/setup/manage_benefit"); }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          Additional Benefit
        </Button>
        <Button
           onClick={() => { history.push("/dashboard/setup/manage_policy_loadings"); }}
          size="md"
          className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
        >
          Loadings
        </Button>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Select Business Class</Typography>
            </div>
            <div className="p-3 bg-white">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchClassText}
                  onChange={handleClassSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Class"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBusinessClass?.map(
                  (item: { id: string; name: string }) => (
                    <tr
                      onClick={() => {
                        setSelectedClassId(item?.id);
                        if (selectedClassId === item?.id) {
                          setFormData({ ...formData, businessId: "" });

                          setSelectedClassId(null);
                        } else {
                          setFormData({ ...formData, businessId: item?.id });

                          setSelectedClassId(item?.id);
                        }
                      }}
                      key={item.id}
                      className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${formData?.businessId === item?.id
                        ? "bg-red-200 hover:bg-red-200"
                        : "bg-white"
                        }`}
                        >
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                           className="font-normal text-sm capitalize "
                        >
                          
                          {item?.name}
                        </Typography>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {classLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          <div className="capitalize bg-white rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Select SubClass</Typography>
            </div>
            <div className="p-3 ">
              <div className="w-full mr-3 flex justify-center items-center ">
              <div className="text-black bg-gray-50/50 text-sm flex space-x-4 gap-2 w-[40%]">
                  <button
                    className="flex gap-2 items-center"
                    onClick={() => handleSelectAllSubClass()}
                  >
                    {isSelectAllIds ? (
                      <IoMdCheckbox size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank />
                    )}
                    Select All
                  </button>
                </div>
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchSubClassText}
                  onChange={handleSubClassSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />

                
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["SubClass", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredPaginatedSubClass?.map(
                  (item: { id: string; name: string; description: string }) => (
                    <tr
                      onClick={() => {
                        handleSelectSubClassId(item?.id)
                        // setFormData({ ...formData, sub_class_id: item?.id });
                        // if (selectedSubClass === item?.id) {
                        //   setSelectedSubClass(null);
                        // } else {
                        //   setSelectedSubClass(item?.id);
                        // }
                      }}
                      key={item.id}
                      className={`transition-all hover:cursor-pointer hover:bg-gray-100 bg-white
                      }`}
                    >

                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize flex gap-5 "
                        >
                          {formData?.sub_class_id?.includes(item?.id) ? (
                            <IoMdCheckbox />
                          ) : (
                            <MdOutlineCheckBoxOutlineBlank />
                          )}

                          {item?.name}
                        </Typography>
                      </td>
                      <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm capitalize "
                        >
                          {item?.description}
                        </Typography>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            {subClassLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          {/* <div className="w-full my-2 bg-white p-5">
            <Typography
              variant="h6"
              color="blue-gray"
              className="mb-2 font-semibold text-[14px] w-1/3"
            >
              Description
            </Typography>
            <Textarea
              size="lg"
                value={formData.description}
                onChange={(event)=>    setFormData({...formData, description:event.target.value})}
              name="description"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </div> */}

          <div className="mt-5 flex items-end justify-end">
            <Button
              onClick={() => handleCreate()}
              size="sm"
              className="capitalize flex flex-row items-center bg-red-400 font-normal"
            >
              <BiPlus size={30} />

              {formLoading ? "Creating..." : "Create Class and SubClass"}
            </Button>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">
              List of
              {" " + getCompanyName + " "}
              Business Class and SubClass
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "Business Class",
                      "SubClass",
                      "Manage Cover Type",
                      "Action",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map(
                    (item: classSubClassType, index: number) => {
                      const isLast = index === paginatedData?.length - 1;
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                          onClick={() => {}}
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {index + 1}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.business_class?.name}
                              {/* {`${item?.peril_name} - ${item?.exclusion_name}`} */}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.subclass?.name}
                            </Typography>
                          </td>

                          {/* <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td> */}

                          <td className={classes}>
                            <div className="flex justify-center items-center">
                              <Button
                                onClick={() =>
                                  handleManagecoverType(
                                    item?.business_class?.id,
                                    item?.business_class?.name,
                                    item?.subclass?.name,
                                    item?.description,
                                    item?.subclass?.id
                                  )
                                }
                                size="sm"
                                className="font-normal flex flex-row items-center gap-2 text-xs capitalize p-2 rounded-md text-white hover:shadow-md"
                              >
                                <MdPolicy />
                                Manage Cover Type
                              </Button>
                            </div>
                          </td>

                          <td className={classes}>
                            <div className="flex flex-row items-center gap-x-2">
                              <IconButton
                                onClick={(e) => {
                                  handleDelete(item?.id);
                                }}
                                color="red"
                                size="sm"
                              >
                                <BiTrash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </CardBody>

            {classSubclassLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !classSubclassLoading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (classSubclassData?.company_subclass_covertypes?.filter(
                          filterData
                        ).length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (classSubclassData?.company_subclass_covertypes?.filter(
                          filterData
                        ).length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManagePolicySetup;
