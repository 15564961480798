import {
  Dialog,
  DialogBody,
  DialogHeader,
  Drawer,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { ReactNode } from "react";

interface ModalProps {
  onClose: () => void;
  title?: string;
  size?: number | 250 | any;
  children?: ReactNode | null;
  open: boolean;
}

export default function DetailDrawer({
  onClose,
  open,
  children,
  title,
  size,
}: ModalProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      size={size}
      placement="right"
      className="p-4"
    >
      <div className=" mt-5 mb-6 flex items-center justify-between">
        <Typography variant="h5" color="blue-gray">
          {title ? title : ""}
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      {children}
    </Drawer>
  );
}
