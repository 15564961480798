export enum TargetTypes {
  brands = "brands",
  admins = "admins",
  individual = "individual",
  promoters = "promoters",
}

export enum ChannelTypes {
  sms = "sms",
  email = "email",
  inapp = "inapp",
}
export type NotificationType = {
  id?: string;
  title: string;
  message: string;
  channel: string;
  created_at: string;
  date_created: string;
  status: string;
  created_by: string;
  target: string;
  read: boolean;
};

export type CreateNotificationType = {
  message: string;
  title: string;
  channel: ChannelTypes;
  target: TargetTypes;
  status: string;
  subject: string;
  recipient: string;
  other_user: {
    phone: string;
  };
};
