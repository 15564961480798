import React from 'react';

interface ConfirmationDialogProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ show, setShow, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-8 w-1/2">
            <p className="text-lg mb-4">Are you sure you want to proceed?</p>
            <div className="flex justify-end">
              <button
                onClick={handleConfirm}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
              >
                Yes
              </button>
              <button
                onClick={() => setShow(false)}
                className="bg-red-400 px-4 text-white py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationDialog;
