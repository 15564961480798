import React from "react";
import { AdminType } from "../../types/admin";
import { Typography } from "@material-tailwind/react";

const RenderAdminDetail = ({ content }: { content: AdminType | undefined }) => {
  return (
    <div className="overflow-scroll h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>
      </div>
      <Typography variant="h5" className="text-lg mt-10 mb-3" color="blue-gray">
        Admin details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div className="flex flex-row items-center justify-between mb-4">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-8"
              color="blue-gray"
            >
              Name
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-8 capitalize"
              color="blue-gray"
            >
              Phone
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.phoneNumber}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.phoneNumber}
            </Typography>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-8"
              color="blue-gray"
            >
              Email
            </Typography>
            <Typography
              as={"a"}
              href={`mailto:${content?.email}`}
              className="text-sm font-semibold  mt-2"
              color="blue-gray"
            >
              {content?.email}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-8 capitalize"
              color="blue-gray"
            >
              Role
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.role}
            </Typography>
          </div>
        </div>
        <div className="h-20" />
      </div>
    </div>
  );
};

export default RenderAdminDetail;
