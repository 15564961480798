import { useQuery, gql } from "@apollo/client";

export const GETPERMISSIONS = gql`
  query MyQuery {
    permission_setup {
      actions
      id
      name
      platform
    }
  }
`;

export const GETADMINROLES = gql`
  query getCompanyRoles($id: uuid!) {
    company_role(where: { company_id: { _eq: $id } }) {
      created_at
      description
      id
      name
    }
  }
`;

export const GETPERMISSIONSFORSPECIFICROLE = gql`
  query getPermissionsForSpecificRole($role_id: uuid!) {
    company_role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        id
        name
      }
      role_id
      selected_actions
    }
  }
`;

export const GETSUBSCRIBECLASS = gql`
  query MyQuery {
    subscribed_business_list {
      id
      name
    }
  }
`;

export const GETROLES = gql`
  query MyQuery {
    roles {
      id
      name
    }
  }
`;

export const GETADMINS = gql`
  query MyQuery {
    admins {
      active_status
      balance
      block
      block_reason
      email
      first_name
      id
      last_name
      phone_number
      pin
      role {
        id
        name
      }
    }
  }
`;

export const GET_COMPREHENSIVE = gql`
  query getComprehensiveRisks {
    comprehensive_third_party_risk_class {
      id
      created_at
      comprehensive
      own_damage_excess_rate
      risk_id
      third_party_rate_theft
      risk_class {
        id
        name
      }
    }
  }
`;

export const GET_THIRDPARTY = gql`
  query getThirdParty {
    third_party_only_risk_class {
      basic_premium
      created_at
      fee_amount
      id
      loading_amount
      seat_amount
      total_amount
      updated_at
      risk_class {
        id
        name
      }
    }
  }
`;

export const GET_ALLRISKCLASS = gql`
  query getAllRiskClassItems {
    risk_class {
      id
      name
      status
    }
  }
`;

export const GET_ROLES = gql`
  query MyQuery {
    roles {
      name
      id
    }
  }
`;

export const GET_INTERMEDIARIES = gql`
  query MyQuery {
    intemediary {
      active_status
      commission_rate
      created_at
      email
      id
      intermediary_type
      name
      phone_number
    }
  }
`;

export const GET_ALL_SETUPS = gql`
  query getAllsetups {
    countries: country(order_by: { name: asc }) {
      id
      is_active
      iso_code
      name
      nationality
      created_at
    }
    discounts: discount {
      created_at
      id
      rate
      description
      status
    }
    banks: bank(order_by: { name: asc }) {
      code
      created_at
      id
      is_active
      name
    }
    body_types: body_type(order_by: { name: asc }) {
      id
      created_at
      is_active
      name
    }
    vehicle_makes: vehicle_make(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
      vehicle_models(
        order_by: { name: asc }
        where: { is_active: { _eq: true } }
      ) {
        id
        name
      }
    }
  }
`;

export const GET_REGIONS = gql`
  query getAllRegions {
    regions {
      id
      name
    }
  }
`;

export const GET_ALL_DISTRICTS = gql`
  query getAllDistrictofRegion($regionId: uuid) {
    districts(where: { region_id: { _eq: $regionId } }) {
      id
      name
      category
      capital
      region {
        name
      }
    }
  }
`;

export const GET_ALL_TOWNS = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
    }
  }
`;

export const GET_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`;

export const GET_ALL_CLIENT = gql`
  query getAllIntermediaries {
    intemediary(
      where: { intermediary_type: { _eq: "broker" } }
      order_by: { created_at: desc }
    ) {
      address
      bank_details
      disbursement_bank_details
      email
      id
      intermediary_type
      momo_details
      preferred_payment_channel: preferred_payment_method
      name
      active_status
      branch {
        code
      }
      initials
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
    insurer_companies(order_by: { created_at: desc }) {
      bank_details
      client_type
      email
      mid_credentials_metadata
      disbursement_bank_details
      subscribed_business_class
      company_initials
      id
      logo_url
      address
      momo_details
      phone
      name
      tin_number
      clientTypeByClientType {
        id
        name
      }
    }
  }
`;

export const GET_ROLE_PERMISSIONS = gql`
  query getRolePermissions($role_id: uuid) {
    role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        name
      }
      selected_actions
    }
  }
`;

export const GET_ALL_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`;

export const GET_ALL_DIVISIONS = gql`
  query getAllDivisions {
    divisions {
      id
      name
      description
    }
  }
`;

export const GET_ALL_TOWNS_OF_DISTRICT = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
      district {
        region {
          name
        }
        name
      }
    }
  }
`;

export const GETSUBCLASSES = gql`
  query getSubclassesofClass($class_id: uuid) {
    subclass(where: { class_id: { _eq: $class_id } }) {
      id
      name
      status
      description
      business_class {
        name
        description
      }
    }
  }
`;

export const GETBUSINESSCLASSES = gql`
  query getAllBusinessClasses {
    business_classes {
      id
      image_url
      name
      description
    }
  }
`;

export const GETALLPERILS = gql`
  query getAllPerils {
    perils {
      class_id
      description
      id
      name
      status
    }
  }
`;

export const GETALLEXCLUSIONS = gql`
  query getAllExclusions {
    exclusions {
      id
      name
      description
      status
    }
  }
`;

export const GETALLPERILSNEXCLUSIONSOFCOVERTYPE = gql`
  query getAllPerilsNExclusionsOfCoverType($id: uuid!) {
    view_coverage_details(where: { cover_type_id: { _eq: $id } }) {
      exclusion_name
      exclusions_id
      exclusion_description
      peril_description
      peril_id
      peril_name
    }
  }
`;

export const GETPREMIUMRATES = gql`
  query getAllPremiumRates {
    view_premium_rate {
      class_id
      class_name
      covertype_id
      covertype_name
      id
      premium_description
      premium_rate
      premium_rate_created_at
      subclass_id
      subclass_name
    }
  }
`;

export const GETCOVERTYPES = gql`
  query getCoverTypes {
    covertypes {
      description
      id
      name
      class_id
      status
    }
  }
`;

export const GETALLDISCOUNTS = gql`
  query getAllDiscounts {
    system_discount {
      description
      id
      name
      status
    }
  }
`;
export const GET_ALL_DISCOUNTS = gql`
  query getAllDiscounts {
    discount {
      description
      id
      name
      status
      business_class: businessClassByBusinessClass {
        id
        name
      }
    }
  }
`;

export const GETDISCOUNTRATESOFDISCOUNT = gql`
  query getDiscountRatesOfDiscount($discount_id: uuid!) {
    view_discount_rates(where: { dicount_id: { _eq: $discount_id } }) {
      class_name
      subclass_name
      cover_type_name
      description
      rate
      id
      dicount_id
      discount_name
    }
  }
`;

export const GETALLLOADINGS = gql`
query getAllLoadings {
    loadings {
      description
      id
      name
      businessClassByBusinessClass{
        id
        name
      }
      status
}
}
`;

export const GETALLLOADINGRATES = gql`
  query getLoadingrates($loading_id: uuid!) {
    loading_rates(where: { loading_id: { _eq: $loading_id } }) {
      id
      loading {
        id
        name
      }
      description
      rate
      status
      subclass {
        id
        name
      }
      covertype {
        id
        name
      }
      business_class {
        name
        id
      }
    }
  }
`;

export const DELETELOADINGRATE = gql`
  mutation deleteLoadingRate($id: uuid!) {
    delete_loading_rates_by_pk(id: $id) {
      id
    }
  }
`;

export const GETALLCOMMMISSIONS = gql`
  query allCommissions {
    commisions {
      description
      id
      name
      status
      created_at
      is_special
      code
    }
  }
`;

export const GETALLBANKS = gql`
  query getALlBanks {
    bank {
      code
      id
      name
    }
  }
`;
export const GETALLTAXES = gql`
  query MyQuery {
    taxes {
      id
      name
    }
  }
`;

export const GETALLCOMMMISSIONSRATE = gql`
  query getCommissionRate($commission_id: uuid!) {
    commisions_rates(where: { commision_id: { _eq: $commission_id } }) {
      class_id
      commision_id
      id
      intermediary_type
      rate
      status
      subclass {
        id
        description
        name
      }
      business_class {
        id
        name
        description
      }
      description
      created_at
    }
  }
`;

export const GETALLLEVIES = gql`
  query getAllLevies {
    levies {
      created_at
      description
      id
      name
      status
    }
  }
`;

export const GETLEVYRATES = gql`
  query getLevyRates($id: uuid!) {
    levies_rate(where: { levy_id: { _eq: $id } }) {
      amount
      business_class {
        id
        name
        description
      }
      covertype {
        id
        description
        name
      }
      description
      id
      levy_id
      rate
      status
      subclass {
        id
        description
        name
      }
    }
  }
`;

export const GETALLTAX = gql`
  query getAllTaxes {
    taxes {
      created_at
      description
      id
      name
      status
    }
  }
`;

export const GETALLSUBSCRIPTION = gql`
  query getAllSubscription {
    subscription_rate {
      description
      id
      name
      rate
      business_class_id
      subclass_id
      user_type
      created_at
      insurerCompanyByInsurerCompany {
        id
        name
      }
    }
  }
`;

export const GETTAXRATES = gql`
  query getTaxRates($id: uuid!) {
    tax_rate(where: { tax_id: { _eq: $id } }) {
      amount
      business_class {
        id
        name
        description
      }
      covertype {
        id
        description
        name
      }
      description
      id
      tax_id
      rate
      status
      subclass {
        id
        description
        name
      }
    }
  }
`;

export const DASHBOARDAGGREGATES = gql`
  query dashboardAggregates {
    insurance_companies: insurer_companies_aggregate {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: { intermediary_type: { _eq: "agent" } }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: { intermediary_type: { _eq: "broker" } }
    ) {
      aggregate {
        count
      }
    }
    policies: policy_aggregate(distinct_on: created_at) {
      aggregate {
        count
      }
    }
    policy_holders: view_total_policyholders_count {
      total_policyholders
    }
    total_policy_sold: view_total_policy_sum {
      total_amount_sold
    }
    subscription_rate {
      rate
    }
    subscription_amount: subscription_payment_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const CLIENT360AGGREGATES = gql`
  query client360Aggregates {
    insurance_companies: insurer_companies_aggregate {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: { intermediary_type: { _eq: "agent" } }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: { intermediary_type: { _eq: "broker" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GETTOTALPOLICYPERMONTH = gql`
  query getTotalPolicyPerMonth {
    months_policies: view_total_policy_sum_by_month {
      month
      total_amount
    }
  }
`;

export const GETALLINSURANCECOMPANIES = gql`
  query getAllInsuranceCompanies {
    insurer_companies {
      email
      id
      momo_details
      bank_details
      tin_number
      phone
      name
      clientTypeByClientType {
        name
        id
      }
      client_type
      company_person
      created_at
    }
  }
`;

export const GET_PARTICULAR_COMPANY_PAYMENTS = gql`
  query MyQuery($company_id: uuid) {
    view_system_client_payments_aggregate(
      where: { company_id: { _eq: $company_id }, status: { _eq: "pending" } }
    ) {
      aggregate {
        sum {
          net_amount
          premium_amount
        }
      }
    }
    view_system_client_payments(
      where: { company_id: { _eq: $company_id }, status: { _eq: "pending" } }
    ) {
      commission_amount
      datecreated
      net_amount
      policy_id
      premium_amount
      status
      subscription_amount
      tax_amount
    }
  }
`;

export const GETALLINTERMEDIARIESAGENT = gql`
  query getAllIntermediariesAgent {
    intemediary(where: { intermediary_type: { _eq: "agent" } }) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status
      branch {
        code
      }
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
  }
`;

export const GETALLINTERMEDIARIESBROKER = gql`
  query getAllIntermediariesBroker {
    intemediary(where: { intermediary_type: { _eq: "broker" } }) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status

      branch {
        code
      }

      contact_person
      nic_registration_number
      phone_number
      tin_number
      registration_number
    }
  }
`;

export const GETALLRISKCLASS = gql`
  query getAllriskClass {
    risk_class {
      name
      id
    }
  }
`;

export const GETDETAILOFRISKCLASS = gql`
  query getDetailsofRiskClass($id: uuid!) {
    third_party_only_risk_class(where: { risk_class: { id: { _eq: $id } } }) {
      basic_premium
      ecowas_perils
      fee_amount
      total_amount
    }
  }
`;

export const GETINSURANCECOMPANYSUBSCRIPTION = gql`
  query MyQuery($id: uuid) {
    total_amount: subscription_payment_aggregate(
      where: { insurance_company_id: { _eq: $id }, status: { _eq: "paid" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    subscription_payment(where: { id: { _eq: $id } }) {
      amount
      datecreated
      dateupdated
      id
      insurer_company {
        id
        name
      }
      subscriptionRateBySubscriptionRate {
        rate
      }
      policy {
        policy_number
        reciept_number
        request {
          premium_amount: amount
          businessClassByBusinessClass {
            name
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTIONPAYMENTAGGREGATE = gql`
  query MyQuery {
    subscription_payment_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const TOTAL_SUBSCRIPTION_LIST = gql`
 query MyQuery {
  subscription_payment_aggregate(where: {status: {_eq: "pending"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
  subscription_payment(where: {status: {_eq: "pending"}}, order_by: {datecreated: desc}) {
    amount
    id
    datecreated
    reference_number
    insurer_company {
      name
      phone
      email
    }
    intemediary {
      email
      id
      name
      phone_number
    }
    status
    subscription_rate: subscriptionRateBySubscriptionRate {
      id
      rate
      name
    }
    policy {
      policy_number
    }
  }
}

`;

export const GETSUBSCRIPTION = gql`
  query getsubscription($startdate: timestamp, $enddate: timestamp) {
    subscription_payment(
      where: {
        status: { _eq: "paid" }
        datecreated: { _lte: $enddate, _gte: $startdate }
      }
    ) {
      amount
      datecreated
      reference_number
      insurer_company {
        name
        phone
        email
      }
      intemediary {
        email
        id
        name
        phone_number
      }
      status
      subscription_rate: subscriptionRateBySubscriptionRate {
        id
        rate
        name
      }
      policy {
        policy_number
      }
    }
  }
`;

export const GETPOLICIES = gql`
  query getPolicies($startdate: timestamp, $enddate: timestamp) {
    policy(where: { created_at: { _lte: $enddate, _gte: $startdate } }) {
      certificate_number
      created_by
      debit_number
      id
      policy_number
      policy_stage
      status
      request {
        amended_rated_details
        amount
        benefit_added_details
        businessClassByBusinessClass {
          name
          id
        }
        cover_type
        created_by
        currencyByCurrency {
          id
          name
        }
        duration
        end_date
        is_paid
        insurerCompanyByInsurerCompany {
          id
          name
        }
        payment_channel
        registration_number_text
        request_stage
        start_date
        risk_class {
          id
          name
        }
        vehicle_details
        user_type
        sub_agent {
          id
          last_name
          first_name
        }
      }
      request_id
    }
  }
`;

export const GETCLIENTDETAILS = gql`
  query ClientDetails($startdate: timestamp, $enddate: timestamp) {
    insurer_companies(
      where: { created_at: { _lte: $enddate, _gte: $startdate } }
    ) {
      address
      client_type
      colors
      company_person
      created_at
      disbursement_bank_details
      email
      id
      logo_url
      momo_details
      name
      phone
      tin_number
    }
    intemediary(where: { created_at: { _lte: $enddate, _gte: $startdate } }) {
      address
      active_status
      contact_person
      email
      guarantor_details
      id_number
      id_type
      intermediary_type
      intermediary_type_code
      momo_details
      nic_registration_number
      phone_number
      registration_number
      tin_number
    }
  }
`;

export const GETALLEXTERNALCLIENTS = gql`
  query getAllExternalClients {
    external_client {
      account_details
      active_status
      created_at
      email
      id
      momo_details
      name
      phone_number
      tax_details
    }
  }
`;

export const GETALLMESSAGETYPES = gql`
  query getAllMessageTypes {
    message_types {
      datecreated
      dateupdated
      description
      id
      name
      status
    }
  }
`;

export const GETMESSAGETEMPLATEBYTYPE = gql`
  query getMessageTemplateByType($id: uuid) {
    message_template(
      where: { messageTypeByMessageType: { id: { _eq: $id } } }
    ) {
      message_body
      message_type
      message_variables
      id
      dateupdated
      datecreated
      messageTypeByMessageType {
        id
        name
        status
        description
        datecreated
      }
    }
  }
`;

export const GETALLMESSAGETEMPLATES = gql`
  query getAllMessageTemplates {
    message_template {
      datecreated
      dateupdated
      id
      message_body
      message_type
      message_variables
      messageTypeByMessageType {
        id
        name
        status
        description
      }
    }
  }
`;

export const GETALLPREFIXES = gql`
  query AllPrefixes {
    vehicle_prefix {
      id
      name
    }
  }
`;

export const GET_VEHICLE_MAKE = gql`
  query MyQuery {
    vehicle_make(order_by: { created_at: desc }) {
      id
      name
    }
  }
`;

export const GET_ALL_VEHICLE_MODEL = gql`
  query MyQuery($vehicl_make_id: uuid) {
    vehicle_model(where: { vehicle_make: { id: { _eq: $vehicl_make_id } } }) {
      make_id
      name
      id
    }
  }
`;

export const GET_VEHICLE_COLOR = gql`
  query MyQuery {
    colors(order_by: { date_created: desc }) {
      id
      name
    }
  }
`;

export const GET_CONSTRUCTION_TYPES = gql`
  query getAllConstructionTypes {
    construction_type {
      code
      id
      state
      description
      type
    }
  }
`;

export const GET_USE_OF_PROPERTIES = gql`
  query getAllPropertyUseTypes {
    property_type {
      code
      description
      id
      state
      type
    }
  }
`;

export const GET_ALL_BUSINESS_CLASSES = gql`
  query MyQuery {
    business_classes {
      id
      name
    }
  }
`;
export const GET_ALL_SUBCLASS = gql`
  query getSubclassGivenBusinessClass($id: uuid) {
    subclass(where: { business_class: { id: { _eq: $id } } }) {
      id
      name
      description
      created_at
      status
    }
  }
`;


export const GETALLCLASSSUBCLASSOFCOMPANY = gql`
  query GETALLSUBCLASSOFCOMPANY($company_id: uuid) {
  company_subclass_covertypes(where: {company_id: {_eq: $company_id}}, distinct_on: sub_class_id) {
    description
    id
    business_class {
      id
      name
    }
    subclass {
      id
      name
    }
  }
}

`;


export const Company_Subclass_CoverType = gql`
  query MyQuery($sub_class_id: uuid, $company_id: uuid) {
    company_subclass_covertypes(where: {company_id: {_eq: $company_id}, sub_class_id: {_eq: $sub_class_id}}) {
      id
      covertypes
      description
    }
  }
`;

export const business_coverType = gql`
  query MyQuery($id: uuid!) {
    covertypes(where: { business_class: { id: { _eq: $id } } }) {
      id
      name
      description
      status
    }
  }
`;


export const business_discount = gql`
  query getAllDiscount($id: uuid) {
  discount(where: {business_class: {_eq: $id}}) {
    id
    name
    description
   
    status
  }
}

`;

export const business_benefits = gql`
 query GETADDITIONALBENEFIT($id:uuid) {
  additional_benefit(where: {business_class: {_eq: $id}}) {
    created_at
    description
    id
    name
    status
  }
}
`;


export const get_benefits_company = gql`
query myQuery($company_id: uuid) {
  company_additional_benefits(where: {company_id: {_eq: $company_id}}) {
    benefit: additional_benefit {
      id
      name
      description
      businessClassByBusinessClass{
        id
        name
      }
    }
    created_at
    id
  
  }
}
`;


export const GET_COMPANY_DISCOUNT = gql`
  query MyQuery($company_id: uuid) {
  company_discounts(where: {company_id: {_eq: $company_id}}, distinct_on: discount_id) {
    discount {
      id
      description
      businessClassByBusinessClass{
        id
        name
      }
    }
    created_at
 
    id
  }
}


`


export const GET_COMPANY_BENEFIT = gql`
  query getBenefitsofCompany($company_id: uuid) {
  company_additional_benefits(where: {company_id: {_eq: $company_id}}) {
    benefit: additional_benefit {
      id
      name
      description
    }
    created_at
    id
  
  }
}
`

export const GET_ADDITIONAL_BENEFIT = gql`
  query GETADDITIONALBENEFIT {
    additional_benefit {
      created_at
      description
      id
      name
      status
    }
  }
`;

export const GET_ADDED_ADDITIONAL_BENEFITS = gql`
  query MyQuery {
    additional_benefit {
      id
      description
      businessClassByBusinessClass {
        id
        name
      }
      name
    }
  }
`;


export const GET_COMPANY_LOADING = gql`
  query allLoadings($id: uuid) {
    loadings(where: { business_class: { _eq: $id } }) {
      id
      description
      businessClassByBusinessClass {
        id
        name
      }
      name
      status
      created_at
    }
  }
`;
export const GET_ALL_MANAGING_LOADINGS = gql`
  query myQuery($company_id: uuid!) {
    company_loading(where: { company_id: { _eq: $company_id } }) {
      loading {
        id
        name
        description
        businessClassByBusinessClass {
          id
          name
        }
      }
      created_at
      id
    }
  }
`;

export const GET_ALL_DRIVER_STATION = gql`
query MyQuery {
  driver_stations {
    id
    name
  }
}
`








// export const GETMESSAGETEMPLATEBYTYPE = gql `
// query GetMessageTemplateByType($id:uuid) {
//   message_template(where: {messageTypeByMessageType: {id: {_eq: $id}}}) {
//     message_body
//     message_type
//     message_variables
//     id
//     dateupdated
//     datecreated
//     messageTypeByMessageType {
//       id
//       name
//       status
//       description
//       datecreated
//     }
//   }
// }
// `;
