import React, { useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IconEdit } from "@tabler/icons-react";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQuery } from "@apollo/client";
import { GETALLPREFIXES } from "../../../GraphQl/queries";
import { toast } from "react-toastify";
import {
  ADDNEWPREFIX,
  DELETEPREFIX,
  UPDATEPREFIX,
} from "../../../GraphQl/mutations";

const VehiclePrefix = () => {
  interface Prefix {
    id: string;
    name: string;
  }
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 4;
  const [newPrefix, setNewPrefix] = useState("");
  const [editPrefixId, setEditPrefixId] = useState<string | null>(null);

  const { loading, error, data } = useQuery(GETALLPREFIXES);
  const prefixes: Prefix[] = data?.vehicle_prefix || [];

  const [addNewPrefix] = useMutation(ADDNEWPREFIX, {
    refetchQueries: [{ query: GETALLPREFIXES }],
    onCompleted: () => {
      setNewPrefix("");
      toast.success("Prefix added successfully!");
    },
    onError: (err) => {
      toast.error(`Error: ${err.message}`);
    },
  });

  const [updatePrefix] = useMutation(UPDATEPREFIX, {
    refetchQueries: [{ query: GETALLPREFIXES }],
    onCompleted: () => {
      setNewPrefix("");
      setEditPrefixId(null);
      toast.success("Prefix updated successfully!");
    },
    onError: (err) => {
      toast.error(`Error: ${err.message}`);
    },
  });

  const [deletePrefix] = useMutation(DELETEPREFIX, {
    refetchQueries: [{ query: GETALLPREFIXES }],
    onCompleted: () => {
      toast.success("Prefix deleted successfully!");
    },
    onError: (err) => {
      toast.error(`Error: ${err.message}`);
    },
  });

  const totalPages = Math.ceil(prefixes.length / PAGE_SIZE);

  const nextPage = () => {
    if (activePage < totalPages) {
      setActivePage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage((prevPage) => prevPage - 1);
    }
  };

  const paginatedData = prefixes.slice(
    (activePage - 1) * PAGE_SIZE,
    activePage * PAGE_SIZE
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPrefix.trim()) {
      if (editPrefixId) {
        updatePrefix({ variables: { id: editPrefixId, name: newPrefix } });
      } else {
        addNewPrefix({ variables: { name: newPrefix } });
      }
    } else {
      toast.error("Prefix name cannot be empty!");
    }
  };

  const handleEdit = (prefix: Prefix) => {
    setNewPrefix(prefix.name);
    setEditPrefixId(prefix.id);
  };

  const handleDelete = (id: string) => {
    deletePrefix({ variables: { id } });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {toast.error(error.message)}</p>;

  return (
    <div>
      <CustomHeader title="Vehicle Prefix" />
      <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-5">
        <div className="rounded-lg overflow-hidden ">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              {editPrefixId ? "Edit Vehicle Prefix" : "Create Vehicle Prefix"}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col">
              <div className="w-full">
                <div className="w-full my-2 gap-3 mb-6 flex flex-col items-center justify-between lg:flex-row md:flex-row lg:item-center md:items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 mr-2 font-semibold text-[14px] w-3/3 "
                  >
                    Prefix
                  </Typography>
                  <input
                    value={newPrefix}
                    onChange={(e) => setNewPrefix(e.target.value)}
                    className="ml-6 w-full border-[#B0BEC5] border-[1px] rounded-lg px-3 py-2"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
              >
                {editPrefixId ? "Update Prefix" : "Add Prefix"}
              </Button>
            </div>
          </form>
        </div>
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <CardBody className="shadow-sm overflow-x-scroll px-0 py-0 rounded-3xl">
            <div className="bg-black pt-5">
              <Typography
                variant="h6"
                color="black"
                className="px-4 pb-4 font-bold text-brand-primary leading-none opacity-70 capitalize"
              >
                List of All Vehicle Prefix
              </Typography>
            </div>
            <div className="w-full">
              <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
                <div className="flex flex-col md:flex-row lg:flex-row gap-3 w-full">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
              </div>
            </div>
            <table className="  bg-white w-full min-w-max table-auto">
              <thead>
                <tr>
                  {["No.", "Prefix", ""].map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData &&
                  paginatedData.map((item, index) => {
                    const isLast = index === paginatedData.length - 1;
                    const className = isLast
                      ? "px-2 py-3 text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {(activePage - 1) * PAGE_SIZE + index + 1}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item.name}
                          </Typography>
                        </td>
                        <td
                          className={`flex flex-row items-center justify-center gap-2 ${className}`}
                        >
                          <button onClick={() => handleEdit(item)}>
                            <IconButton className="bg-[#4CAF4F]" size="sm">
                              <IconEdit stroke={2} size={13} />
                            </IconButton>
                          </button>
                          <button onClick={() => handleDelete(item.id)}>
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {prefixes && prefixes.length > 0 && (
              <div className="p-3 flex items-center justify-center bg-white">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(prefixes.length / PAGE_SIZE)}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={activePage * PAGE_SIZE >= prefixes.length}
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
            {prefixes?.length < 1 && (
              <div className="bg-white p-3 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default VehiclePrefix;
